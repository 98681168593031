<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Customer Support Services</h4>
          <p class="card-text"></p>

          <div class="d-flex flex-column justify-content-center" v-if="loading">
            <ProgressSpinner style="width:50px;height:50px; margin-top:1px;" strokeWidth="1" fill="var(--surface-ground)"
              animationDuration=".5s" />
          </div>
          <div class="row" v-else>
            <div class="col-sm-12">
              <div class="card mt-2 mt-4" v-for="customerService in customerServices" :key="customerService">
                <div class="card-body">
                  <p class="card-title">
                    Service: {{ customerService.support_service.title }}
                    <span class="float-right"><button @click="editcustomerService(customerService)"
                        class="btn btn-circle btn-primary btn-sm mr-1">
                        <i class="fas fa-edit"></i>
                      </button>
                      <button class="btn btn-circle btn-danger btn-sm mr-1"
                        @click="deletecustomerService(customerService)">
                        <i class="fas fa-trash"></i></button></span>
                    <br>
                    <small v-if="customerService.gateway_type != null" class="text-primary">{{ customerService.gateway_type }}</small>
                  </p>
                </div>
              </div>
              <p class="text-danger text-center" v-if="customerServices.length == 0">No support service found.</p>
            </div>
          </div>
          <Toast />
          <ConfirmDialog></ConfirmDialog>
        </div>
      </div>
    </div>

    <div class="col-md-8">
      <div class="card">
        <form @submit.prevent="formSubmit" class="user" autocomplete="off">
          <div class="card-body">
            <div class="row">
              <div class="col-md-10">
                <h4 class="card-title">{{ customerServiceFormTitle }}</h4>
              </div>
              <div class="col-md-2">
                <button v-show="newCustomerService.service_id" type="button" @click="resetForm()"
                  class="btn float-right btn-warning btn-circle btn-sm">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <p class="card-text"></p>

            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="">Service Name</label>
                  <select v-model="newCustomerService.service_id" class="form-control form-control-sm" @change="handleServiceChange">
                    <option selected :value="undefined">--Select--</option>
                    <option :value="service.id" v-for="service in supportServices" :key="service">
                      {{ service.title }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6" v-if="selectedServiceTitle === 'Gateway'">
                <div class="mb-3">
                  <label for="exampleMode">Select Gateway</label>
                  <div class="">
                    <select v-model="gatewayType" class="form-control form-control-sm">
                      <option selected :value=null>--Select--</option>
                      <option value="Stripe">Stripe</option>
                      <option>Authorize.net</option>
                      <option>BrainTree</option>
                      <option>Squareup</option>
                      <option>Heart Land</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-6" v-if="selectedServiceTitle === 'Twillio'">
                <div class="mb-3">
                  <label for="twillioToken">Token</label>
                  <input v-model="twillioToken" type="text" class="form-control form-control-sm" id="twillioToken" autocomplete="twillioToken" />
                </div>
              </div>
            </div>

            <button :disabled="!newCustomerService.service_id" class="btn btn-primary btn-sm btn-block mt-3">
              {{ BtnTitle }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import ProgressSpinner from 'primevue/progressspinner';
export default {

  components: {
    ConfirmDialog,
    Toast,
    ProgressSpinner,
  },

  data() {
    return {
      newCustomerService: {},
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      supportServices: {},
      customerServiceFormTitle: "Add Support Service",
      customerServices: [],
      BtnTitle: "Create",
      loading: true,
      selectedServiceTitle: '',

      //Gateway data
      gatewayType: null,
      publishableKey: null,
      secretKey: null,
      publicKey: null,
      privateKey: null,
      merchantID: null,
      applicationID: null,
      accessToken: null,
      locationID: null,
      loginID: null,
      transactionKey: null,
      twillioToken: null,
    };
  },

  created() {
    this.getSupportServices();
    this.getcustomerServices();
  },

  methods: {
    maskString(value) {
      if (value === null) {
        return '';
      }
      
      const visibleLength = value.length - 3;
      const maskedPart = 'x'.repeat(visibleLength);
      const lastPart = value.slice(-3);
      return maskedPart + lastPart;
    },

    handleServiceChange() {
      const selectedService = this.supportServices.find(service => service.id === this.newCustomerService.service_id);
      this.selectedServiceTitle = selectedService ? selectedService.title : '';

      if (this.selectedServiceTitle != 'Gateway') {
        //Gateway data
        this.gatewayType = null,
        this.publishableKey = null,
        this.twillioToken = null,
        this.secretKey = null,
        this.publicKey = null,
        this.privateKey = null,
        this.merchantID = null,
        this.applicationID = null,
        this.accessToken = null,
        this.locationID = null,
        this.loginID = null,
        this.transactionKey = null
      }
    },

    getSupportServices: function () {
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };
      axios.get(this.env_api_url + '/support_services', { headers })
        .then(response => {
          console.log("loadSupportServices from Database -->", response.data)
          this.supportServices = response.data;

        })
    },
    getcustomerServices: function () {
      this.loading = true;
      var customerId = this.$route.params.id;
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };

      // Get all Servers of this Customer
      axios.get(this.env_api_url + '/customers/' + customerId, { headers })
        .then((response) => {
          this.loading = false;
          console.log("All Support Services of this customer --> ", response);
          this.customerServices = response.data.customer_support_services;

        }).catch((error) => {
          console.warn('Your Error is :', error);
        })
      // Get all Servers of this Customer
    },

    formSubmit() {
      var data = {
        id: this.newCustomerService.id,
        service_id: this.newCustomerService.service_id,
        user_id: this.$route.params.id,
        gateway_type: this.gatewayType,
        publishable_key: this.publishableKey,
        secret_key: this.secretKey,
        public_key: this.publicKey,
        private_key: this.privateKey,
        merchant_id: this.merchantID,
        application_id: this.applicationID,
        access_token: this.accessToken,
        location_id: this.locationID,
        login_id: this.loginID,
        transaction_key: this.transactionKey,
        twillio_token: this.twillioToken
      };

      this.BtnTitle = "Waiting..."
      // Support service store
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .post(this.env_api_url + "/customer_support_services", data, { headers })
        .then((response) => {
          this.$notify({
            type: "success",
            title: "Success",
            text: "Saved Successfully!",
          });
          console.log("Support Service store successfully!", response);
          this.BtnTitle = "Create";
          this.resetForm();
          this.getcustomerServices();
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          console.warn("Your Error is :", error);
        });
      // Support service store
    },

    editcustomerService(customerService) {
      this.newCustomerService = customerService;
      this.twillioToken = customerService.twillio_token;
      this.selectedServiceTitle = customerService.support_service.title;
      this.gatewayType = customerService.gateway_type;
      this.customerServiceFormTitle = "Edit Support Service";
      this.BtnTitle = "Update";
    },
    deletecustomerService(customerService) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      this.$confirm.require({
        message: "Do you want to delete " + customerService.support_service.title + "?",
        header: 'Delete',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios
            .delete(
              this.env_api_url + "/customer_support_services/" + customerService.id,
              {
                headers: headers,
              }
            )
            .then((response) => {
              this.$toast.add({ severity: 'success', summary: 'Deleted', detail: 'Record deleted successfully!', life: 2000 });
              console.log("Deleted Support Service: ", response);
              this.getcustomerServices();
            })
            .catch((error) => {
              console.warn("Your Error is :", error);
            });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Record Safe', detail: 'Your record is safe!', life: 2000 });
        }
      });
    },
    resetForm() {
      this.customerServiceFormTitle = "Add Support Service";
      this.newCustomerService = {};
      this.selectedServiceTitle = '';
    },
  },
};
</script>

<style></style>