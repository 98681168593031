<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Lead Notes</h4>
          <p class="card-text"></p>

          <div class="row">
            <div class="col-sm-12">
              <div
                class="card mt-2 mt-4"
                v-for="leadNote in leadNotes"
                :key="leadNote"
              >
                <div class="card-body">
                  <p class="card-title">
                    Note: {{ leadNote.notes }}
                    <span class="float-right"
                      ><button
                        @click="editLeadNotes(leadNote)"
                        class="btn btn-circle btn-primary btn-sm mr-1"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                      <button
                        class="btn btn-circle btn-danger btn-sm mr-1"
                        @click="deleteLeadNotes(leadNote)"
                      >
                        <i class="fas fa-trash"></i></button
                    ></span>
                  </p>
                  <p class="card-text" v-if="leadNotes.notes">
                    Notes: <small> {{ leadNotes.notes }}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Toast /><ConfirmDialog></ConfirmDialog>
        </div>
      </div>
    </div>

    <div class="col-md-6 mb-4">
      <div class="card">
        <form @submit.prevent="formSubmit" class="user">
          <div class="card-body">
            <div class="row">
              <div class="col-md-10">
                <h4 class="card-title">{{ leadNoteFormTitle }}</h4>
              </div>
              <div class="col-md-2">
                <button
                  v-show="newLeadNote.notes"
                  type="button"
                  @click="resetForm()"
                  class="btn float-right btn-warning btn-circle btn-sm"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <p class="card-text"></p>

            <div class="row">
              <div class="col-md-12 ">
                <div class="mb-3">
                  <label for="">Notes</label>
                  <textarea
                    v-model="newLeadNote.notes"
                    class="form-control"
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    ref="focus"
                  ></textarea>
                </div>
              </div>
            </div>
            <button
              :disabled="!newLeadNote.notes"
              class="btn btn-primary btn-user btn-block"
            >
              {{btnTitle}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
export default {
  computed: mapState(["user"]),
  components: {ConfirmDialog, Toast},
  data() {
    return {
      newLeadNote: {},
      moment: moment,
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      leadNoteFormTitle: "Add Notes",
      leadNotes: [],
      btnTitle: "Create",
    };
  },
  mounted(){
    this.$refs.focus.focus();
  },
  created() {
    this.getLeadNotes();
  },
  methods: {
    getLeadNotes: function () {
      var leadId = this.$route.params.id;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      // Get all Products
      ///For Lead products called Eloquent from Lead Controller
      axios
        .get(this.env_api_url + "/leads/" + leadId, { headers })
        .then((response) => {
          console.log(
            "All Notes of this lead --> ",
            response.data.lead_notes
          );
          this.leadNotes = response.data.lead_notes;
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
      // Get all Products
    },

    formSubmit() {
      var data = {
        id: this.newLeadNote.id,
        lead_id: this.$route.params.id,
        notes: this.newLeadNote.notes,
      };
      this.btnTitle = "Waiting...";
      // Products store
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .post(this.env_api_url + "/lead_notes", data, { headers })
        .then((response) => {
          console.log("Notes store successfully!", response);
          this.$notify({
            type: "success",
            title: "Success",
            text: "Saved Successfully!",
          });
          this.btnTitle = "Create";
          this.resetForm();
          this.getLeadNotes();
        }).catch((error) => {

          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          
          console.warn("Your Error is :", error);
        });
      // Products store
    },

    editLeadNotes(leadNote) {
      this.newLeadNote =leadNote;
      this.leadNoteFormTitle = "Edit Notes";
      this.btnTitle = "Update";
    },
    deleteLeadNotes(leadNote) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
         axios
          .delete(
            this.env_api_url + "/lead_notes/" + leadNote.id,
            {
              headers: headers,
            }
          )
          .then((response) => {
            
            this.btnTitle = "Create";
            this.$toast.add({severity:'success', summary: 'Deleted', detail:'Record deleted successfully!', life: 2000});
            console.log("Delete lead basic data: ", response);
            this.getLeadNotes();
          })
          .catch((error) => {
            console.warn("Your Error is :", error);
          });   
        },
        reject: () => {
            this.$toast.add({severity:'error', summary: 'Record Safe', detail:'Your record is safe!', life: 2000});
        }
      });
    },
    resetForm() {
      this.leadNoteFormTitle = "Add Notes";
      this.newLeadNote = {};
    },
  },
};
</script>

<style>
</style>