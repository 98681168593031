<template>
  <div class="card shadow mr-5">
    <div class="card-header" style="padding:0px;">
      <div class="row mt-3">
        <div class="col-md-6"> 
          <table-header
            :title="'Survey Submissions'"
            :hasLink="true"
            :linkTo="'/surveys'"
            :linkText="'Surveys'"
          />
        </div>
        <div class="col-md">
          <router-link to="/survey/reports" class="btn btn-info btn-sm float-right mr-2">Reports</router-link>
          <DownloadButton v-tooltip.top="'Submissions'" :url="exportUrl" :fileName="ExcelFileName" class="btn-success float-right mr-1" />
          <button type="button" :style="{ 'background-color': color, 'border-color': border }" v-on:click="pauseEvent" class="btn btn-primary btn-sm float-right mr-1" @click="filters.archived = !filters.archived;">{{filters.archived?'Unarchived':'Archived'}}</button>
        </div>
      </div>
    </div>
    <ProgressSpinner v-if="loading" style="width:50px;height:50px" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"/>
    <div class="card-body" v-else>
      <DataTable :value="surveys" :paginator="true" style="padding:4px;" :rows="10" selectionMode="single"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5,10,20,50]" responsiveLayout="scroll"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
        <Column style="padding:4px;" field="id" header="S. No" :sortable="true"></Column>
        <Column style="padding:4px;" field="name" header="Survey Name" :sortable="true"></Column>
        <Column style="padding:4px;" field="email_survey" header="Date Published" :sortable="true">
          <template #body="slotDate">
            {{ moment(slotDate.data.created_at).format("MM/DD/YYYY") }}
          </template>
        </Column>
        <Column style="padding:4px;" field="submission_count" header="Total Submissions" :sortable="true"></Column>
        <Column style="padding:4px;" header="Actions">
            <template #body="slotProps">
              <!-- <router-link :to="{name:'ShowReport', params: { id:slotProps.data.id}}" >Show Report</router-link >               -->
              <router-link v-tooltip.top="'View List'" class="btn btn-warning btn-sm m-1 btn-circle" :to="{name:'FilledSurvey', params: { id:slotProps.data.id}}" ><i class="fa fa-list" aria-hidden="true"></i></router-link >              
              <router-link v-tooltip.top="'View Insights'" class="btn btn-info btn-sm m-1 btn-circle" :to="{name:'ShowReport', params: { id:slotProps.data.id}}" ><i class="fa fa-eye" aria-hidden="true"></i></router-link >
              <button v-tooltip.top="filters.archived?'Unarchived':'Archived'" type="button"  v-on:click="filters.archived? archivedFunc(slotProps.data.id): unarchivedFunc(slotProps.data.id)" class="btn btn-secondary btn-sm m-1 btn-circle"
               :disabled="achivedbtnDisabled"><i :class="filters.archived?'fa fa-upload':'fa fa-download'" aria-hidden="true"></i></button>         
              <!-- <router-link v-tooltip.top="'Export'" class="btn btn-success btn-sm m-1 btn-circle" :to="{name:'ShowReport', params: { id:slotProps.data.id}}" ><i class="fa fa-file-excel" aria-hidden="true"></i></router-link >               -->
            </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script>
  import Tooltip from 'primevue/tooltip';
  import TableHeader from "../SharedComponents/TableHeader.vue";
  import axios from "axios";
  import moment from "moment";
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import ProgressSpinner from 'primevue/progressspinner';
import DownloadButton from '../SharedComponents/DownloadButton.vue'

  export default {
    directives: {
      'tooltip': Tooltip
    },
    
    components: {
      TableHeader, 
      DataTable, 
      Column, 
      ProgressSpinner,
      DownloadButton
    },

    data() {
      return {
        filters:{
          
        },
        loading: true,
        surveys: [],
        achivedbtnDisabled: false,
        moment: moment,
        display: false,
        SurveyID: null,
        totalRows: 0,
        ExcelFileName: "Submissions.xlsx",
        env_api_url: process.env.VUE_APP_SERVICE_URL,
        base_url:process.env.VUE_APP_SERVICE_URL
      };
    },
    
      watch: {
        filters: {
          deep: true,
          handler () {   
            if (!this.awaitingSearch) {
              setTimeout(() => {
                this.getSurveySubmissions()
                this.loading=true;
                this.awaitingSearch = false;
              }, 700); // 1 sec delay
            }
            this.awaitingSearch = true;
          }
        },
      },

      computed:{
        exportUrl(){
          let url = this.base_url + '/export/submissions';
          return url;
        }
      },
    
    methods: {

      pauseEvent() {
        if(this.filters.archived){
          this.color='#2e59d9';
          this.loading=true;
        }
        else{
        this.color = '#e02d1b';
        this.loading=true;
        }
      },

      archivedFunc(surveyID){
        this.achivedbtnDisabled = true;
        var data = { 
          survey_id: surveyID,
          is_archive: false
        };

        var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };
        axios.post(this.base_url + "/survey/is_archive", data, { headers}).then(
          (response) => {
            if (response.status === 200) {
              this.achivedbtnDisabled = false;
              this.getSurveySubmissions();
            }
            console.log(response);
          },
          (error) => {
            this.achivedbtnDisabled = false;
            console.log(error.response.data.error);
            this.$notify({
              type: "error",
              title: "Something went wrong!",
              text: error.response.data.error,
            });
          }
        );
      },
      
      unarchivedFunc(surveyID) {
        this.achivedbtnDisabled = true;
        var data = { 
          survey_id: surveyID,
          is_archive: true
        };

        var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };
        axios.post(this.base_url + "/survey/is_archive", data, { headers}).then(
          (response) => {
            if (response.status === 200) {
              this.achivedbtnDisabled = false;
              this.getSurveySubmissions();
            }
            console.log(response);
          },
          (error) => {
            this.achivedbtnDisabled = false;
            console.log(error.response.data.error);
            this.$notify({
              type: "error",
              title: "Something went wrong!",
              text: error.response.data.error,
            });
          }
        );
      },

      getSurveySubmissions: function () {
        this.filters.is_archive = this.$route.query.is_archive?this.$route.query.is_archive:"";
        var headers = {
          Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };
        axios
          .get(this.base_url+'/submissions', { 
            headers,
            params: this.filters,
          })
          .then((response) => {

            this.surveys = response.data.surveys;
            this.totalRows = 0;
            // this.customers_url = 0;
            this.loading = false;
          });
      }
    },

    created() {
      this.getSurveySubmissions();
    },
  };
</script>
