<template>
  <p class="h5 text-primary ml-3"><strong>
    {{ title }} 
    <span v-if="hasLink">
      | <router-link class="btn btn-primary btn-sm ml-1" :to="linkTo" ><i class="fa fa-plus"></i> {{linkText}}</router-link >
    </span>
  </strong>
  </p>
</template>

<script>
export default {
  props: ["title", "hasLink",'linkTo','linkText'],
};
</script>

<style>
</style>