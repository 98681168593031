<template>
    <div>
        <form @submit.prevent="productSettingsForm">
        <table class="table table-striped">
        <thead>
            <tr>
            <th scope="col">Product</th>
            <th scope="col">Select</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="product in products" :key="product">
                <td>
                    <label style="margin-top: 5px;">{{product.name}}</label>
                </td>
                <td>
                    <label class="switch">
                        <input type="checkbox" 
                        id="{{product.id}}"
                        name="productSelect"
                        v-bind:value="{id: product.id, name: product.name}"
                        v-model="selectedProduct[product.id]"
                        />
                        <span class="slider round"></span>
                    </label>
                </td>
            </tr>
        </tbody>
        </table>
            <button class="btn btn-success btn-block btn-sm" :disabled="isDisabled">{{btnTitle}}</button>
        </form>
    </div>
</template>
<script>

import axios from "axios";
export default {
    data() {
        return {
        env_api_url: process.env.VUE_APP_SERVICE_URL,
        products: [],
        selectedProduct: {},
        btnTitle: 'Import',
        isDisabled: false,
        };
    },

    created() {
        this.loadProducts();
    },

    methods: {
        
        hideDialog()
        {
            this.$emit('productDialogHide',false)
        },

        loadProducts: function () {
            var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
            axios.get(this.env_api_url + '/product', {headers})
            .then(response => {
                this.products = response.data.products;
            })
        },

        productSettingsForm() {
            this.btnTitle = "Waiting...";
            this.isDisabled = true;

            var data = {
                selectedProducts: this.selectedProduct,
            };

            var headers = {
            Authorization:`Bearer ` + localStorage.getItem("rezo_customers_user"),
            };

            axios
            .post(this.env_api_url + "/list/import_customers", data, { headers })
            .then((response) => {
            if (response.status === 200) {
            
                this.btnTitle = "Import";
                this.isDisabled = false;
                this.showProductsPopUp = false;
            
                this.hideDialog();
                this.$notify({
                    type: "success",
                    title: "Success",
                    text: "Products save successfully!!",
                    });
                    console.log("Products save successfully");
                }
            },
            (error) => {
                console.log(error.response.data.error);
                this.$notify({ 
                type: "error",
                title: "Something went wrong!",
                text: error.response.data.error,
                });
                this.btnTitle = "Import";
                this.isDisabled = false;
            });
        },
    },
}
</script>
