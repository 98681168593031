<template>
  <div class="card shadow mr-5">
    <div class="card-header" style="padding:0px;">
      <div class="row mt-3 pr-2">
        <div class="col-md-4 pr-0">
          <table-header title="Support Services Report" />
        </div>
        <div class="col-md-8 pl-0">
          <form class="form-inline float-right">
            <div class="form-group">
              <select class="form-control form-control-sm mr-1" @change="handleServiceChange"
                v-model="filters.service_id">
                <option selected :value="undefined">-- Select Service --</option>
                <option :value="service.id" v-for="service in supportServices" :key="service">
                  {{ service.title }}
                </option>
              </select>
            </div>
            <div class="form-group" v-if="selectedServiceTitle === 'Gateway'">
              <select v-model="filters.gatewayType" class="form-control form-control-sm mr-1" :class="{ 'is-invalid': v$.filters.gatewayType.$error }">
                <option selected :value=undefined>-- Select Type --</option>
                <option value="Stripe">Stripe</option>
                <option value="Authorize.net">Authorize.net</option>
                <option value="BrainTree">BrainTree</option>
                <option value="Squareup">Squareup</option>
                <option value="Heart Land">Heart Land</option>
              </select>
            </div>
            <div class="form-group ">
              <button type="button" class="btn btn-success btn-sm mr-1" @click="handleSearch"><i class="fa fa-search"></i>Search</button>
            </div>
            <div class="form-group ">
              <button type="button" class="btn btn-danger btn-sm mr-1" @click="filters = {}, selectedServiceTitle = '', getCustomers()"><i class="fa fa-undo"></i> Reset</button>
            </div>
            <div class="form-group ">
              <button type="button" @click="download()" class="btn btn-primary btn-sm  mr-2"><i
                  class="fa fa-download"></i>
                Export</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <ProgressSpinner v-if="loading" style="width:50px;height:50px" strokeWidth="1" fill="var(--surface-ground)"
      animationDuration=".5s" />
    <div class="card-body" v-else>
      <small class="mr-2">Total Records: {{customers.length}}</small>
      <DataTable :value="customers" selectionMode="single">
        <Column style="padding:4px;" header="Company Name">
          <template #body="slotRecord">
            <router-link class="text-primary" :to="{ path: '/customers/' + slotRecord.data.id + '/edit' }">
              {{ slotRecord.data.company_name }}
            </router-link>
          </template>
        </Column>

        <Column style="padding:4px;" field="email" :sortable="true" header="Email"></Column>
        <Column field="phone" header="Phone #">
          <template #body="slotProps">
            <a v-if="slotProps.data.phone" v-bind:href="'tel:' + slotProps.data.phone">{{ slotProps.data.phone }}</a>
            <small v-else>N/A</small>
          </template>
        </Column>

        <!-- <Column style="padding:4px;" header="Products">
          <template #body="slotProducts">
            <span v-if="slotProducts.data.products">
              <small v-for="productObj in slotProducts.data.products" :key="productObj"
                class="badge badge-primary m-1">{{ productObj.product ? productObj.product.name : '' }}</small>
            </span>

            <span v-if="(slotProducts.data.products).length < 1">
              <small class="badge badge-danger">No Products yet!</small>
            </span>
          </template>
        </Column> -->

        <!-- <Column style="padding:4px;" header="Servers">
          <template #body="slotRecord">
            <span v-if="slotRecord.data.servers">
              <small v-for="serverObj in slotRecord.data.servers" :key="serverObj" class="badge badge-primary m-1">
                <router-link class="text-light"
                  :to="{ path: '/customers/' + slotRecord.data.id + '/edit', query: { activeTab: 'servers' } }">
                  {{ serverObj.server.name }}
                </router-link>
              </small>
            </span>

            <span v-if="(slotRecord.data.servers).length < 1">
              <small class="badge badge-danger">No Server</small>
            </span>
          </template>
        </Column> -->
        
        <Column style="padding:4px;" header="Services">
          <template #body="slotRecord">
            <span v-if="slotRecord.data.support_services">
              <div v-for="serverObj in slotRecord.data.support_services" :key="serverObj">

                <span v-if="serverObj.support_service.title == selectedServiceTitle">
                  {{ serverObj.support_service.title }} {{ serverObj.support_service.title === 'Twillio' && serverObj.twillio_token != null ? '| ' + serverObj.twillio_token : ''}}
                </span>

                <span v-if="serverObj.support_service.title == selectedServiceTitle && serverObj.gateway_type == filters.gatewayType">
                  {{ serverObj.support_service.title === 'Gateway' && serverObj.gateway_type != null ? '| ' + serverObj.gateway_type : ''}}
                </span>
                
              </div>
            </span>

            <span v-if="(slotRecord.data.support_services).length < 1">
              <small class="badge badge-danger">No Service</small>
            </span>
          </template>
        </Column>
      </DataTable>
      <p class="text-center p-0" v-if="customers.length == 0">No Records Found.</p>
    </div>
  </div>
</template>

<script>
import TableHeader from "../SharedComponents/TableHeader.vue";
import axios from "axios";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressSpinner from 'primevue/progressspinner';
import Tooltip from 'primevue/tooltip';
import { eventBus } from '../../eventBus.js';
import useValidate from "@vuelidate/core";
import {
    helpers,
    requiredUnless
} from "@vuelidate/validators";
export default {
  directives: {
    'tooltip': Tooltip
  },

  components: {
    TableHeader,
    DataTable,
    Column,
    ProgressSpinner,
  },

  data() {
    return {
      v$: useValidate(),
      newCustomerService: {},
      filters: {},
      loading: true,
      gatewayType: null,
      customers: [],
      supportServices: {},
      ExcelFileName: "customers.xlsx",
      totalRows: 0,
      base_url: process.env.VUE_APP_SERVICE_URL,
      customer_url: '',
      selectedServiceTitle: '',
    };
  },

  validations() {
    return {
      filters: {
        gatewayType: {
          requiredIf: helpers.withMessage('Gateway type is required', requiredUnless(this.selectedServiceTitle != 'Gateway')),
        }
      },
    };
  },

  methods: {
    download() {
      this.loading = true;
      const requestFilters = { ...this.filters };
      requestFilters.type = this.$route.query.type ? this.$route.query.type : "";
      requestFilters.export = true;

      axios
        .get(this.base_url + '/customers_report', {
          responseType: "blob",
          headers: {
            Authorization:
              `Bearer ` +
              localStorage.getItem("rezo_customers_user"),
          },
          params: requestFilters
        })
        .then((response) => {
          this.loading = false;
          const url = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.ExcelFileName}`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$notify({
            type: "error",
            title: "Error",
            text: "Something Went Wrong!",
          });
          this.loading = false;
        });
    },
    pauseEvent() {
      if (this.filters.active) {
        this.color = '#2e59d9';
        this.loading = true;
      }
      else {
        this.color = '#e02d1b';
        this.loading = true;
      }
    },
    
    handleSearch() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.v$.$errors.forEach(element => {
          console.log(element.$message);
        });
      } else {
        this.getCustomers();
      }
    },

    getCustomers: function (page_url) {
      this.loading = true;
      page_url = page_url || this.base_url + '/customers_report'

      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };
      axios.get(page_url, { headers, params: this.filters })
        .then((response) => {
          this.loading = false;
          this.customers = response.data.customers;
          this.loading = false;
        });
    },

    handleServiceChange() {
      const selectedService = this.supportServices.find(service => service.id === this.filters.service_id);
      this.selectedServiceTitle = selectedService ? selectedService.title : '';
    },

    getSupportServices: function () {
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };
      axios.get(this.base_url + '/support_services', { headers })
        .then(response => {
          console.log("loadSupportServices from Database -->", response.data)
          this.supportServices = response.data;
        })
    }
  },

  mounted() {
    var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };
    axios.get(this.base_url + '/product', { headers })
      .then(response => {

        response.data.products.forEach(element => {
          if (this.$route.query.type == element.id) {
            this.ExcelFileName = "Customers (" + element.name + ").xlsx"
          }
        });
      })
  },

  created() {
    this.getCustomers();
    this.getSupportServices();

    eventBus.on('update-filters', (newFilters) => {
      this.filters = newFilters;
    });
  },
};
</script>