<template>
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <!-- <h6 class="m-0 font-weight-bold text-primary">Records <small>({{totalRows}})</small></h6> -->
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0" >
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Created at</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="customer in customersArchives" :key="customer">
              <td>{{customer.company_name }}</td>
              <!-- <td>{{ moment(customer.created_at).format("MM/DD/YYYY") }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from 'moment';
export default {
  
  data() {
    return {
      customersArchives: [],
      leadsInfo: [],
      moment: moment,
      totalRows: 0,
      customers_url:process.env.VUE_APP_SERVICE_URL + '/customers',
    };
  },
 
  methods: {
    getArchivedCustomers: function () {
      var customerId = this.$route.params.id;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      // Get all Products
      ///For Customer products called Eloquent from Customer Controller
      axios
        .get(this.env_api_url + "/customers/" + customerId, { headers })
        .then((response) => {
          console.log(
            "ALLLLLLLLL --> ",
            response.data
          );
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
      // Get all Products
    },
  },
  created() {
    this.getArchivedCustomers();
  },
};
</script>