<template>
    <div>
        <form @submit.prevent="sendSmsForm" class="user">
            <div class="form-group row">
                <div class="col-md-8 mb-sm-0">
                    <label for="email">Enter Phone</label>
                    <!-- <input ref="phone" v-model="phone" type="number" id="email" class="form-control form-control-sm" :class="{ 'is-invalid': v$.phone.$error }" /> -->
                    <InputMask autocomplete="chrome-off" v-model="phone" mask="(999) 999-9999"
                        :disabled="true"
                        placeholder="(999) 999-9999" class="form-control form-control-sm"
                        :class="{ 'is-invalid': v$.phone.$error }" />
                    <span class="text-danger" v-if="v$.phone.$error">
                        <span class="help-block" v-for="validationError in v$.phone.$errors" :key="validationError">
                            {{ validationError.$message }}
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-12 mb-sm-0">
                    <label class="required" for="email">Type Message</label>
                    <textarea v-model="message" id="message" class="form-control form-control-sm"
                        :class="{ 'is-invalid': v$.phone.$error }"></textarea>
                    <span class="text-danger" v-if="v$.message.$error">
                        <span class="help-block" v-for="validationError in v$.message.$errors" :key="validationError">
                            {{ validationError.$message }}
                        </span>
                    </span>
                </div>
            </div>

            <button class="btn btn-primary" :disabled="btnDisabled">{{ btnTitle }}</button>
        </form>
    </div>
</template>
<script>

import InputMask from 'primevue/inputmask';
import { required, helpers } from "@vuelidate/validators";
import axios from "axios";
import useValidate from "@vuelidate/core";

export default {
    components: { InputMask },
    props:["sms_phone_number"],
    data() {
        return {
            v$: useValidate(),
            env_api_url: process.env.VUE_APP_SERVICE_URL,

            btnTitle: 'Send SMS',
            phone: this.sms_phone_number,
            message: null,
            btnDisabled: false,
        };
    },

    validations() {
        return {
            phone: {
                required
            },
            message: {
                required: helpers.withMessage('Please write any message!', required),

            }
        };
    },

    methods: {
        hideDialog()
        {
            this.$emit('Display',false)
        },

        sendSmsForm() {
            this.v$.$validate();
            if (this.v$.$error) {
                console.log("errors");
            } else {

                this.btnDisabled = true
                this.btnTitle = 'Waiting...'

                var data = {
                    phone: this.phone,
                    message: this.message
                };

                var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };
                axios
                .post(this.env_api_url + "/customer/send_sms_contact_details", data, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        console.log("Sms send successfully");
                        this.hideDialog();
                    }

                    this.btnDisabled = false
                    this.btnTitle = 'Send SMS'
                    
                }).catch((error) => {

                    this.btnDisabled = false
                    this.btnTitle = 'Send'

                    this.$notify({
                        type: "error",
                        title: "Something Went Wrong",
                        text: "Error!",
                    });

                    this.btnDisabled = false
                    this.btnTitle = 'Send SMS'

                    console.warn("Your Error is :", error);
                });
            }
        }
    },
}
</script>
