<template>
  <!-- DataTales Example -->
  <div class="card shadow mr-5">
    <div class="card-header" style="padding:0px;">
      <div class="row mt-3">
        <div class="col-md-5">
          <table-header
            :title="'Leads'"
            :hasLink="true"
            :linkTo="'/leads/create'"
            :linkText="'New Lead'"
          />
        </div>
        <div class="col-md-7">
          <form class="form-inline float-right mr-3">
            <div class="form-group">
              <input
                v-model="filters.name"
                class="form-control form-control-sm"
                placeholder="Name"
                ref="search"
              />
            </div>
            <div class="form-group">
              <!-- <input
                v-model="filters.rating"
                class="form-control ml-1 form-control-sm"
                placeholder="Rating"
                type="number"
                style="width: 65px;"
              /> -->
              <div>
                <select v-model="filters.rating" style="width: 85px;" class="ml-1 form-control form-control-sm" id="rate" >
                <option  selected :value="rating">Rate</option>
                <!-- <option  value="1"><router-link class="dropdown-item" :to="'/leads?rate=' + 1">1</router-link></option>
                <option  value="1"><router-link class="dropdown-item" :to="'/leads?rate=' + 2">2</router-link></option>
                <option  value="1"><router-link class="dropdown-item" :to="'/leads?rate=' + 3">3</router-link></option>
                <option  value="1"><router-link class="dropdown-item" :to="'/leads?rate=' + 4">4</router-link></option>
                <option  value="1"><router-link class="dropdown-item" :to="'/leads?rate=' + 5">5</router-link></option> -->
                
                <option  value="1">1</option>
                <option  value="2">2</option>
                <option  value="3">3</option>
                <option  value="4">4</option>
                <option  value="5">5</option>
                </select>
              </div>
            </div>
            <div class="form-group ml-1">
              <button type="button" class="btn btn-danger btn-sm" @click="this.filters={}">Reset</button>
            </div>
          </form>
          <DownloadButton :url="exportUrl" :fileName="ExcelFileName" class="float-right ml-5 mr-1" />
        </div>
      </div>
    </div>
    <ProgressSpinner v-if="loading" style="width:50px;height:50px" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"/>
    <div class="card-body" v-else>
      <DataTable :value="leads" :paginator="true" style="padding:4px;" :rows="10" selectionMode="single"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5,10,20,50]" responsiveLayout="scroll"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
        <Column style="padding:4px;" field="company_name" header="Company Name" :sortable="true"></Column>
        
        <Column field="phone" header="Phone #">
          <template #body="slotProps">
            <a v-bind:href="'tel:' + slotProps.data.phone_number">{{slotProps.data.phone_number}}</a>
          </template>
        </Column>

        <Column style="padding:4px;" header="Products">
            <template #body="slotProducts">
              <span v-if="slotProducts.data.contact_details">
                <small  v-for="productObj in slotProducts.data.contact_details" :key="productObj" class="badge badge-primary mr-1">{{productObj.lead_products?productObj.lead_products.name:''}}</small>
              </span>

              <span v-if="(slotProducts.data.contact_details).length<1">
                <small class="badge badge-danger">No Products yet!</small>
              </span>
            </template>
        </Column>

        <Column field="rating" header="Rating #">
          <template #body="slotProps">
            <div :class="addClass(slotProps.data)">
              <strong>{{slotProps.data.rating}}</strong>
            </div>
          </template>
        </Column>
        
        <Column style="padding:4px;" field="created_at" header="Created At" :sortable="true">
          <template #body="slotDate">
            {{ moment(slotDate.data.created_at).format("MM/DD/YYYY") }}
          </template>
        </Column>
        
        <Column style="padding:4px;" header="Actions">
            <template #body="slotProps">
              <router-link :to="{name:'EditLead', params: { id:slotProps.data.id}}" ><i class="fas fa-edit btn btn-circle btn-primary  btn-sm"></i></router-link >
              <button v-on:click="deleteLead(slotProps.data.id)" class="btn btn-circle btn-danger  btn-sm ml-1"><i class="fas fa-trash"></i></button>
            </template>
        </Column>
      </DataTable>
    </div>
    <Toast /><ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script>
import TableHeader from "../SharedComponents/TableHeader.vue";
import axios from "axios";
import moment from "moment";
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import DownloadButton from '../SharedComponents/DownloadButton.vue';
import ProgressSpinner from 'primevue/progressspinner';
import { eventBus } from '../../eventBus.js';

export default {
  components: { 
    TableHeader, 
    DataTable, 
    Column, 
    DownloadButton, 
    ProgressSpinner, 
    ConfirmDialog, 
    Toast
  },
  data() {
    return {
      filters:{
        
      },
      loading: true,
      leads: [],
      ExcelFileName: "Leads.xlsx",
      moment: moment,
      totalRows: 0,
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      base_url:process.env.VUE_APP_SERVICE_URL
    };
  },
  watch: {
    filters: {
      deep: true,
      handler () {
        
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getLeads()
            this.loading=true;
            this.awaitingSearch = false;
          }, 700); // 1 sec delay
        }
        this.awaitingSearch = true;
      }
    },
  },
  computed:{
    exportUrl(){
      let url = this.base_url + '/export/leads';
      if(this.$route.query.type){
        url += '?type=' + this.$route.query.type;
      }

      if(this.filters.rating){

        if (this.$route.query.type){
          url += '&rating=' + this.filters.rating;
        }else{
          url += '?rating=' + this.filters.rating;
        }
      }
      
      return url;
    }
  },
  methods: {

    addClass(data) {
      return [
        {
          'no-rate': data.rating === 0,
          'veryLow-rate': data.rating === 1,
          'low-rate': data.rating === 2,
          'medium-rate': data.rating === 3,
          'high-rate': data.rating === 4,
          'veryHigh-rate': data.rating === 5,
        }
      ];
    },
    
    getLeads: function () {
      this.filters.type = this.$route.query.type?this.$route.query.type:"";
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.base_url+'/leads', { 
          headers,
          params: this.filters,
        })
        .then((response) => {
          this.leads = response.data.leads;
          this.totalRows = 0;
          this.customers_url = 0;
          this.$refs.search.focus();
          this.loading = false;
        });
    },

    deleteLead: function (leadId) {
       
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios
            .delete(this.env_api_url + "/leads/" + leadId, {
              headers: headers,
            })
            .then((response) => {
              this.getLeads();
              this.$toast.add({severity:'success', summary: 'Deleted', detail:'Record deleted successfully!', life: 2000});
              console.log("Deleted Lead: ", response);
            })
            .catch((error) => {
              console.warn("Your Error is :", error);
            });
        },
        reject: () => {
            this.$toast.add({severity:'error', summary: 'Record Safe', detail:'Your record is safe!', life: 2000});
        }
      });
    },
  },
  mounted(){
    this.$refs.search.focus();

    //Excel export with product name
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
      axios.get(this.env_api_url + '/product', {headers})
      .then(response => {

        response.data.products.forEach(element => {
          if (this.$route.query.type == element.id)
          {
            this.ExcelFileName = "Leads (" + element.name + ").xlsx"
          }
        });
      })
    //Excel export with product name
  },
  created() {
    
    this.getLeads();

    eventBus.on('update-filters', (newFilters) => {
      this.filters = newFilters;
    });
  },
};
</script>
<style scoped>
  .zoom {
    transition: transform .2s;
  }
  .zoom:hover {
    transform: scale(1.5);
  }
  .no-rate {
    color: red;
  }
  .veryLow-rate {
    color: red;
  }
  .low-rate {
    color: rgb(206, 160, 22);
  }
  .medium-rate {
    color: rgb(22, 123, 206);
  }
  .high-rate {
    color: rgb(53, 190, 53);
  }
  .veryHigh-rate {
    color: rgb(53, 190, 53);
  }
</style>
