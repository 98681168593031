<template>
  <page-header :title="'Site Settings'" />
  <div class="card col-md-8 mb-3">
    <div class="card-body">
      <button type="button" class="btn btn-secondary btn-block pt-3" v-on:click="showMailchimp"><h6>Mailchimp Settings</h6></button>
      <button type="button" class="btn btn-secondary btn-block pt-3" v-on:click="showProductDialog"><h6>Products Settings</h6></button>

      <Dialog :maximizable="true" :closeOnEscape="true"  header="Settings" v-model:visible="showMailchimpPopUp" :style="{width: '30w'}">
        <form @submit.prevent="formSubmit" class="user">
          <div class="row">
            <div class="col-sm-5">
              <label>API Key</label>
              <input v-model="apiKey" type="password" id="examplePassword" class="form-control form-control-sm"/>
            </div>
            <div class="col-sm-4 mt-4">
              <div class="input-group mb-3" style="margin-top: 8px;">
                <input type="text" class="form-control form-control-sm" readonly v-model="apiKey_value">
                <div class="input-group-append">
                  <button class="btn btn-info btn-sm" type="button" @click="editField()">Edit</button>
                </div>
              </div>
            </div>
            <div class="col-sm-3 mt-4">
              <!-- Settings Update Button -->
              <button class="btn btn-success btn-sm ml-2 btn-block" :disabled="btnDisabled" style="margin-top: 8px;">{{btnTitle}}</button>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-3">
              <div class="row">
                <div class="col-sm-12 form-inline">
                  <div class="form-group mr-2">
                    <label class="mr-1" for="checkboxActive">Customer</label>
                    <label class="switch">
                      <input type="checkbox" id="checkboxActive" v-model="mailchimp_active_customer" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="row">
                <div class="col-sm-12 form-inline">
                  <div class="form-group mr-2">
                    <label class="mr-1" for="checkboxActive">Lead</label>
                    <label class="switch">
                      <input type="checkbox" id="checkboxActive" v-model="mailchimp_active_lead" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Customers -->
          <div v-show="mailchimp_active_customer">
            <small class="d-flex justify-content-center text-secondary">- For Customers -</small>
            <div class="row">
              <div class="col-md-3 ml-3 customerCard" style="max-width: 23%;">
                <SkiCustomersMailchimpModal />
              </div>
              <div class="col-md-3 customerCard" style="max-width: 23%;">
                <GmsCustomersMailchimpModal />
              </div>
              <div class="col-md-3 customerCard" style="max-width: 23%;">
                <RentalsCustomersMailchimpModal />
              </div>
              <div class="col-md-3 customerCard" style="max-width: 23%;">
                <OthersCustomersMailchimpModal />
              </div>
            </div>
          </div>
          
          <!-- Leads -->
          <div v-show="mailchimp_active_lead">
            <small class="d-flex justify-content-center text-secondary">- For Leads -</small>
            <div class="row">
              <div class="col-md-3 ml-3 customerCard" style="max-width: 23%;">
                <SkiLeadsMailchimpModal />
              </div>
              <div class="col-md-3 customerCard" style="max-width: 23%;">
                <GmsLeadsMailchimpModal />
              </div>
              <div class="col-md-3 customerCard" style="max-width: 23%;">
                <RentalsLeadsMailchimpModal />
              </div>
              <div class="col-md-3 customerCard" style="max-width: 23%;">
                <OthersLeadsMailchimpModal />
              </div>
            </div>
          </div>
        </form>
      </Dialog>
      
      <!-- Product Settings -->
      <Dialog :maximizable="true" :closeOnEscape="true"  header="Settings" v-model:visible="showProductsPopUp" style="width: 330px;">
        <router-link to="/product/create" class="btn btn-block btn-sm btn-primary">Add more</router-link>
        <ProductSettingForm />
      </Dialog>
    </div>
  </div>
</template>

<script>
import PageHeader from "../SharedComponents/PageHeader.vue";
import Dialog from "primevue/dialog";
import axios from "axios";

import SkiCustomersMailchimpModal from "./partials/CustomersMailchimp/SkiSnowboardModal.vue";
import GmsCustomersMailchimpModal from "./partials/CustomersMailchimp/GmsModal.vue";
import RentalsCustomersMailchimpModal from "./partials/CustomersMailchimp/RentalsModal.vue";
import OthersCustomersMailchimpModal from "./partials/CustomersMailchimp/OthersModal.vue";

import SkiLeadsMailchimpModal from "./partials/LeadsMailchimp/SkiSnowboardModal.vue";
import GmsLeadsMailchimpModal from "./partials/LeadsMailchimp/GmsModal.vue";
import RentalsLeadsMailchimpModal from "./partials/LeadsMailchimp/RentalsModal.vue";
import OthersLeadsMailchimpModal from "./partials/LeadsMailchimp/OthersModal.vue";
import ProductSettingForm from "./partials/ProductSettingForm.vue";

export default {
  components: { 
    PageHeader, 
    Dialog, 
    SkiCustomersMailchimpModal, 
    GmsCustomersMailchimpModal, 
    RentalsCustomersMailchimpModal, 
    OthersCustomersMailchimpModal, 
    SkiLeadsMailchimpModal,  
    GmsLeadsMailchimpModal, 
    RentalsLeadsMailchimpModal, 
    OthersLeadsMailchimpModal,
    ProductSettingForm
  },
  data() {
    return {
      apiKey: null,
      apiKey_value: null,
      show_apiKey: null,
      isSkiCustomerModal : false,
      isGmsCustomerModal : false,
      isRentalCustomerModal : false,
      isOtherCustomerModal : false,
      isSkiLeadModal : false,
      isGmsLeadModal : false,
      isRentalLeadModal : false,
      isOtherLeadModal : false,
      showMailchimpPopUp : false,
      showProductsPopUp : false,
      base_url: process.env.VUE_APP_SERVICE_URL,
      mailchimp_for_customer: false,
      mailchimp_for_lead: false,
      btnDisabled: false,
      btnTitle: "Update Settings",

    };
  },

  created() {
    var siteId = 2;
    var headers = {
      Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
    };
    
    axios
    .get(this.base_url + "/settings/" + siteId, { headers })
    .then((response) => {
      console.log("Edit Site Setting Record: ", response.data.mailchimp_for_lead);
      this.mailchimp_active_lead = response.data.mailchimp_for_lead?true:false;
      this.mailchimp_active_customer = response.data.mailchimp_for_customer ? true:false;
      this.show_apiKey = response.data.api_key

      let total_x  = ''
      for (let index = 1; index <= response.data.api_key.length; index++) {
        total_x = total_x + 'x';
      }
      this.apiKey_value = total_x;

    })
    .catch((error) => {
      console.warn("Your Error is :", error);
    });
  },

methods: {
  skiCustomerModal(){
      this.isSkiCustomerModal = true;
    },
  gmsCustomerModal(){
      this.isGmsCustomerModal = true;
    },
  rentalCustomerModal(){
      this.isRentalCustomerModal = true;
    },
  otherCustomerModal(){
      this.isOtherCustomerModal = true;
    },
  skiLeadModal(){
      this.isSkiLeadModal = true;
    },
  gmsLeadModal(){
      this.isGmsLeadModal = true;
    },
  rentalLeadModal(){
      this.isRentalLeadModal = true;
    },
  otherLeadModal(){
      this.isOtherLeadModal = true;
    },
  showMailchimp(){
      this.showMailchimpPopUp = true;
    },

    showProductDialog(){
      this.showProductsPopUp = true;
    },

    editField() {
      this.apiKey = this.show_apiKey;
    },
    
    formSubmit() {
      this.btnDisabled = true;
      this.btnTitle = "Waiting...";

      var data = {
        setting_id: 2,
        api_key: this.apiKey,
        mailchimp_for_customer: this.mailchimp_active_customer,
        mailchimp_for_lead: this.mailchimp_active_lead,
      };
      var headers = {
        Authorization:
          `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      axios.post(this.base_url + "/settings", data, { headers }).then(
        (response) => {
          if (response.status === 200) {
            this.$notify({
              type: "success",
              title: "Success",
              text: "Saved Successfully!",
            });

            this.btnDisabled = false;
            this.btnTitle = "Update Settings";

            this.$router.push({ path: "/settings/site" });
          }
          console.log(response);
        },
        (error) => {
          console.log(error.response.data.error);
          this.$notify({
            type: "error",
            title: "Something went wrong!",
            text: error.response.data.error,
          });

          this.btnDisabled = false;
          this.btnTitle = "Update Settings";

        }
      );
    },
  },
};
</script>

<style scoped>
.customerCard {
  /* background: #d1d3e2; */
  border-radius: 9px;
  padding: 0px;
  border: 1px;
  border-style: solid;
  border-color: #d1d3e2;
  margin: 6px;
  padding-top: 10px;
}

</style>