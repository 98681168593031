<template>
  <!-- DataTales Example -->
  <div class="card shadow mr-5">
    <div class="card-header" style="padding:0px;">
      <div class="row mt-3">
        <div class="col-md-6">
          <table-header
            :title="'Surveys'"
            :hasLink="true"
            :linkTo="'/survey/create'"
            :linkText="'New Survey'"
          />
        </div>
        <div class="col-md-6">
          <form class="form-inline float-right mr-3">
            <div class="form-group">
              <input
                v-model="searchFilters.name"
                class="form-control form-control-sm"
                placeholder="Name"
                ref="search"
              />
            </div>
            
            <div class="form-group ml-1">
              <button type="button" class="btn btn-danger btn-sm" @click="resetSearch()">Reset</button>
              <DownloadButton v-tooltip.top="'Surveys'" :url="exportUrl" :fileName="ExcelFileName" class="btn-primary float-right ml-1" />
              <div class="dropdown show" v-if="selectedSurvey">
                <a class="btn btn-primary btn-secondary dropdown-toggle btn-sm ml-1" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Customers</a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <a class="dropdown-item mouseHand" @click="customersDialog(0)">Customers</a>
                  <div class="dropdown-divider"></div>
                    <div v-for="product in products" :key="product">
                      <a class="dropdown-item mouseHand" @click="customersDialog(product.id)">{{product.name}}</a>
                    </div>
                </div>
              </div>
            </div>        
            
            <Dialog :maximizable="true" :closeOnEscape="true" v-model:visible="ShowCustomers" :style="{width: '50vw'}">
              <template #header>
                <DataTable :value="customers" :paginator="true" :rows="loadRows" selectionMode="single"
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  :rowsPerPageOptions="[5,10,20,50]" responsiveLayout="scroll"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                  <Column style="padding:4px;" field="company_name" header="Company Name" :sortable="true"></Column>
                  <Column style="padding:4px;" header="Products">
                      <template #body="slotProducts">  
                      
                        <span v-if="slotProducts.data.products">
                          <small  v-for="productObj in slotProducts.data.products" :key="productObj" class="badge badge-primary mr-1">{{productObj.product?productObj.product.name:''}}</small>
                        </span>

                        <span v-if="(slotProducts.data.products).length<1">
                          <small class="badge badge-danger">No Products yet!</small>
                        </span>
                      </template>
                  </Column>
                  <Column style="padding:4px;" field="created_at" header="Created At" :sortable="true">
                    <template #body="slotDate">
                      {{ moment(slotDate.data.created_at).format("MM/DD/YYYY") }}
                    </template>
                  </Column>
                  <Column style="padding:4px;" header="Actions">
                    <template #body="slotProps">
                    <button class="btn btn-circle btn-secondary btn-sm mr-1" v-tooltip.top="'Send SMS Survey link'" :disabled="smsbtnDisabled" v-on:click="customerSendSMS(slotProps.data.id)"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
                      <button v-on:click="sendSurveyLink(slotProps.data.id)" v-tooltip.top="'Send email Survey link'" class="btn btn-circle btn-info btn-sm ml-1" :disabled="mailbtnDisabled"><i class="fa fa-envelope" aria-hidden="true"></i></button>
                    </template>
                  </Column>
                </DataTable>
              </template>
            </Dialog>
          </form>
        </div>
      </div>
    </div>
    <ProgressSpinner v-if="loading" style="width:50px;height:50px" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"/>
    <div class="card-body" v-else>
      <DataTable :value="surveys" :paginator="true" style="padding:4px;" :rows="loadRows" selectionMode="single"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5,10,20,50]" responsiveLayout="scroll"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
        <Column style="padding:4px;" field="name" header="Survey Name" :sortable="true"></Column>

        <Column style="padding:4px;" field="" header="Survey for email">
          <template #body="slotDate">
            <label class="switch">
              <input type="radio" v-model="selectedSurvey" id="checkboxActive" style="background-color: #1cc88a;" :value="slotDate.data.id" />
              <span class="slider round"></span>
            </label>
          </template>
        </Column>

        <Column style="padding:4px;" field="created_at" header="Created At" :sortable="true">
          <template #body="slotDate">
            {{ moment(slotDate.data.created_at).format("MM/DD/YYYY") }}
          </template>
        </Column>

        <Column style="padding:4px;" header="Actions">
            <template #body="slotProps">
              <button class="btn btn-circle btn-secondary btn-sm mr-1" v-tooltip.top="'Send Message'" v-on:click="showsmsDialog(slotProps.data.id)"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
              <Dialog :maximizable="true" :closeOnEscape="true" v-model:visible="smsModeldisplay" :style="{width: '35vw'}">
                  <template #header>
                    <h5>Send sms for Survey link</h5>
                  </template>
                  <sendSMS :surveyID="SurveyID" :base_url="env_api_url" :model="smsModeldisplay" @smsModeldisplay="smsdialog($event)" />
              </Dialog>

              <button class="btn btn-circle btn-info  btn-sm mr-1" v-tooltip.top="'Send Email'" v-on:click="showDialog(slotProps.data.id)"><i class="fa fa-envelope" aria-hidden="true"></i></button>

              <Dialog :maximizable="true" :closeOnEscape="true" v-model:visible="display" :style="{width: '25vw'}">
                  <template #header>
                    <h5>Send email for Survey link</h5>
                  </template>
                  <sendEmail :surveyID="SurveyID" :base_url="env_api_url" :model="display" @Display="dialog($event)" />
              </Dialog>
              
              <router-link v-tooltip.top="'Test Survey'" target="_blank" class="btn btn-circle btn-success  btn-sm mr-1" :to="{name:'TestSurvey', params: { id:slotProps.data.id}}" ><i class="fa fa-eye" aria-hidden="true"></i></router-link >
              <router-link :to="{name:'EditSurvey', params: { id:slotProps.data.id}}" ><i class="fas fa-edit btn btn-circle btn-primary  btn-sm"></i></router-link >
              <button v-on:click="deleteSurvey(slotProps.data.id)" class="btn btn-circle btn-danger  btn-sm ml-1"><i class="fas fa-trash"></i></button>
            </template>
        </Column>
      </DataTable>
    </div>
    <Toast /><ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script>
import Tooltip from 'primevue/tooltip';
import sendEmail from './partials/sendEmail.vue';
import sendSMS from './partials/sendSMS.vue';
import TableHeader from "../SharedComponents/TableHeader.vue";
import axios from "axios";
import moment from "moment";
import Dialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import DownloadButton from '../SharedComponents/DownloadButton.vue';
import ProgressSpinner from 'primevue/progressspinner';
import { eventBus } from '../../eventBus';

export default {
  directives: {
    'tooltip': Tooltip
  },
  
  components: { 
    sendEmail,
    sendSMS,
    TableHeader, 
    DataTable, 
    Column, 
    Dialog,
    DownloadButton, 
    ProgressSpinner,
    ConfirmDialog,
    Toast
  },

  data() {
    return {
      filters:{
        
      },
      searchFilters:{},
      loading: true,
      loadRows: 10,
      surveys: [],
      products: [],
      customers: [],
      selectedSurvey: null,
      moment: moment,
      mailbtnDisabled: false,
      smsbtnDisabled: false,
      display: false,
      smsModeldisplay: false,
      ShowCustomers: false,
      ExcelFileName: "Surveys.xlsx",
      SurveyID: null,
      totalRows: 0,
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      base_url:process.env.VUE_APP_SERVICE_URL
    };
  },
  watch: {
    filters: {
      deep: true,
      handler () {
        
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getSurveys()
            this.loading=true;
            this.awaitingSearch = false;
          }, 700); // 1 sec delay
        }
        this.awaitingSearch = true;
      }
    },
    searchFilters: {
      deep: true,
      handler () {
        
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getSurveys()
            this.loading=true;
            this.awaitingSearch = false;
          }, 700); // 1 sec delay
        }
        this.awaitingSearch = true;
      }
    },
  },
  computed:{
    exportUrl(){
      let url = this.base_url + '/export/surveys';
      return url;
    }
  },
  mounted(){
    this.$refs.search.focus();
  },
  methods: {

    loadProducts: function () {
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
      axios.get(this.env_api_url + '/product', {headers})
      .then(response => {
          this.products = response.data.products;
      })
    },

    showDialog: function (id) {
      this.SurveyID = id;
      this.display = true;
    },
    
    resetSearch: function () {
      this.searchFilters = {}
    },
    
    sendSurveyLink: function (id) {

      this.mailbtnDisabled = true
      var data = {
        customer_id: id,
        survey_id: this.selectedSurvey,
        base_url: this.base_url,
      };
      
      var headers = {
        Authorization:
        `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      axios
      .post(process.env.VUE_APP_SERVICE_URL + "/survey/send_email_survey_link", data, { headers })
      .then((response) => {
          if (response.status === 200) {
            console.log("Email send successfully");
          }
          this.mailbtnDisabled = false
          this.$notify({
              type: "success",
              title: "Success",
              text: "Email send Successfully!",
          });
      }).catch((error) => {
        this.mailbtnDisabled = false
        this.$notify({
          type: "error",
          title: "Something Went Wrong",
          text: "Error!",
        });
        console.warn("Your Error is :", error);
      });
    },
    
    customersDialog: function (type) {

      var headers = {Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),};
      if (type != 0) {
        //Use type filter
        this.filters.type = type;
      }

      axios
        .get(this.base_url+'/customers', {
          headers,
          params: this.filters,
        })
        .then((response) => {
          console.log("All customers", response.data.customers.data)
          this.customers = response.data.customers;
          this.ShowCustomers = true;
        });
    },

    dialog(title)
    {
      this.display=title;
    },
    // SMS Dialog
    showsmsDialog: function (id) {
      this.SurveyID = id;
      this.smsModeldisplay = true;
    },

    smsdialog(title)
    {
      this.smsModeldisplay=title;
    },
    
    customerSendSMS(customerID)
    {
      this.smsbtnDisabled = true;
      var data = {
        customer_id: customerID,
        survey_id: this.selectedSurvey
      };
      
      var headers = {
        Authorization:
          `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      
      axios
        .post(this.env_api_url + '/survey/send_sms_survey_link', data, {
          headers: headers,
        })
        .then((response) => {
          this.smsbtnDisabled = false;
          console.log('Message sent successfully to this customer', response)
          this.$notify({
            type: "success",
            title: "Success",
            text: "Sent Successfully!",
          });
        })
        .catch((error) => {
          this.smsbtnDisabled = false;
          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          console.warn("Your Error is :", error);
        });
    },
    
    getSurveys: function () {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.base_url+'/survey', { 
          headers,
          params: this.searchFilters,
        })
        .then((response) => {

          this.surveys = response.data.surveys;
          this.totalRows = 0;
          // this.customers_url = 0;
          this.$refs.search.focus();
          this.loading = false;
        });
    },

    deleteSurvey: function (SurveyID) {
       
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios
            .delete(this.env_api_url + "/survey/" + SurveyID, {
              headers: headers,
            })
            .then((response) => {
              this.getSurveys();
              this.$toast.add({severity:'success', summary: 'Deleted', detail:'Record deleted successfully!', life: 2000});
              console.log("Deleted Survey: ", response);
            })
            .catch((error) => {
              console.warn("Your Error is :", error);
            });
        },
        reject: () => {
          this.$toast.add({severity:'error', summary: 'Record Safe', detail:'Your record is safe!', life: 2000});
        }
      });
    },
  },
  created() {
    this.getSurveys();
    this.loadProducts();

    eventBus.on('update-filters', (newFilters) => {
      this.searchFilters = newFilters;
    });
  },
};
</script>

<style scoped>
  input:checked + .slider {
    background-color: #1cc88a;
  }

  .mouseHand {
    cursor: pointer;
  }

</style>