<template>
  <form @submit.prevent="formSubmit" class="user">
	<div class="col-md-12 mb-sm-0">
            <small class="required" for="listID">List ID</small>
            <input
              v-model="listID"
              type="text"
              class="form-control form-control-sm"
              :class="{ 'is-invalid': v$.listID.$error }"
              id="listID"
            />
            <span class="text-danger" v-if="v$.listID.$error">
              <span
                class="help-block"
                v-for="validationError in v$.listID.$errors"
                :key="validationError"
              >
                {{ validationError.$message }}
              </span>
            </span>
          </div>
            <div class="col-md-12 mb-sm-0">
            <small class="required" for="tag">Tag</small>
            <input
              v-model="tag"
              type="text"
              class="form-control ex-sm-font form-control-sm"
              :class="{ 'is-invalid': v$.tag.$error }"
              id="tag"
            />
            <span class="text-danger" v-if="v$.tag.$error">
              <span
                class="help-block"
                v-for="validationError in v$.tag.$errors"
                :key="validationError"
              >
                {{ validationError.$message }}
              </span>
            </span>
          </div>
		<button class="btn btn-primary mt-2 ml-3 ex-sm-font btn-sm" :disabled="btnDisabled">{{btnTitle}}</button>
  </form>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import axios from "axios";
export default {  
data() {
        return {
          v$: useValidate(),
          env_api_url: process.env.VUE_APP_SERVICE_URL,
          listID: null,
          tag: null,
          btnDisabled: false,
          btnTitle: "Save",
            
        };
},
    validations() {
        return {
            listID: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(150),
            },
            tag: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(150),
            },
        };
    },

    created() {
    var id = 2;
    var headers = {
      Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
    };
    
    axios
    .get(this.env_api_url + "/settings/" + id, { headers })
    .then((response) => {
      this.listID = response.data.ski_lead_listid,
      this.tag = response.data.ski_lead_tag
    })
    .catch((error) => {
      console.warn("Your Error is :", error);
    });
  },

    methods: {
      formSubmit() {
            this.v$.$validate();
            if (!this.v$.$error) {
              
              this.btnDisabled = true;
              this.btnTitle = "Waiting...";

                let data = {
                    setting_id: 2,
                    ski_lead_listid: this.listID,
                    ski_lead_tag: this.tag,
                };
                axios
                    .post(this.env_api_url + "/settings", data, {
                        headers: {
                            Authorization:
                                `Bearer ` +
                                localStorage.getItem("rezo_customers_user"),
                        },
                    })
                    .then(() => {
                        this.$notify({
                            type: "success",
                            title: "Success",
                            text: "Save Successfully!",
                        });
                        
                      this.btnDisabled = false;
                      this.btnTitle = "Save";
                    })
                    .catch(() => {
                        this.$notify({
                            type: "error",
                            title: "Error",
                            text: "Something went wrong",
                        });
                        
                      this.btnDisabled = false;
                      this.btnTitle = "Save";
                    });
            }
        },

    },
}
</script>

<style scoped>
.ex-sm-font {
  font-size: 12px;
}
</style>