<template>
  <page-header :title="'Edit Survey | '" :module_title="survey.name"/>

  <ul class="nav nav-pills nav-fill mt-3 mb-3">
    
    <li class="nav-item">
      <a
        class="nav-link "
        :class="{'active': activeTab == 'basic'}" 
        @click="goToTab('basic')"
        href="javascript:void(0)"
        ><i class="fas fa-clipboard-list"></i> Survey Form</a
      >
    </li>
    
    <li class="nav-item">
      <a class="nav-link" 
      :class="{'active': activeTab == 'surveyQuestions'}" 
      href="javascript:void(0)"
       @click="goToTab('surveyQuestions')"
        ><i class="fa fa-copy"></i> Questions</a
      >
    </li>

    <!-- 
    <li class="nav-item">
      <a class="nav-link"
        :class="{'active':activeTab == 'contactDetails'}"
        href="javascript:void(0)"
        @click="goToTab('contactDetails')"
        ><i class="fa fa-book"></i> Contact Details</a
      >
    </li>

    <li class="nav-item">
      <a class="nav-link"
        :class="{'active':activeTab == 'salesInfo'}"
        href="javascript:void(0)"
        @click="goToTab('salesInfo')"
        ><i class="fa fa-info-circle"></i> Sales info</a
      >
    </li> -->

    <!-- <li class="nav-item">
      <a class="nav-link" :class="{'active':activeTab == 'SaleInfoHistory'}" href="javascript:void(0)" @click="goToTab('SaleInfoHistory')" ><i class="fa fa-book"></i> History</a>
    </li> -->

    
  </ul>

  <section v-if="activeTab == 'basic'">
    <survey-form />
  </section>

  <section v-if="activeTab == 'surveyQuestions'">
    <survey-questions />
  </section>

  <!--
  <section v-if="activeTab == 'salesInfo'">
    <lead-salesInfo />
  </section>

  <section v-if="activeTab == 'contactDetails'">
    <lead-contactDetails />
  </section>

  <section v-if="activeTab == 'SaleInfoHistory'">
    <lead-saleHistory />
  </section>

   -->

</template>

<script>
import PageHeader from "../SharedComponents/PageHeader.vue";
import surveyForm from "./Create.vue";
// import LeadSalesInfo from './partials/LeadSalesInfo.vue';
// import LeadContactDetails from './partials/LeadContactDetails.vue';
// import LeadSaleHistory from './partials/LeadSaleHistory.vue';
import surveyQuestions from './partials/Questions.vue';

import axios from "axios";
import { useRoute } from 'vue-router'

export default {
  setup(){

  },

  components: { 
    PageHeader, 
    surveyForm,
    surveyQuestions,
    // LeadSalesInfo,
    // LeadContactDetails,
    // LeadSaleHistory,
  },
  
  data() {
    const route = useRoute()
    console.log(route.query)
    return {
      survey: null,
      base_url: process.env.VUE_APP_SERVICE_URL,
      activeTab: route.query.activeTab==undefined?'basic':route.query.activeTab,
    };
  },
  created() {
    this.getSurvey();
  },
  methods: {
    goToTab(param) {
      //router.push({ path: '', query: { plan: 'private' }})
      this.$router.replace({ query: { activeTab: param } });
      this.activeTab = param;
    },

    getSurvey: function () {
      var survey_id = this.$route.params.id;

      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };

      axios.get(this.base_url + '/survey/' + survey_id, {headers})
      .then((response) => {
        // console.log("Get Survey: ", response.data.survey);
        this.survey = response.data.survey;
      }).catch((error) => {
          console.warn('Your Error is :', error);
      })
    },
  }

};
</script>
