<template>
    <form @submit.prevent="sendSmsForm" class="user">
        <div class="form-group row">
            <div class="col-md-8 mb-sm-0">
                <label class="required" for="email">Enter Phone</label>
                <InputMask ref="phone" autocomplete="chrome-off" v-model="phone" mask="(999) 999-9999" placeholder="(999) 999-9999" class="form-control form-control-sm" :class="{ 'is-invalid': v$.phone.$error }"/>
                <span class="text-danger" v-if="v$.phone.$error">
                    <span
                        class="help-block"
                        v-for="validationError in v$.phone.$errors"
                        :key="validationError"
                    >
                        {{ validationError.$message }}
                    </span>
                </span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-12 mb-sm-0">
                <label class="required" for="email">Type Message</label>
                <textarea rows="10" v-model="message" id="message" class="form-control form-control-sm" :class="{ 'is-invalid': v$.phone.$error }"></textarea>
                <span class="text-danger" v-if="v$.message.$error">
                    <span
                        class="help-block"
                        v-for="validationError in v$.message.$errors"
                        :key="validationError"
                    >
                        {{ validationError.$message }}
                    </span>
                </span>
            </div>
        </div>

        <button class="btn btn-primary" :disabled="btnDisabled">{{btnTitle}}</button>
    </form>
</template>

<script>
    import InputMask from 'primevue/inputmask';
    import {required, helpers } from "@vuelidate/validators";
    import axios from "axios";
    import useValidate from "@vuelidate/core";
    export default {
        
        components: {InputMask},

        props:["surveyID", "base_url"],
        data() {
            return {
            v$: useValidate(),

            btnTitle: 'Send',
            phone: null,
            message: null,
            btnDisabled: false,
            env_api_url: process.env.VUE_APP_SERVICE_URL
            };
        },
        
        validations() {
            return {
                phone: {
                    required
                },
                message: {
                    required: helpers.withMessage('Please write any message!', required),
                    
                }
            };
        },

        created() {
            var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
            axios.get(this.env_api_url + '/survey/' + this.surveyID, {headers})
            .then((response) => {

                let txt = 
                    response.data.survey.name +
                    '\r\n' + response.data.survey.description + 
                    '\r\n' +
                    '\r\n' + 'Please open the Survey link' + 
                    '\r\n' + 'https://customer.rezosystems.com/#' + '/survey/' + this.surveyID + '/rezocustomers';

                this.message = txt;

            }).catch((error) => {
                console.warn('Your Error is :', error);
            })
        },

        methods: {
            hideDialog()
            {
                this.$emit('smsModeldisplay',false)
            },

            sendSmsForm()
            {
                this.v$.$validate();
                if (this.v$.$error) {
                    console.log("errors");
                } else {

                    this.btnDisabled = true
                    this.btnTitle = 'Waiting...'

                    var data = {
                        phone: this.phone,
                        message: this.message,
                        subject: this.subject,
                        survey_id: this.surveyID,
                        base_url: this.base_url
                    };
                    
                    var headers = {
                        Authorization:
                        `Bearer ` + localStorage.getItem("rezo_customers_user"),
                    };

                    axios
                    .post(this.env_api_url + "/survey/send_sms", data, { headers })
                    .then((response) => {
                        if (response.status === 200) {
                            console.log("Sms send successfully");
                        }

                        this.btnDisabled = false
                        this.btnTitle = 'Send'
                        this.hideDialog();
                        this.$notify({
                            type: "success",
                            title: "Success",
                            text: "Sms send Successfully!",
                        });
                    }).catch((error) => {

                        this.btnDisabled = false
                        this.btnTitle = 'Send'

                        this.$notify({
                            type: "error",
                            title: "Something Went Wrong",
                            text: "Error!",
                        });
                        
                        console.warn("Your Error is :", error);
                    });
                }
            }
        }
    }
</script>