<template>
<page-header :title="'New Server'" />
<div class="card col-md-6 mb-3">
<div class="card-body" style="line-height: 4px;">
  <form @submit.prevent="formSubmit" class="user">
    <!--((( Row Start )))-->
    <div class="form-group row mt-3">
      <div class="col-sm-6">
        <label for="exampleserverName" class="required"> Server Name</label>
        <input
          v-model="serverName"
          autocomplete="chrome-off"
          type="text"
          class="form-control form-control-sm"
          :class="{ 'is-invalid': v$.serverName.$error }"
          id="exampleserverName"
          ref="focus"
        />
        <span class="text-danger" v-if="v$.serverName.$error">
          <span
            class="help-block mt-2"
            v-for="validationError in v$.serverName.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
      <div class="col-sm-6">
        <label for="serverIp" class="required"> Server IP</label>
        <input
          v-model="serverIp"
          autocomplete="chrome-off"
          type="text"
          class="form-control form-control-sm"
          :class="{ 'is-invalid': v$.serverIp.$error }"
          id="serverIp"
        />
        <span class="text-danger" v-if="v$.serverIp.$error">
          <span
            class="help-block mt-2"
            v-for="validationError in v$.serverIp.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <p><strong>SSH Details</strong></p>
      </div>
      <div class="col-sm-6">
        <label for="sshLogin" class="required"> Login</label>
        <input
          v-model="sshLogin"
          autocomplete="chrome-off"
          type="text"
          class="form-control form-control-sm"
          :class="{ 'is-invalid': v$.sshLogin.$error }"
          id="sshLogin"
        />
        <span class="text-danger" v-if="v$.sshLogin.$error">
          <span
            class="help-block mt-2"
            v-for="validationError in v$.sshLogin.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      
      </div>
      <div class="col-sm-6">
        <label for="sshPassword" class="required"> Password</label>
        <div class="input-group">
          <input
            v-model="sshPassword"
            autocomplete="chrome-off"
            v-bind:class="{text_As_Pswrd: disc_text, 'is-invalid': v$.sshPassword.$error}"
            class="form-control form-control-sm"
            id="sshPassword"
          />
          <div class="input-group-prepend">
            <div v-on:click="showPasword" style="cursor: pointer;" class="input-group-text">
              <i id="eyeIcon" class="fa fa-eye" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <span class="text-danger" v-if="v$.sshPassword.$error">
          <span
            class="help-block mt-2"
            v-for="validationError in v$.sshPassword.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
        
        
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-4">
        <label for="displayOrder">Display Order</label>
        <input
          v-model="displayOrder"
          autocomplete="chrome-off"
          type="number"
          min="0"
          class="form-control form-control-sm"
          :class="{ 'is-invalid': v$.displayOrder.$error }"
          id="displayOrder"
        />
        <span class="text-danger" v-if="v$.displayOrder.$error">
          <span
            class="help-block mt-2"
            v-for="validationError in v$.displayOrder.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4">
        <div class="">
          <input type="checkbox" v-model="dedicated" id="is_dedicated" :class="{ 'is-invalid': v$.dedicated.$error }">
          <label class="ml-1" for="is_dedicated">Dedicated</label>
        </div>
        <span class="text-danger" v-if="v$.dedicated.$error">
          <span
            class="help-block mt-2"
            v-for="validationError in v$.dedicated.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
    </div>
    <button class="btn btn-primary" :disabled="btnDisabled">{{btnTitle}}</button>
    <hr />
  </form>
</div>
</div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, minLength,integer, maxLength } from "@vuelidate/validators";
import PageHeader from "../SharedComponents/PageHeader.vue";
import axios from "axios";

export default {
  components: {PageHeader},
  data() {
    return {
      v$: useValidate(),
      serverName:null,
      serverIp: null,
      sshLogin: null,
      sshPassword: null,
      displayOrder: null,
      dedicated: null,
      disc_text:true,
      btnDisabled:false,
      btnTitle:"Create",
      env_api_url: process.env.VUE_APP_SERVICE_URL,
    };
  },
  validations() {
    return {
      serverName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      serverIp: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      sshLogin: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      sshPassword: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      displayOrder: {
        integer,
      },
      dedicated: {
      },
    };
  },
  mounted(){
    this.$refs.focus.focus();
  },
  methods: {
    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors", this.v$.$error);
      } else {

        this.btnDisabled = true;
        this.btnTitle = "Waiting...";

        var data = {
            name: this.serverName,
            server_ip: this.serverIp,
            ssh_login: this.sshLogin,
            ssh_password: this.sshPassword,
            display_order: this.displayOrder,
            dedicated: this.dedicated
        };
        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };
        axios.post(this.env_api_url + "/servers", data, { headers }).then(
          (response) => {
            if (response.status === 200) {
              this.$notify({
                type: "success",
                title: "Success",
                text: "Saved Successfully!",
              });
              console.log("Form submitted successfully");
              this.$router.push({ path: "/servers" });
            }
            console.log(response);
          },
          (error) => {

            this.btnDisabled = false;
            this.btnTitle = "Create";

            console.log(error.response.data.error);
            this.$notify({
              type: "error",
              title: "Something went wrong!",
              text: error.response.data.error,
            });
         }
        );
      }
    },
    showPasword() {
      this.disc_text = !this.disc_text; //Disc text class will fail
    },
  },
};
</script>
<style scoped>
  .text_As_Pswrd {
    -webkit-text-security: disc;
  }
  .zoom {
    transition: transform .2s;
  }
  .zoom:hover {
    color:#dfc04e;
    transform: scale(1.5);
  }
  .spinner-border{
    height: 20px;
    width: 20px;
  }
</style>