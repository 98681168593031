<template>
    <form @submit.prevent="sendSmsForm" class="user">
        <div class="form-group row">
            <label class="required ml-3">Select Product</label>
            <div class="col-md-12 mb-sm-0">
                <MultiSelect
                    v-model="selectedProducts" 
                    :options="products" 
                    optionLabel="name" 
                    optionValue="id"
                    placeholder="Select Products"
                    :filter="true"
                    filterPlaceholder="Search product"
                    display="chip"
                />
                <span class="text-danger" v-if="v$.selectedProducts.$error">
                    <span
                        class="help-block"
                        v-for="validationError in v$.selectedProducts.$errors"
                        :key="validationError"
                    >
                        {{ validationError.$message }}
                    </span>
                </span>
            </div>

            <div class="col-md-12 mb-sm-0">
                <label class="required" for="email">Type Message</label>
                <textarea ref="msg" rows="10" v-model="message" id="message" class="form-control form-control-sm"></textarea>
                <span class="text-danger" v-if="v$.message.$error">
                    <span
                        class="help-block"
                        v-for="validationError in v$.message.$errors"
                        :key="validationError"
                    >
                        {{ validationError.$message }}
                    </span>
                </span>
            </div>
        </div>

        <button class="btn btn-primary" :disabled="btnDisabled">{{btnTitle}}</button>
    </form>
</template>

<script>
    import {required, helpers } from "@vuelidate/validators";
    import axios from "axios";
    import useValidate from "@vuelidate/core";
    import MultiSelect from 'primevue/multiselect';

    export default {

        components:{
            MultiSelect
        },
        
        data() {
            return {
                v$: useValidate(),
                btnTitle: 'Send',
                productID: null,
                message: null,
                products: [],
                selectedProducts: null,
                btnDisabled: false,
                env_api_url: process.env.VUE_APP_SERVICE_URL
            };
        },
        
        validations() {
            return {
                selectedProducts: {
                    required: helpers.withMessage('Industry must be selected!', required),
                },
                message: {
                    required: helpers.withMessage('Please write any message!', required),
                }
            };
        },

        mounted(){
            this.$refs.msg.focus();
        },

        created() {
            this.loadProducts();
        },

        methods: {
            hideDialog()
            {
                this.$emit('smsModeldisplay',false)
            },

            sendSmsForm()
            {
                this.v$.$validate();
                if (this.v$.$error) {
                    console.log("errors");
                } else {

                    this.btnDisabled = true
                    this.btnTitle = 'Waiting...'

                    var data = {
                        productIDs: this.selectedProducts,
                        message: this.message
                    };
                    
                    var headers = {
                        Authorization:
                        `Bearer ` + localStorage.getItem("rezo_customers_user"),
                    };

                    axios
                    .post(this.env_api_url + "/list/send_sms", data, { headers })
                    .then((response) => {
                        if (response.status === 200) {
                            console.log("Sms send successfully");
                        }

                        this.btnDisabled = false
                        this.btnTitle = 'Send'
                        this.hideDialog();
                        this.$notify({
                            type: "success",
                            title: "Success",
                            text: "Sms send Successfully!",
                        });
                    }).catch((error) => {

                        this.btnDisabled = false
                        this.btnTitle = 'Send'

                        this.$notify({
                            type: "error",
                            title: "Something Went Wrong",
                            text: "Error!",
                        });
                        
                        console.warn("Your Error is :", error);
                    });
                }
            },
            loadProducts: function () {

                var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
                axios.get(process.env.VUE_APP_SERVICE_URL + '/product', {headers})
                .then(response => {
                console.log("All products from Database -->", response.data.products)

                this.products = response.data.products;

                })

            },
        }
    }
</script>
<style scoped>
   /* Styling here */
</style>