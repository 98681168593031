<template>
  <!-- DataTales Example -->
  <div class="card shadow mr-5">
    <div class="card-header" style="padding:0px;">
      <div class="row mt-3">
        <div class="col-md-6">
          <table-header
            :title="'Demo Modules'"
            :hasLink="true"
            :linkTo="'/demo_modules/create'"
            :linkText="'New Demo'"
          />
        </div>
        <div class="col-md-6">
          <form class="form-inline float-right mr-3">
            <div class="form-group">
              <input
                v-model="filters.search_demo"
                class="form-control form-control-sm"
                placeholder="Name"
                ref="search"
              />
            </div>
            <div class="form-group ml-1">
              <button type="button" class="btn btn-danger btn-sm" @click="this.filters={}">Reset</button>
            </div>
          </form>
          <DownloadButton class="float-right ml-1 mr-1" :url="this.env_api_url + '/export/1/rezo_modules/'" fileName="Rezo_Modules-Demo.xlsx" />
        </div>
      </div>
    </div>
    <ProgressSpinner v-if="loading" style="width:50px;height:50px" strokeWidth="1" fill="var(--surface-ground)" animationDuration=".5s"/>
    <div class="card-body" v-else>
      <DataTable :value="demo_modules.data" style="padding:4px;" selectionMode="single">
        <Column style="padding:4px;" field="name" :sortable="true" header="Company Name"></Column>
        <Column style="padding:4px;" field="created_at" header="Created At" :sortable="true">
          <template #body="slotDate">
            {{ moment(slotDate.data.created_at).format("MM/DD/YYYY") }}
          </template>
        </Column>
        <Column style="padding:4px;" header="Actions">
            <template #body="slotProps">
              <router-link class="btn btn-circle btn-primary  btn-sm" :to="{ name: 'EditDemoModule', params: { id: slotProps.data.id } }"><i class="fas fa-edit"></i></router-link>
                <button v-on:click="deleteDemoModule(slotProps.data.id)" class="btn btn-circle btn-danger  btn-sm ml-1"><i class="fas fa-trash"></i></button>
            </template>
        </Column>
      </DataTable>
      <div v-if="demo_modules.data.length > 0" class="pagination d-flex justify-content-center">
        <button class="btn btn-outline-secondary pt-0 pb-0 mr-1" @click="getDemoModules(demo_modules.first_page_url)" :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> First Page </button>
        <button class="btn btn-outline-secondary pt-0 pb-0 mr-2" @click="getDemoModules(demo_modules.prev_page_url)" :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> Previous </button>
        <label>Page {{ pagination.current_page }} of {{ pagination.last_page }}</label>
        <button class="btn btn-outline-secondary pt-0 pb-0 ml-2" @click="getDemoModules(demo_modules.next_page_url)" :disabled="pagination.current_page == pagination.last_page" style="font-size: 14px; height: 30px;">Next </button>
        <button class="btn btn-outline-secondary pt-0 pb-0 ml-1" @click="getDemoModules(demo_modules.last_page_url)" :disabled="pagination.current_page == pagination.last_page" style="font-size: 14px; height: 30px;"> Last Page </button>

        <div class="ml-1">
          <select v-model="filters.per_page" class="form-control" style="border: 1px solid #858796; height: 30px; padding-top: 2px;">
            <option selected :value="5">5</option>
            <option selected :value="10">10</option>
            <option selected :value="20">20</option>
            <option selected :value="50">50</option>
            <option selected :value="100">100</option>
          </select>
        </div>
      </div>
    </div>
    <Toast /><ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script>
import TableHeader from "../SharedComponents/TableHeader.vue";
import axios from "axios";
import moment from "moment";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import DownloadButton from '../SharedComponents/DownloadButton.vue';
import ProgressSpinner from 'primevue/progressspinner';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import { eventBus } from "../../eventBus";
export default {
  components: { TableHeader, DataTable, Column, DownloadButton, ProgressSpinner, ConfirmDialog, Toast },
  data() {
    return {
      filters:{
        per_page:10
      },
      loading: true,
      demo_modules: [],
      pagination: {},
      moment: moment,
      totalRows: 0,
      env_api_url:process.env.VUE_APP_SERVICE_URL  ,
      customer_url:'',
    };
  },
  watch: {
    filters: {
        deep: true,
        handler () {
          
          if (!this.awaitingSearch) {
            setTimeout(() => {
              this.getDemoModules()
              this.loading=true;
              this.awaitingSearch = false;
            }, 700); // 1 sec delay
          }
          this.awaitingSearch = true;
          
        }
      },
    },
  methods: {

    getDemoModules: function (page_url) {
      this.loading = true;
      let vm = this;
      page_url = page_url || this.env_api_url +'/rezo_modules'

      this.filters.type = this.$route.query.type?this.$route.query.type:"";
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      
      axios.get(page_url, { headers, params: this.filters })
      .then((response) => {
        vm.makePagination(response.data)
        this.totalRows = response.data.demo_modules_counter;
        this.demo_modules = response.data.demo_modules;
        this.$refs.search.focus();
        this.loading = false;
      });
    },

    makePagination: function (data) {

      let pagination = {
        current_page: data.demo_modules.current_page,
        last_page: data.demo_modules.last_page,
        next_page_url: this.env_api_url + '/rezo_modules?page=' + (data.demo_modules.current_page + 1),
        prev_page_url: this.env_api_url + '/rezo_modules?page=' + (data.demo_modules.current_page - 1),
      }
 
      this.pagination = pagination;
    },

    deleteDemoModule: function (moduleId) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios
          .delete(this.env_api_url + '/rezo_modules/' + moduleId, {
            headers: headers,
          })
         .then((response) => {
           this.getDemoModules();
            this.$toast.add({severity:'success', summary: 'Deleted', detail:'Record deleted successfully!', life: 2000});
            console.log("Delete Demo Module basic data: ", response);
          })
          .catch((error) => {
            console.warn("Your Error is :", error);
          });
            
        },
        reject: () => {
            this.$toast.add({severity:'error', summary: 'Record Safe', detail:'Your record is safe!', life: 2000});
        }
      });
    },
  },
  mounted(){
    this.$refs.search.focus();
  },
  created() {
  this.getDemoModules();

  eventBus.on('update-filters', (newFilters) => {
    console.log('Demo Module log: ', newFilters);
    this.filters = {
      per_page:10
    };
  });
  },
};
</script>
