<template>
  <div class="justify-content-center">
    <div class="card o-hidden border-0 shadow-lg my-5" style="border-radius: 0px;">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-lg-12">
            <div class="p-5">
              <p class="h4 pull-left text-primary m-5"></p>
                <div class="form-group row m-3 question-card">
                  <h4 class="p-2">Thank you for completing the survey!</h4>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      
      };
    },
  };
</script>

<style scoped>
  .question-card {
    padding: 10px;
    box-shadow: 1px 1px 20px 2px rgb(121 183 183 / 20%);
    border-radius: 7px;
    background: #dff0d8;
    color: 3c763d;
  }
</style>
