<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card mb-3">
        <div class="card-body">
          <p class="card-title">Survey Questions</p>
          <!-- <label class="required" for="questionTitle">Question Title</label> -->
          <div class="row">
            <div class="col-sm-12">
              <div
                class="card mb-2"
                v-for="question in surveyQuestions"
                :key="question"
              >
                <div class="card-body">
                  <p class="card-title">
                    <strong>Title: </strong>{{ question.title }}
                    <span class="float-right"
                      ><button
                        @click="editsurveyQuestions(question)"
                        class="btn btn-circle btn-primary btn-sm mr-1"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                      <button
                        class="btn btn-circle btn-danger btn-sm mr-1"
                        @click="deletesurveyQuestions(question)"
                      >
                        <i class="fas fa-trash"></i></button
                    ></span>
                  </p>
                  <p class="card-text">
                    <strong>Type: </strong>{{ question.question_type }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 mb-4">
      <div class="card">
        <form @submit.prevent="formSubmit" class="user">
          <div class="card-body">
            <!-- <div class="row">
              <div class="col-md-10">
                <h5 class="card-title">{{ questionFormTitle }}</h5>
              </div>
              <div class="col-md-2">
                <button
                  v-show="newSurveyQuestion.title"
                  type="button"
                  @click="resetForm()"
                  class="btn float-right btn-warning btn-circle btn-sm" >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div> -->

            <div class="row">
              <div class="col-md-8">
                <select v-model="newSurveyQuestion.question_type" class="form-control form-control-sm mb-3" aria-placeholder="test">
                  <option selected :value="undefined">-- Question type * --</option>
                  <option value="checkbox">Multiple choice -- Single answer</option>
                  <option value="checkbox_multiple">Multiple choice -- Multiple answers</option>
                  <option value="textbox">Textbox</option>
                  <option value="stars">Stars</option>
                  <option value="scale">Scale</option>
                </select>
              </div>  
              <div class="col-md-4 ">
                <!-- <label class="required" for="questionDisplayOrder">Display order</label> -->
                <input
                  v-model="newSurveyQuestion.display_order"
                  type="number"
                  class="form-control form-control-sm mb-3"
                  placeholder="Display order *"
                />
              </div>
            </div>
            
            <div class="row">
              <div class="col-md-12">
                <input
                  v-model="newSurveyQuestion.title"
                  type="text"
                  class="form-control form-control-sm mb-3"
                  placeholder="Question title *"
                  ref="focus"
                />
              </div>
            </div>
            
            <!-- For checkbox, checkbox_multiple type -->
            <span
              v-show="newSurveyQuestion.question_type == 'checkbox' || newSurveyQuestion.question_type == 'checkbox_multiple' || newSurveyQuestion.question_type == 'scale'"
              class="text-danger form-control-sm">
              Give one option per line
            </span>
            
            <!-- For Scale type -->
            <p v-show="newSurveyQuestion.question_type == 'scale'" class="text-danger form-control-sm mb-5">
              (Please add you 2 end points of the scale (Example: Bad & Great). Then add how many levels of scale, for example 1 to 5 or 1 to 10. So between the 2 end point, you add each # of the scale on a line)
            </p>

            <!-- Description hide for type= Stars, Textbox -->
            <div class="row" v-show="newSurveyQuestion.question_type != 'textbox' && newSurveyQuestion.question_type != 'stars'">
              <div class="col-md-12">
                <textarea v-model="newSurveyQuestion.description" class="form-control form-control-sm mb-3" rows="4" cols="50" maxlength="200" placeholder="Description"></textarea>
              </div>
            </div>
    
            <!-- Select length for Textbox -->
            <div class="row mb-3" v-show="newSurveyQuestion.question_type == 'textbox'">
              <div class="col-md-12">
                <div class="form-check">
                  <input v-model="newSurveyQuestion.textbox_size" class="form-check-input" type="radio" name="textbox_size" id="shortLength" value="short" checked>
                  <label class="form-check-label" for="shortLength"> Short - 1 line 45 characters long </label>
                </div>
                <div class="form-check">
                  <input v-model="newSurveyQuestion.textbox_size" class="form-check-input" type="radio" name="textbox_size" id="mediumLength" value="medium">
                  <label class="form-check-label" for="mediumLength"> Medium - 3 lines 135 characters long </label>
                </div>
                <div class="form-check">
                  <input v-model="newSurveyQuestion.textbox_size" class="form-check-input" type="radio" name="textbox_size" id="longLength" value="long">
                  <label class="form-check-label" for="longLength"> Long - 8 lines 360 characters long </label>
                </div>
              </div>
            </div>

            <div class="row">
              <!-- <div class="col-md-6" :class="{ 'col-md-12': !newSurveyQuestion.question_type }"> -->
              <div class="col-md-12">
                <button :disabled="!newSurveyQuestion.question_type || !newSurveyQuestion.display_order || !newSurveyQuestion.title" class="btn btn-primary btn-sm btn-block"><strong>Save</strong></button>
              </div>
              <!-- <div class="col-md-6">
                <button v-show="newSurveyQuestion.question_type" type="button" @click="resetForm()" class="btn btn-warning btn-sm btn-block"><strong>Clear</strong></button>
              </div> -->
            </div>

          </div>
        </form>
      </div>
      <Toast /><ConfirmDialog></ConfirmDialog>
    </div>
  </div>
</template>
<script>

import axios from "axios";
import { mapState } from "vuex";
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
export default {
  computed: mapState(["user"]),
  components: {ConfirmDialog, Toast},
  data() {
    return {
      newSurveyQuestion: {},
      base_url: process.env.VUE_APP_SERVICE_URL,
      questionFormTitle: "Add Question",
      surveyQuestions: [],
    };
  },
  mounted(){
    this.$refs.focus.focus();
  },
  created() {
    this.getSurveyQuestions();
  },
  methods: {

    getSurveyQuestions: function ()
    {
      var survey_id = this.$route.params.id;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      axios
      .get(this.base_url + "/survey/" + survey_id, { headers })
      .then((response) => {
        console.log(
          "All Questions of this survey --> ",
          response.data.survey_questions
        );
        this.surveyQuestions = response.data.survey_questions;
      })
      .catch((error) => {
        console.warn("Your Error is :", error);
      });
    },

    getQuestionOptions: function (question_id)
    {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      axios
      .get(this.base_url + "/question/" + question_id, { headers })
      .then((response) => {
        
        console.log("All Options --> ", response.data.question_options);
        this.newSurveyQuestion.description = response.data.question_options;

      })
      .catch((error) => {
        console.warn("Your Error is :", error);
      });
    },

    formSubmit() {

      var data = {
        "survey_id": this.$route.params.id,
        "id": this.newSurveyQuestion.id,
        "title": this.newSurveyQuestion.title,
        "display_order": this.newSurveyQuestion.display_order,
        "question_type": this.newSurveyQuestion.question_type,
        "textbox_size": this.newSurveyQuestion.textbox_size,
        "description": this.newSurveyQuestion.description,
      };

      var headers = {
        Authorization:
          `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      axios
      .post(this.base_url + "/question", data, { headers })
      .then((response) => {
        if (response.status === 200)
        {
          this.$notify({
            type: "success",
            title: "Success",
            text: "Save Successfully!",
          });
          
          this.resetForm();
          this.getSurveyQuestions();
        }
        console.log(response);
      })
      .catch((error) => {
        this.$notify({
          type: "error",
          title: "Something Went Wrong",
          text: "Error!",
        });
        console.warn("Your Error is :", error);
      });
    },

    editsurveyQuestions(question) {

      this.getQuestionOptions(question.id);

      this.newSurveyQuestion =question;
      this.questionFormTitle = "Edit Question";
    },
    deletesurveyQuestions(question) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        // message: "Do you want to delete " + question.title + "?",
        header: 'Delete',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios
            .delete(
              this.base_url + "/question/" + question.id,
              {
                headers: headers,
              }
            )
            .then((response) => {
              
              this.$toast.add({severity:'success', summary: 'Deleted', detail:'Record deleted successfully!', life: 2000});
              console.log(response);
              this.getSurveyQuestions();
            })
            .catch((error) => {
              console.warn("Your Error is :", error);
            });
        },
        reject: () => {
          this.$toast.add({severity:'error', summary: 'Record Safe', detail:'Your record is safe!', life: 2000});
        }
      });
    },
    resetForm() {
      this.questionFormTitle = "Add Question";
      this.newSurveyQuestion = {};
    },
  },
};
</script>
