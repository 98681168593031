<template>
  <page-header :title="'Edit Customer | '" :module_title="customerTitle.company_name"/>
  <ul class="nav nav-pills nav-fill mt-3 mb-3">
    
    <li class="nav-item">
      <a
        class="nav-link "
        :class="{'active': activeTab == 'basic'}" 
        @click="goToTab('basic')"
        href="javascript:void(0)"
        ><i class="fa fa-user"></i> Basic</a
      >
    </li>

    <li class="nav-item">
      <a class="nav-link"
        :class="{'active':activeTab == 'products'}"
        href="javascript:void(0)"
        @click="goToTab('products')"
        ><i class="fa fa-project-diagram"></i> Products</a
      >
    </li>


    <li class="nav-item">
      <a class="nav-link" 
        :class="{'active':activeTab == 'servers'}"  
        href="javascript:void(0)"
        @click="goToTab('servers')"
        ><i class="fa fa-server"></i> Servers</a
      >
    </li>
    <li class="nav-item">
      <a class="nav-link" 
      :class="{'active': activeTab == 'Services'}" 
      href="javascript:void(0)"
      @click="goToTab('Services')"
        ><i class="fab fa-servicestack"></i> Support Services</a
      >
    </li>
    
    <!-- <li class="nav-item">
      <a class="nav-link" 
      :class="{'active': activeTab == 'Archives'}" 
      href="javascript:void(0)"
      @click="goToTab('Archives')"
        ><i class="fa fa-clock"></i> Archived</a
      >
    </li> -->
    
    <!-- <li class="nav-item">
      <a class="nav-link" 
      :class="{'active': activeTab == 'actionItems'}" 
      href="javascript:void(0)"
       @click="goToTab('actionItems')"
        ><i class="fa fa-server"></i> Action Items</a
      >
    </li> -->
    <li class="nav-item">
      <a class="nav-link" 
      :class="{'active': activeTab == 'notes'}" 
      href="javascript:void(0)"
       @click="goToTab('notes')"
        ><i class="fa fa-copy"></i> Notes</a
      >
    </li>
  </ul>
  <section v-if="activeTab == 'basic'">
    <customer-form />
  </section>
  <section v-if="activeTab == 'servers'">
    <customer-servers />
  </section>
  <section v-if="activeTab == 'products'">
    <customer-products />
  </section>
  <section v-if="activeTab == 'notes'">
    <customer-notes />
  </section>
  <section v-if="activeTab == 'actionItems'">
    <customer-actionItems />
  </section>
  <section v-if="activeTab == 'Services'">
    <customer-Services />
  </section>
  <section v-if="activeTab == 'Archives'">
    <customer-Archives />
  </section>
</template>

<script>
import PageHeader from "../SharedComponents/PageHeader.vue";
import CustomerForm from "./partials/CustomerForm.vue";
import CustomerProducts from './partials/CustomerProducts.vue';
import CustomerServers from "./partials/CustomerServers.vue";
import { useRoute } from 'vue-router'
import CustomerNotes from './partials/CustomerNotes.vue';
import CustomerActionItems from './partials/actionItems.vue'
import CustomerServices from './partials/CustomerServices.vue'
import CustomerArchives from './partials/CustomerArchives.vue'
import axios from "axios";
export default {
  setup(){

    
  },
  components: { PageHeader, CustomerForm, CustomerServers, CustomerProducts ,CustomerNotes,CustomerActionItems, CustomerServices, CustomerArchives },
  data() {
    const route = useRoute()
    console.log(route.query)
    return {
      customerTitle: null,
      base_url: process.env.VUE_APP_SERVICE_URL,
      activeTab: route.query.activeTab==undefined?'basic':route.query.activeTab,
    };
  },
  created() {
    this.getCustomerTitle();
  },
  methods: {
    goToTab(param) {
      //router.push({ path: '', query: { plan: 'private' }})
      this.$router.replace({ query: { activeTab: param } });
      this.activeTab = param;
    },
        getCustomerTitle: function () {
      var customerId = this.$route.params.id;
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };

        axios.get(this.base_url + '/customers/'+customerId, {headers})
        .then((response) => {
          this.customerTitle = response.data.customer_title;
          
        }).catch((error) => {
            console.warn('Your Error is :', error);
        })
    },
  },
};
</script>
