<template>
  <h4 class=""><strong>{{survey.name}}</strong> - Survey Report</h4>
  <div class="card col-md-12 mb-3">
    <div class="card-body" style="line-height: 4px;">
      <div class="form-group row mt-3">
        <div class="col-md-12 mb-sm-0">
          <div v-for="(question, index) in survey_questions" :key="question">
            <div class="form-group row">
              <h5 for="input"><strong>{{index+1}})</strong> {{question.title}}</h5>
            </div>
            <div class="card graphCard">
              <Chart type="bar" :data="question.graph_dataset" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Chart from 'primevue/chart';

  export default {
    
    components: { Chart },

    data() {
      return {
        base_url: process.env.VUE_APP_SERVICE_URL,
        survey: [],
        survey_questions: [],

        ////-------------------Dummy graph data-------------------\\\\
          // basicData: { 
          // 	// labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          // 	labels: [],
          // 	datasets: [
          // 		{
          // 			label: 'My First dataset',
          // 			backgroundColor: '#42A5F5',
          // 			data: [100, 90, 80, 70, 55, 25, 10]
          // 		},
          // 		{
          // 			label: 'My Second dataset',
          // 			backgroundColor: '#9CCC65',
          // 			data: [10, 20, 30, 40, 50,100, 50]
          // 		}
          // 	]
          // }
        ////-------------------Dummy graph data-------------------\\\\
        
      };
    },

    created() {
      this.getReportData();
    },

    methods:
    {
      getReportData: function ()
      {
        var surveyID = this.$route.params.id;
        var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
        
        axios.get(this.base_url + '/report/data/' + surveyID, {headers})
        .then((response) => {
      
          this.survey = response.data.survey;
          this.survey_questions = response.data.survey_questions;

        }).catch((error) => {

          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          
          console.warn("Your Error is :", error);
        });
      }
    }
  };
</script>
<style scoped>
  .graphCard {
    padding: 20px;
    margin: 38px;
  }
</style>