<template>
  <page-header :title="formTitle" />
  <div class="card col-md-6 mb-3">
    <div class="card-body" style="line-height: 4px;">
      <form @submit.prevent="formSubmit" class="user">
        <div class="form-group row mt-3">
          <div class="col-sm-6 mb-sm-0">
            <!-- <input
              autocomplete="chrome-off"
              v-model="productName"
              type="text"
              class="form-control mt-2 form-control-sm mb-1"
              id="productName"
            /> -->

            <span class="p-float-label mt-3">
              <InputText id="productName" class="form-control-sm" :class="{ 'is-invalid': v$.productName.$error }" type="text" v-model="productName" />
              <label for="productName" class="required">Product Name</label>
            </span>
           
            <div class="mt-2">
              <span class="text-danger" v-if="v$.productName.$error">
                <span class="help-block" v-for="validationError in v$.productName.$errors" :key="validationError">
                  {{ validationError.$message }}
                </span>
              </span>
            </div>
          </div>
        </div>
        
        <div class="form-group row mt-3">
          <div class="col-sm-6 mb-sm-0">
            <!-- <input
              autocomplete="chrome-off"
              v-model="displayOrder"
              type="number"
              class="form-control mt-2 form-control-sm mb-1" :class="{ 'is-invalid': v$.displayOrder.$error }"
              id="displayOrder"
            /> -->

            <div class="p-inputtext-sm">
              <span class="p-float-label mt-3">
                <InputNumber id="displayOrder" v-model="displayOrder" :min="0" :max="1000" :showButtons="true" />
                <label for="displayOrder" class="required">Display order</label>
              </span>
            </div>

            <div class="mt-2">
              <span class="text-danger" v-if="v$.displayOrder.$error">
                <span class="help-block" v-for="validationError in v$.displayOrder.$errors" :key="validationError">
                  {{ validationError.$message }}
                </span>
              </span>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-3">
            <div class="form-inline">
              <div class="form-group">
                <label class="mr-1" for="checkboxActive">Active</label>
                <label class="switch">
                  <input type="checkbox" id="checkboxActive" v-model="isActive" />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-3 mb-sm-0">
            <button class="btn btn-primary btn-sm" :disabled="btnDisabled">{{btnTitle}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import {
  required
} from "@vuelidate/validators";
import PageHeader from "../SharedComponents/PageHeader.vue";
import axios from "axios";
export default {
  components: { 
    PageHeader, 
    InputText,
    InputNumber,  
  },

  data() {
    return {
    v$: useValidate(),    
    base_url: process.env.VUE_APP_SERVICE_URL,

    formTitle: "New Product",
    productID:null,
    productName:null,
    displayOrder:null,
    isActive: false,

    btnDisabled: false,
    btnTitle: "Create"
    };
  },

  validations() {
    return {
      productName: {
        required
      },
      displayOrder: {
        required
      }
    };
  },

created() {
  
  if (this.$route.name == "EditProduct")
  {
    this.formTitle = "Edit Product";
    this.productID = this.$route.params.id;
    this.getProduct();
    this.btnTitle = "Update"
  }

},

  methods: {
   
    getProduct: function ()
    {
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
      axios.get(this.base_url + '/product/' + this.productID, {headers})
      .then((response) => {
        this.productName = response.data.product.name;
        this.displayOrder = response.data.product.display_order;
        this.isActive = response.data.product.active?true:false;
      }).catch((error) => {
        console.warn('Your Error is :', error);
      })
    },

    formSubmit() { 
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors",this.v$.$error);
      } else {

        this.btnDisabled = true;
        this.btnTitle = "Waiting...";

        var data = {
          product_id : this.productID,
          name : this.productName,
          display_order: this.displayOrder,
          active: this.isActive,
        };

        var headers = {
          Authorization:`Bearer ` + localStorage.getItem("rezo_customers_user"),
        };

        axios
        .post(this.base_url + "/product", data, { headers })
        .then((response) => {
          if (response.status === 200)
          {
            this.btnDisabled = false;
            this.btnTitle = this.$route.name == "EditProduct" ? "Update":"Create";

            this.$notify({
              type: "success",
              title: "Success",
              text: "Save Successfully!",
            });

            this.$router.push("/products")
          }
        }).catch((error) => {

          this.btnDisabled = false

          if (this.$route.name == "EditProduct")
          {
            this.btnTitle = 'Update';
          }
          else
          {
            this.btnTitle = 'Create';
          }

          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          
          console.warn("Your Error is :", error);
        });
      }
    },
  },
};
</script>