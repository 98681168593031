<template>
    <div v-if="loading" class="d-flex flex-column justify-content-center">
        <ProgressSpinner style="width:50px;height:50px;" strokeWidth="1" fill="var(--surface-ground)"
            animationDuration=".5s" />
    </div>
    <div v-else>
        <form @submit.prevent="sendSurveyLink" class="user">
            <label class="ml-2 text-secondary">Select Survey for SMS to <strong>{{ phone }}</strong></label>
            <div class="card-body">
                <div class="row" v-for="survey in surveys" :key="survey.id">
                    <div class="col-md-10">
                        {{ survey.name }}
                    </div>
                    <div class="col-md-2">
                        <label class="switch">
                            <input type="radio" style="background-color: #e74a3b;" v-model="selectedSurvey"
                                id="checkboxActive" :value="survey.id" />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>
            <button class="btn btn-danger btn-sm" @click="sendSurvey()" :disabled="selectedSurvey == null">{{ btnTitle
            }}</button>
        </form>
    </div>
</template>
<script>

import axios from "axios";
import ProgressSpinner from 'primevue/progressspinner';

export default {

    components: {
        ProgressSpinner
    },

    props: ["survey_sms_number"],
    data() {
        return {
            base_url: process.env.VUE_APP_SERVICE_URL,
            btnTitle: 'Send Survey',
            phone: this.survey_sms_number,
            btnDisabled: false,
            selectedSurvey: null,
            loading: true,
            surveys: [],
        };
    },

    created() {
        this.getSurveys();
    },

    methods: {

        getSurveys: function () {
            var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };
            axios
                .get(this.base_url + '/survey', { headers, params: this.searchFilters, })
                .then((response) => {
                    this.surveys = response.data.surveys;
                    this.loading = false;
                });
        },

        sendSurveyLink: function () {
            var data = {
                user_id: this.$route.params.id,
                survey_id: this.selectedSurvey,
                phone_number: this.phone,
                base_url: this.base_url,
            };

            this.btnTitle = "Please wait...";

            var api_url = this.base_url + "/customer/send_sms_survey_link";
            var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };
            axios
                .post(api_url, data, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        console.log("survey send successfully");
                        this.btnTitle = "Send Survey";
                        this.hideDialogSurvey();
                    }
                }).catch((error) => {
                    this.btnTitle = "Send Survey";
                    this.$notify({
                        type: "error",
                        title: "Something Went Wrong",
                        text: "Error!",
                    });
                    console.warn("Your Error is :", error);
                });
        },

        hideDialogSurvey() {
            this.$emit('surveyDisplay', false)
        },
    },
}
</script>
<style scoped>
input:checked+.slider {
    background-color: #e74a3b;
}

.mouseHand {
    cursor: pointer;
}
</style>