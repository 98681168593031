<template>
    <div class="mb-4">
      <div class="card border-left-warning shadow py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="h6 font-weight-bold mb-2 text-center text-primary text-uppercase"> Weekly <small>({{totalRow}})</small> 
                <button
                  v-show="newTask.task_title"
                  type="button"
                  @click="resetForm()"
                  class="btn btn-warning float-right btn-circle btn-sm"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>

              </div>

                <form @submit.prevent="formSubmit" class="user">
                    <div class="form-group row">
                        <div class="col-md-12 mb-2">
                          <input v-model="newTask.task_title" type="text" placeholder="Title" class="form-control form-control-sm" />
                        </div>

                        <div class="col-md-8 mb-2">
                          <input v-model="newTask.display_order" type="number" class="form-control form-control-sm" placeholder="Display Order" />
                        </div>

                        <div class="col-md-4 md-form form-sm">
                            <button :disabled="!newTask.task_title" class="btn btn-primary btn-sm btn-block">{{ btnTitle }}</button>
                        </div>
                    </div>
                </form>

                <table id="table_layout" class="table">
                  <tbody>
                    <tr class="zoom" v-for="task in tasks" :key="task">
                      <td style="line-height: 18px;padding: 4px; font-size: 12px;">{{task.task_title}}</td>
                      <td id="customer_td_width">
                        <i @click="editTask(task)" class="fas fa-edit text-warning zoomBtn"></i>
                        <i v-on:click="deleteTask(task)" class="fas ml-2 fa-trash text-danger zoomBtn"></i>
                      </td>
                    </tr>
                  </tbody>
                  <p v-if="totalRow == 0" class="text-danger text-center">No task found!</p>
                </table>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex'

export default {
  data() {
    return {
      newTask: {},

      btnTitle: "Add",
      task_type: null,
      env_api_url: process.env.VUE_APP_SERVICE_URL,

      totalRow: 0,
      tasks:[],
    };
  },

  watch: {
    user: {
      immediate:true,
      handler(user){
        if(user.id != 0)
          this.getWeekList();
      }
    }
  },

  methods: {
    getWeekList: function ()
    {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      
      axios
        .get(this.env_api_url + "/todo?user_id=" + this.user.id+"&task_type=2", { headers })
        .then((response) => {

          console.log("Week", response);
          let tasks = response.data.tasks;
          this.tasks = tasks;
          this.totalRow = tasks.length;
        
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
    },

    resetForm() {
      this.btnTitle = "Add";
      this.newTask = {};
    },

    editTask(task) {
      this.newTask = task;
      this.btnTitle = "Update";
    },
    deleteTask(task) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      this.$confirm.require({
          message: 'Are you sure you want to proceed?',
          header: 'Delete',
          acceptClass: 'p-button-success',
          rejectClass: 'p-button-danger',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
             axios
              .delete(
                this.env_api_url + "/todo/" + task.id,
                {
                  headers: headers,
                }
              )
              .then((response) => {
                this.$toast.add({severity:'success', summary: 'Deleted', detail:'Record deleted successfully!', life: 2000});
                console.log("Delete monthly task: ", response);
                this.getWeekList();
              })
              .catch((error) => {
                console.warn("Your Error is :", error);
              }); 
          },
          reject: () => {
              this.$toast.add({severity:'error', summary: 'Record Safe', detail:'Your record is safe!', life: 2000});
          }
      });
    },

    formSubmit()
    {
      var data = {
        id: this.newTask.id,
        task_title: this.newTask.task_title,
        display_order: this.newTask.display_order,
        user_id: this.user.id,
        task_type: 2, //For Week task
      };
      
      var headers = {
        Authorization:
          `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
     
      axios
        .post(process.env.VUE_APP_SERVICE_URL + "/todo", data, { headers })
        .then((response) => {
          if (response.status === 200) {

            this.$notify({
              type: "success",
              title: "Success",
              text: "Saved Successfully!",
            });

            console.log("Form submitted successfully");
            this.getWeekList();
          }
            this.resetForm();
          console.log(response);
        }).catch((error) => {

          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          
          console.warn("Your Error is :", error);
        });
    },
  },
  computed: mapState(['user'])
}
</script>

<style scoped>
  .zoom {
    transition: transform .2s;
  }
  .zoom:hover {
    transform: scale(1.1);
    background: rgb(238, 238, 238);
    border-radius: 10%;
  }
  .zoomBtn {
    transition: transform .2s;
  }
  .zoomBtn:hover {
    transform: scale(1.3);
    background: rgb(238, 238, 238);
    border-radius: 10%;
  }

  #table_layout 
	{
		table-layout:fixed;
		width:100%;
		overflow-wrap: break-word;
	}

  #customer_td_width {
    width: 16%;
    padding: 0px;
    vertical-align: middle;
  }
</style>