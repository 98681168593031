<template>
  <page-header :title="'Edit Business Module'" />
  <div class="card col-md-6 mb-3">
    <div class="card-body" style="line-height: 4px;">
      <form @submit.prevent="formSubmit" class="user">
        <!--((( Row Start )))-->
        <div class="form-group row mt-3">
          <div class="col-sm-12">
            <!-- <label for="exampleCompanyName" class="required"> Model Name</label> -->
            <input v-model="moduleName" autocomplete="chrome-off" placeholder="Model Name" type="text" class="form-control form-control-sm"
              :class="{ 'is-invalid': v$.moduleName.$error }" id="examplecompanyName" />
            <span class="text-danger" v-if="v$.moduleName.$error">
              <span class="help-block mt-2" v-for="validationError in v$.moduleName.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span>
          </div>
        </div>

        <div class="form-group row mt-3">
          <div class="col-md-6">
            <!-- <label for="" class="required"> Servers</label> -->
            <div class="">
              <select v-model="server_id" @change="onSelect(server_id)" class="form-control form-control-sm"  :class="{ 'is-invalid': v$.server_id.$error }">
                <option value="null">--Select Server--</option>
                <option :value="server.id" v-for="server in servers" :key="server">
                  {{ server.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <!-- <label for="">IP Address</label> -->
            <div class="">
              <input type="text" v-model="ip_address" readonly placeholder="IP address" class="form-control form-control-sm">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10 mb-3">
            <!-- <label for="exampledomainName" class="required"> Domain name</label> -->
            <input type="text" v-model="domainName" placeholder="Domain Name" autocomplete="chrome-off"
              :class="{ 'is-invalid': v$.domainName.$error }" class="form-control form-control-sm"
              id="exampledomainName">
            <span class="text-danger" v-if="v$.domainName.$error">
              <span class="help-block mt-2" v-for="validationError in v$.domainName.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span>
          </div>

          <div class="col-md-2" style="padding-top: 3px;">
            <a :href="domainName" target="_blank" class="btn btn-info btn-sm float-right">Open</a>
          </div>

          <div class="col-md-10 mb-3">
            <!-- <label for="examplerepo" class="required"> Repository link</label> -->
            <input type="text" autocomplete="chrome-off" placeholder="Repository link" v-model="moduleRepo"
              :class="{ 'is-invalid': v$.moduleRepo.$error }" class="form-control form-control-sm" id="examplerepo">
            <span class="text-danger" v-if="v$.moduleRepo.$error">
              <span class="help-block mt-2" v-for="validationError in v$.moduleRepo.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span>
          </div>

          <div class="col-md-2" style="padding-top: 3px;">
            <a :href="moduleRepo" target="_blank" class="btn btn-info btn-sm float-right">Open</a>
          </div>

          <div class="col-md-12 mb-3">
            <!-- <label for="">Document Root</label> -->
            <input type="text" autocomplete="chrome-off" placeholder="Document Root" v-model="doc_root" class="form-control form-control-sm">
          </div>

          <div class="col-md-4 mb-1">
            <!-- <label for="exampleDbName"> DB Name</label> -->
            <input type="text" v-model="dbName" placeholder="DB Name" autocomplete="chrome-off"
              class="form-control form-control-sm" id="exampleDbName">
            <!-- <span class="text-danger" v-if="v$.dbName.$error">
              <span class="help-block mt-2" v-for="validationError in v$.dbName.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span> -->
          </div>

          <div class="col-md-4 mb-1">
            <!-- <label for="exampleDbUser"> DB User</label> -->
            <input type="text" placeholder="DB Name" autocomplete="chrome-off" v-model="dbUser"
              class="form-control form-control-sm" id="exampleDbUser">
            <!-- <span class="text-danger" v-if="v$.dbUser.$error">
              <span class="help-block mt-2" v-for="validationError in v$.dbUser.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span> -->
          </div>

          <div class="col-md-4 mb-1">
            <!-- <label for="exampleDbPassword"> DB Password</label> -->
            <input v-bind:class="{ text_As_Pswrd: disc_text }" placeholder="DB Password" v-model="dbPassword"
              autocomplete="chrome-off" class="form-control form-control-sm" id="exampleDbPassword">
            <i v-on:click="showPasword" id="eyeIcon" class="fa fa-eye zoom ml-1 mt-1" style="cursor: pointer;"
              aria-hidden="true"></i>
            <!-- <span class="text-danger" v-if="v$.dbPassword.$error">
              <span class="help-block mt-2" v-for="validationError in v$.dbPassword.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span> -->
          </div>

          <div class="col-md-10 mb-3">
            <!-- <label for="examplephpMyAdmin"> Phpmyadmin</label> -->
            <input type="text" v-model="phpMyAdmin" placeholder="Phpmyadmin" autocomplete="chrome-off"
              class="form-control form-control-sm"
              id="examplephpMyAdmin">
            <!-- <span class="text-danger" v-if="v$.phpMyAdmin.$error">
              <span class="help-block mt-2" v-for="validationError in v$.phpMyAdmin.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span> -->
          </div>
          <div class="col-md-2 mb-3">
            <a :href="phpMyAdmin" target="_blank" class="btn btn-info btn-sm float-right">Open</a>
          </div>
        </div>

                
        <div class="row">
          <div class="col-sm-12">
            <!-- <label for="exampleCompanyName">Note</label> -->
            <textarea
              placeholder="Note"
              v-model="notes"
              class="form-control"
              cols="20"
              rows="2"
            ></textarea>
          </div>
        </div>

        <div class="mt-3">
          <!-- Copy details are in Textbox -->
          <textarea v-model="copyDetails" hidden class="form-control form-control-sm" rows="4" cols="100" maxlength="200" ></textarea>

          <div class="float-right justify-content-between">
            <button type="button" class="btn btn-success btn-sm mr-2 mb-3 rounded" @click="copy(copyDetails)">Copy</button>
            <button type="button" class="btn btn-info btn-sm mr-2 mb-3 rounded" @click="openEmailsHistoryModal">
              Emails History
            </button>
            <button type="button" class="btn btn-primary btn-sm mb-3 rounded" @click="openSendEmailModal">
              Send Email
            </button>
          </div>
        </div>
        
        <button class="btn btn-primary btn-sm" :disabled="btnDisabled">{{ btnTitle }}</button>
      </form>
      <Dialog header="Send Email for Server Detail" v-model:visible="isDisplaySendEmailModal"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }" :style="{ width: '30vw' }" appendTo="body" :modal="true"
        :dismissableMask="true" :maximizable="true" :closeOnEscape="true">
        <form @submit.prevent="sendEmailForm" class="mb-2">
          <div class="form-group">
            <label for="email">Email address</label>
            <input type="email" v-model="email" autocomplete="chrome-off" class="form-control form-control-sm"
              placeholder="Recipient Email" id="email" required />
          </div>
          <div class="form-group">
            <label for="subject">Subject</label>
            <input type="subject" autocomplete="chrome-off" v-model="subject" class="form-control form-control-sm"
              id="subject" placeholder="Subject" required />
          </div>
          <div class="form-gruop mt-4">
            <ProgressBar v-if="isSendEmailModalLoading" mode="indeterminate" />
            <button v-else type="submit" class="btn btn-primary btn-sm float-right">
              Send
            </button>
          </div>
        </form>
      </Dialog>

      <Dialog :closeOnEscape="true" header="View Sent Emails" v-model:visible="isDisplayEmailsHistoryModal"
        :breakpoints="{ '960px': '90vw', '640px': '95vw' }" :style="{ width: '60vw' }" appendTo="body" :modal="true"
        :dismissableMask="true" :maximizable="true">
        <table class="table table-striped">
          <thead>
            <tr>
              <!-- <th scope="col">#</th> -->
              <th scope="col">To</th>
              <th scope="col">Subject</th>
              <th scope="col">Date/Time</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="mailsHistoryTableLoading">
              <td colspan="5" class="text-center">
                <span class="spinner-border spinner-border-sm spinner-primary"></span>
              </td>
            </tr>
            <tr v-else-if="mailsHistory.length === 0">
              <td colspan="5" class="text-center">
                No Record.
              </td>
            </tr>
            <tr v-else v-for="mail in mailsHistory" :key="mail.id">
              <!-- <th scope="row">{{ mail.id }}</th> -->
              <td>{{ mail.to }}</td>
              <td>{{ mail.subject }}</td>
              <td>{{ moment(mail.created_at).format("MM/DD/YYYY hh:mm A") }}</td>
              <td>
                <div v-show="emailsHistoryLoading == mail.id">
                  <span class="spinner-border spinner-border-sm spinner-primary"></span>
                </div>
                <div v-show="emailsHistoryLoading != mail.id" class="btn-group">
                  <button class="btn btn-info btn-sm" @click="
                    () => {
                      viewMail(mail.id);
                    }
                  ">
                    View
                  </button>
                  <button class="btn btn-primary btn-sm" @click="
                    () => {
                      resendEmail(mail.id);
                    }
                  ">
                    Resend
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Dialog>
    </div>
  </div>
  <Toast />
</template>

<script>
import PageHeader from "../SharedComponents/PageHeader.vue";
import useValidate from "@vuelidate/core";
import Dialog from "primevue/dialog";
import ProgressBar from "primevue/progressbar";
import moment from "moment";
import { required, minLength, maxLength } from "@vuelidate/validators";
import axios from "axios";
import { mapState } from "vuex";
import Toast from 'primevue/toast';

export default {
  computed: mapState(["user"]),
  components: { PageHeader, Dialog, ProgressBar, Toast },
  data() {
    return {
      v$: useValidate(),
      servers: [],
      server_id: "",
      disc_text: true,
      moment: moment,
      moduleName: null,
      ip_address: null,
      notes: null,
      doc_root: null,
      domainName: null,
      moduleRepo: null,
      dbName: null,
      dbUser: null,
      dbPassword: null,
      phpMyAdmin: null,
      btnDisabled: false,
      btnTitle: "Update",
      env_api_url: process.env.VUE_APP_SERVICE_URL,

      isDisplaySendEmailModal: false,
      isSendEmailModalLoading: false,
      email: null,
      subject: null,
      isDisplayEmailsHistoryModal: false,
      emailsHistoryLoading: false,
      mailsHistory: [],
      mailsHistoryTableLoading: false,
    };
  },
  validations() {
    return {
      server_id: {
        required
      },
      moduleName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(150),
      },
      domainName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      moduleRepo: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      dbName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(125),
      },
      dbUser: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(125),
      },
      dbPassword: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(125),
      },
      phpMyAdmin: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
    };
  },
  created() {
    this.getServers();
    this.email = this.user.email;
    var businessModuleId = this.$route.params.id;
    var headers = {
      Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
    };

    axios
      .get(this.env_api_url + "/rezo_modules/" + businessModuleId, { headers })
      .then((response) => {
        console.log("Edit Business Module Record: ", response.data.module);
        this.moduleName = response.data.module.name,
          this.subject = this.moduleName + ' | Business Details',
          this.domainName = response.data.module.domain_name,
          this.moduleRepo = response.data.module.repo,
          this.dbName = response.data.module.db_name,
          this.dbUser = response.data.module.db_user,
          this.dbPassword = response.data.module.db_password,
          this.phpMyAdmin = response.data.module.php_my_admin,
          this.server_id = response.data.module.server_id,
          this.ip_address = response.data.module.ip_address,
          this.doc_root = response.data.module.doc_root,
          this.notes = response.data.module.notes
          
          this.getServer(response.data.module.server_id);

          let txt = 'Please find the Access to the "' + this.moduleName + '" DB.' + '\r\n' + this.moduleRepo + '\r\n' + this.dbName + '\r\n' + this.dbUser + '\r\n' + this.dbPassword + '\r\n' + this.phpMyAdmin;
          this.copyDetails= txt;

      })
      .catch((error) => {
        console.warn("Your Error is :", error);
      });
  },

  methods: {
    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors", this.v$.$error);
      } else {

        this.btnDisabled = true;
        this.btnTitle = "Waiting...";

        var data = {
          id: this.$route.params.id,
          name: this.moduleName,
          domain_name: this.domainName,
          repo: this.moduleRepo,
          db_name: this.dbName,
          db_user: this.dbUser,
          db_password: this.dbPassword,
          php_my_admin: this.phpMyAdmin,
          server_id: this.server_id,
          ip_address: this.ip_address,
          doc_root: this.doc_root,
          notes: this.notes,
        };
        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };
        axios.post(this.env_api_url + "/rezo_modules", data, { headers }).then(
          (response) => {
            if (response.status === 200) {
              this.$notify({
                type: "success",
                title: "Success",
                text: "Updated Successfully!",
              });
              console.log("Form updated successfully");
              this.$router.push({ path: "/business_modules" });
            }
            console.log(response);
          },
          (error) => {

            this.btnDisabled = false;
            this.btnTitle = "Update";

            console.log(error.response.data.error);
            this.$notify({
              type: "error",
              title: "Something went wrong!",
              text: error.response.data.error,
            });
          }
        );
      }
    },
        
    onSelect(serverID) {
      this.getServer(serverID);
    },
    
    getServer: function (id) {
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
      axios.get(process.env.VUE_APP_SERVICE_URL + '/servers/' + id, {headers})
      .then(response => {
        this.serverDetail = response.data;
        this.ip_address = this.serverDetail.server_ip;
      })
    },

    sendEmailForm() {
      this.isSendEmailModalLoading = true;
      let data = {
        module_id: this.$route.params.id,
        email: this.email,
        subject: this.subject,
      };
      axios
        .post(this.env_api_url + "/rezo_modules/email", data, {
          headers: {
            Authorization:
              `Bearer ` +
              localStorage.getItem("rezo_customers_user"),
          },
        })
        .then(() => {
          this.$notify({
            type: "success",
            title: "Success",
            text: "Email Sent Successfully!",
          });
          this.v$.$reset();
          this.isDisplaySendEmailModal = false;
          this.isSendEmailModalLoading = false;
        })
        .catch(() => {
          this.$notify({
            type: "error",
            title: "Error",
            text: "Something went wrong",
          });
          this.v$.$reset();
          this.isDisplaySendEmailModal = false;
          this.isSendEmailModalLoading = false;
        });
    },
    openSendEmailModal() {
      this.isDisplaySendEmailModal = true;
      this.email = null;
      this.subject = null;
    },
    openEmailsHistoryModal() {
      this.isDisplayEmailsHistoryModal = true;
      this.mailsHistoryTableLoading = true;
      axios.get(
        this.env_api_url + "/" + this.$route.params.id + "/mails",
        {
          headers: {
            Authorization:
              `Bearer ` +
              localStorage.getItem("rezo_customers_user"),
          },
        }
      ).then((response) => {
        this.mailsHistory = response.data;
        this.mailsHistoryTableLoading = false;
      }).catch(() => {
        this.mailsHistoryTableLoading = false;
      });
    },
    toDateTimeString: function (value) {
      return moment(value).format("LLL");
    },
    viewMail(id) {
      this.emailsHistoryLoading = id;
      axios
        .get(this.env_api_url + "/mails/" + id, {
          headers: {
            Authorization:
              `Bearer ` +
              localStorage.getItem("rezo_customers_user"),
          },
        })
        .then((response) => {
          let viewMailWindow = open('/', "viewMail", '', true);
          viewMailWindow.document.write(response.data);
          this.emailsHistoryLoading = false;
        }).catch(() => {
          this.emailsHistoryLoading = false;
        });
    },
    resendEmail(id) {
      this.emailsHistoryLoading = id;
      axios
        .post(
          this.env_api_url + "/rezo_modules/email",
          {
            id,
          },
          {
            headers: {
              Authorization:
                `Bearer ` +
                localStorage.getItem("rezo_customers_user"),
            },
          }
        )
        .then(() => {
          this.$notify({
            type: "success",
            title: "Success",
            text: "Email Sent Successfully!",
          });
          this.isDisplayEmailsHistoryModal = false;
          this.emailsHistoryLoading = false;
        })
        .catch(() => {
          this.$notify({
            type: "error",
            title: "Error",
            text: "Something went wrong",
          });
          this.isDisplayEmailsHistoryModal = false;
          this.emailsHistoryLoading = false;
        });
    },
    getServers: function () {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.env_api_url + "/servers", { headers })
        .then((response) => {
          this.servers = response.data;
        });
    },

    async copy(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.$toast.add({severity:'success', summary: 'Copied', detail:'All details copied!', life: 2000});
      } catch($e) {
        this.$toast.add({severity:'error', summary: "Clipboard error", detail:"Couldn't copied!", life: 2000});
      }
    },

    showPasword() {
      this.disc_text = !this.disc_text; //Disc text class will fail
    },
  },
};
</script>

<style scoped>
.text_As_Pswrd {
  -webkit-text-security: disc;
}

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  color: #dfc04e;
  transform: scale(1.5);
}

.spinner-border {
  height: 20px;
  width: 20px;
}
</style>
