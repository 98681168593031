<template>
  <form @submit.prevent="formSubmit" class="user">
    <div class="form-group row">
      <div class="col-sm-6 mb-3 mb-sm-0">
        <input v-model="firstName" type="text" class="form-control form-control-user" :class="{ 'is-invalid': v$.firstName.$error }" id="exampleFirstName" placeholder="Follow Up Date"/>
        <!-- <span class="text-danger" v-if="v$.firstName.$error">
          <span
            class="help-block"
            v-for="validationError in v$.firstName.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span> -->
      </div>
      <div class="col-sm-6">
        <input
          v-model="lastName"
          type="text"
          class="form-control form-control-user"
          :class="{ 'is-invalid': v$.lastName.$error }"
          id="exampleLastName"
          placeholder="Follow Up Action"
        />
        <!-- <span class="text-danger" v-if="v$.lastName.$error">
          <span
            class="help-block"
            v-for="validationError in v$.lastName.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span> -->
      </div>
    </div>
    <button class="btn btn-primary btn-user btn-block">Submit</button>
    <hr />
  </form>
</template>

<script>
import useValidate from "@vuelidate/core";
// import axios from 'axios'

import {
  required,
  minLength,
  email,
  maxLength,
  sameAs,
} from "@vuelidate/validators";


export default {
  components: {   },
  data() {
    return {
      v$: useValidate(),
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      customer_id: null,
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      confirmPassword: null,
    };
  },
  validations() {
    return {
      firstName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(150),
      },
      lastName: { required, minLength: minLength(3), maxLength: maxLength(150) },
      email: {
        required,
        email,
        minLength: minLength(3),
        maxLength: maxLength(150),
      },
      password: {
        required:((!this.customer_id)? true:false), ///If not edit then Required apply
        minLength: minLength(6),
        maxLength: maxLength(150),
      },
      confirmPassword: {
        required:((!this.customer_id)? true:false), ///If not edit then Required apply
        minLength: minLength(6),
        sameAs:((this.password)? sameAs(this.password):false),
        maxLength: maxLength(150),
      },
    };
  },
  created(){
    // var customerId = this.$route.params.id;
    // var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
    // axios.get(this.env_api_url + '/customers/'+customerId, {headers})
    // .then((response) => {
    //   console.log("Edit Customer Record: ",response.data.customer);
    //   this.customer_id =response.data.customer.id
    //   this.firstName =response.data.customer.first_name
    //   this.lastName =response.data.customer.last_name
    //   this.email =response.data.customer.email
    //   this.password =response.data.customer.password
    // }).catch((error) => {
    //     console.warn('Your Error is :', error);
    // })
  },
  methods: {
    formSubmit() {
      this.v$.$validate();
      alert("submitted");
    },
  },
};
</script>
