<template>
<div class="card col-md-6 mb-3">
  <div class="card-body" style="line-height: 4px;">
    <form @submit.prevent="formSubmit" class="user">
      <!--((( Row Start )))-->
      <div class="form-group row">
        <div class="col-sm-6 mb-3 mt-3 mb-sm-0">
          <label for="examplecompanyName" class="required">Company Name</label>
          <input
            v-model="companyName"
            type="text"
            autocomplete="chrome-off"
            class="form-control form-control-sm" :class="{'is-invalid':v$.companyName.$error}"
            id="examplecompanyName"
          />
          <small class="text-danger" v-if="v$.companyName.$error">
            <span class="help-block mt-2" v-for="validationError in v$.companyName.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </small>
        </div>
        <div class="col-sm-6">
          <div class="float-right" style="padding-top: 28px;">
            <div>
              <!-- <select v-model="rating" style="width: 85px;" class="ml-1 form-control form-control-sm" id="rate" >
                <option  selected :value="0">Rate</option>
                <option  value="1">1</option>
                <option  value="2">2</option>
                <option  value="3">3</option>
                <option  value="4">4</option>
                <option  value="5">5</option>
              </select> -->

              <Dropdown 
                scrollHeight="300px"
                style="padding: 4.5px;" 
                v-model="rating" 
                :options="selecteRating"
                optionValue="value"
                optionLabel="name" 
                placeholder="Rate" 
              />
            </div>
          </div>
        </div>
      </div>
        
      <div class="form-group row">
        <div class="col-sm-12">
          <label for="exampleaddress">Address</label>
          <input
            v-model="address"
            type="text"
            autocomplete="chrome-off"
            class="form-control form-control-sm" :class="{'is-invalid':v$.address.$error}"
            id="exampleaddress"
          />
          <small class="text-danger" v-if="v$.address.$error">
            <span class="help-block" v-for="validationError in v$.address.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </small>
        </div>
      </div>
        
      <div class="form-group row">
        <div class="col-sm-4 mb-3 mb-sm-0">
          <label for="exampleCity">City</label>
          <input
            v-model="city"
            autocomplete="chrome-off"
            type="text"
              id="exampleCity"
            class="form-control form-control-sm" :class="{'is-invalid':v$.address.$error}"
          />
          <small class="text-danger" v-if="v$.city.$error">
            <span class="help-block" v-for="validationError in v$.city.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </small>
        </div>

        <div class="col-sm-5">
          <label for="exampleFormstate">State</label>
          <select v-model="state" class="form-control form-control-sm" style="" id="exampleFormstate" >
          <option  value="">--Select--</option>
          <!-- <option selected value="" disabled>Select</option> -->
          <option v-for="state in states" :key="state">{{state}}</option>
          </select>
        </div>

        <div class="col-sm-3">
          <label for="examplepostalCode">Zip Code</label>
          <input
            v-model="postalCode"
            autocomplete="chrome-off"
            type="number"
            class="form-control form-control-sm mb-2" :class="{'is-invalid':v$.postalCode.$error}"
            id="examplepostalCode"
          />
          <small class="text-danger" v-if="v$.postalCode.$error">
            <span class="help-block" v-for="validationError in v$.postalCode.$errors" :key="validationError">
              {{ validationError.$message }}
            </span>
          </small>
        </div>   
      </div>

      <div class="form-group row mb-4">
        <div class="col-sm-4">
          <label for="examplephone">Phone</label>
          <!-- <input
            v-model="phoneNumber"
            type="text"
            class="form-control form-control-sm" :class="{'is-invalid':v$.phoneNumber.$error}"
            id="examplephone"
            :placeholder="template"
          /> -->
          <InputMask autocomplete="chrome-off" v-model="phoneNumber" mask="(999) 999-9999"  id="examplePhone" class="form-control form-control-sm" :class="{'is-invalid':v$.phoneNumber.$error}"/>
          <small class="text-danger" v-if="v$.phoneNumber.$error">
            <span class="help-block" v-for="validationError in v$.phoneNumber.$errors" :key="validationError" >
              {{ validationError.$message }}
            </span>
          </small>
        </div>
        <div class="col-sm-7">
          <label for="exampleInputEmail" class="required"> Email</label>
          <input
            v-model="email"
            type="email"
            autocomplete="chrome-off"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': v$.email.$error }"
            id="exampleInputEmail"
          />
          <small class="info-block text-danger" v-if="v$.email.$error">
            <span
              class="help-block mt-2"
              v-for="validationError in v$.email.$errors"
              :key="validationError"
            >
              {{ validationError.$message }}
            </span>
          </small>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
            <h5 class="card-title">Contact Details <button type="button" class="btn btn-outline-info float-right btn-sm btn-circle" @click="addMorePetitioner()" ><i class="fa fa-plus" aria-hidden="true"></i></button> </h5>
            <div class="" v-for="(contact, index) in lead_contact_details" :key="contact" >
              <div class="row">
                <div class="col-md-6">
                  <input type="text" autocomplete="chrome-off" placeholder="Contact Name" v-model="contact.contact_name" class="form-control form-control-sm mb-2" />
                  <InputMask autocomplete="chrome-off" mask="(999) 999-9999" placeholder="Contact Phone" id="examplePhone" v-model="contact.contact_phone_number" class="form-control form-control-sm field_set mb-2" />
                </div>
                <div class="col-md-6">
                  <input type="text" autocomplete="chrome-off" placeholder="Title" v-model="contact.contact_title" class="form-control form-control-sm mb-2" />
                  <input type="text" autocomplete="chrome-off" placeholder="Email Address" v-model="contact.contact_email" class="form-control form-control-sm mb-2" />
                </div>
                <div class="col-md-6">
                  <select v-model="contact.product_id" class="form-control form-control-sm" name="" id="" >
                    <option selected :value="undefined">--Product--</option>
                    <option :value="product.id" v-for="product in products" :key="product" >
                      {{ product.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6">
                  <input v-model="contact.website_url" placeholder="Website URL" type="text" class="form-control form-control-sm" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 d-flex justify-content-center">
                  <button type="button" @click="removeInputRow(lead_contact_details, index, contact.id)" class="btn text-center btn-outline-danger btn-sm btn-circle mb-2 mt-2"><i class="fa fa-trash" aria-hidden="true"></i></button>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row mt-3">
        <div class="col-sm-12 mb-sm-0">
          <label for="notes">Notes</label>
          <textarea class="form-control form-control-sm" v-model="notes" rows="3" cols="15"></textarea>
        </div>
      </div>

      <div class="mb-3">
        <div class="flex align-items-left">
          <Checkbox v-model="active" inputId="activeStatus" :binary="true" />
          <label for="activeStatus" class="ml-2">Active</label>

          <Checkbox v-model="isCustomer" inputId="activeStatus" :binary="true" class="ml-3" />
          <label for="activeStatus" class="ml-2">Add to Customer</label>
        </div>
      </div>
      <button class="btn btn-primary" :disabled="btnDisabled">{{btnTitle}}</button>
    </form>

    <Toast />
    <ConfirmDialog></ConfirmDialog>
  </div>
</div>

</template>

<script>
import useValidate from "@vuelidate/core";
import axios from "axios";
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import InputMask from 'primevue/inputmask';
import { required, maxLength, email, helpers } from "@vuelidate/validators";
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';

export default {
  components: {
    InputMask, 
    Toast, 
    ConfirmDialog,
    Dropdown,
    Checkbox
  },

  data() {
      return {
      v$: useValidate(),
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      states: [],
      products: [],
      companyName: null,
      address: null,
      city: null,
      notes: null,
      state: "",
      postalCode: null,
      phoneNumber: null,
      email: undefined,
      active: null,
      isCustomer: null,
      btnDisabled: false,
      btnTitle: "Update",
      rating: 0,
      selecteRating: [
        {name: '1', value: 1},
        {name: '2', value: 2},
        {name: '3', value: 3},
        {name: '4', value: 4},
        {name: '5', value: 5}
      ],
      lead_contact_details: [
        {

        }
      ],
    };
  },
  
  validations() {
    return {
      companyName: {
        required,
        maxLength: maxLength(150),
      },
      address: {
        maxLength: maxLength(150),
      },
      city: {
        maxLength: maxLength(150),
      },
      postalCode: {
        maxLength: helpers.withMessage('Maximum 5', maxLength(5)),
        number: true,
      },
      phoneNumber: {
        number: true,
      },
      email: {
        required,
        email,
        maxLength: maxLength(150),
      },
    };
  },
  created() {
    this.getStates();
    this.loadProducts();
    this.getLeadContactDetails();
    this.getLead();
  },
  
  methods: {

    getLead: function () {
      var leadId = this.$route.params.id;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      
      axios
      .get(this.env_api_url + "/leads/" + leadId, { headers })
      .then((response) => {
        console.log("Edit Lead Record: ", response.data.lead);

        this.companyName = response.data.lead.company_name,
        this.address =  response.data.lead.address,
        this.city = response.data.lead.city,
        this.state = response.data.lead.state,
        this.postalCode = response.data.lead.postal_code,
        this.phoneNumber = response.data.lead.phone_number,
        this.email = response.data.lead.email,
        this.rating = response.data.lead.rating,
        this.active = response.data.lead.is_active?true:false,
        this.notes = response.data.lead.notes,
        this.isCustomer = response.data.lead.is_customer?true:false
      })
      .catch((error) => {
        console.warn("Your Error is :", error);
      });
    },

    loadProducts: function () {
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
      axios.get(process.env.VUE_APP_SERVICE_URL + '/product', {headers})
      .then(response => {
        console.log("All products from Database -->", response.data.products)
        this.products = response.data.products;
      })
    },
    
    addMorePetitioner: function () {
      var new_contacts = {
      };
      this.lead_contact_details.push(new_contacts);
    },
    
    removeInputRow: function (obj, index, contactID) {
      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        accept: () => {
          obj.splice(index, 1);
          this.deleteContactDetail(contactID);
        },
        reject: () => {
          this.$toast.add({severity:'error', summary: 'Record Safe', detail:'Your record is safe!', life: 2000});
        }
      });
    },

    getStates: function () {
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };

        axios.get(this.env_api_url + '/leads', {headers})
        .then((response) => {
          this.states = response.data.states;
        }).catch((error) => {
            console.warn('Your Error is :', error);
        })
      // Get all States of this Customer
    },

    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors",this.v$.$error);
      } else {

        this.btnDisabled = true;
        this.btnTitle = "Waiting...";

        var data = {
          id: this.$route.params.id,
          company_name: this.companyName,
          address: this.address,
          city: this.city,
          state: this.state,
          postal_code: this.postalCode,
          phone_number: this.phoneNumber,
          email: this.email,
          rating: this.rating,
          is_active: this.active,
          is_customer: this.isCustomer,
          lead_contact_details: this.lead_contact_details,
          notes: this.notes
        };

        var headers = {
          Authorization:`Bearer ` + localStorage.getItem("rezo_customers_user"),
        };

        axios
        .post(this.env_api_url + "/leads", data, { headers })
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              type: "success",
              title: "Success",
              text: "Updated Successfully!",
            });
            console.log("Form Updated successfully");
            this.btnDisabled = false;
            this.btnTitle = "Update";
          }

          this.$router.push("/leads");
          console.log(response);
        })
        .catch((error) => {

          this.btnDisabled = false;
          this.btnTitle = "Update";

          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          
          console.warn("Your Error is :", error);
        });
      }
    },

    getLeadContactDetails: function () {
      var lead_id = this.$route.params.id;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      // Get all Contact Details
      axios
      .get(this.env_api_url + "/leads/" + lead_id, { headers })
      .then((response) => {
        console.log(
          "All Contact Details of this lead --> ",response.data.contactDetails
        );
        this.lead_contact_details = response.data.contactDetails;
      })
      .catch((error) => {
        console.warn("Your Error is :", error);
      });
    },

    deleteContactDetail(id) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .delete(this.env_api_url + "/contact_details/" + id,{headers: headers})
        .then((response) => {
          this.$toast.add({severity:'success', summary: 'Deleted', detail:'Record deleted successfully!', life: 2000});
          console.log("Delete successfully", response);
          this.getLeadContactDetails();
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
    }
  },
};
</script>
<style>
#examplePhone{
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
</style>