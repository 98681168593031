<template>
  <div class="row">
    <div class="card col-md-7 mb-3">
      <div class="card-body" style="">
        <div class="d-flex flex-column justify-content-center" v-if="loading">
          <ProgressSpinner style="width:50px;height:50px; margin-top:70px;" strokeWidth="1" fill="var(--surface-ground)"
            animationDuration=".5s" />
        </div>
        <div v-else>
          <form @submit.prevent="formSubmit" class="user">
            <div class="form-group row">
              <div class="col-sm-6 mb-3 mt-3 mb-sm-0">
                <label class="required" for="exampleCompanyName">Company Name</label>
                <input v-model="companyName" type="text" class="form-control form-control-sm"
                  :class="{ 'is-invalid': v$.companyName.$error }" id="exampleCompanyName" autocomplete="chrome-off" />
                <small class="text-danger" v-if="v$.companyName.$error">
                  <span class="help-block mt-2" v-for="validationError in v$.companyName.$errors" :key="validationError">
                    {{ validationError.$message }}
                  </span>
                </small>
              </div>
              <div class="col-sm-6 mt-4">
                <label class="switch" style="padding-top: 22px;">
                  <input type="checkbox" id="checkboxActive" v-model="isInternationalCustomer" />
                  <span class="slider round"></span>
                </label>
                <label class="mr-1" for="checkboxActive">International Customer</label>
                <!-- <Rating v-model="rating" class="ml-3" :stars="5" :cancel="false"/> -->
                <!-- <strong>Created by:</strong> <small class="pull-right badge" :class="isLead?'badge-danger':'badge-success'">{{isLead?'Lead':'Customer'}}</small> -->
              </div>
            </div>
            <div class="form-group row mb-3 mb-sm-0">
              <div :class="'col-sm-12'">
                <label for="exampleaddress"> Address</label>
                <input v-model="address" autocomplete="chrome-off" type="text" class="form-control form-control-sm"
                  :class="{ 'is-invalid': v$.address.$error }" id="exampleaddress" />
              </div>

            </div>
            <div class="form-group row mb-3 mb-sm-0">
              <div class="col-sm-4 ">
                <label for="exampleInputcity"> City</label>
                <input v-model="city" autocomplete="chrome-off" type="text" class="form-control form-control-sm"
                  :class="{ 'is-invalid': v$.city.$error }" id="exampleInputcity" />
                <small class="text-danger" v-if="v$.city.$error">
                  <span class="help-block" v-for="validationError in v$.city.$errors" :key="validationError">
                    {{ validationError.$message }}
                  </span>
                </small>
              </div>

              <div v-if="isInternationalCustomer" class="col-sm-4">
                <label for="province"> Province</label>
                <input v-model="province" autocomplete="chrome-off" type="text" class="form-control form-control-sm"
                  id="province" />
              </div>


              <div v-if="!isInternationalCustomer" class="col-sm-5">
                <label for="selectStates"> State</label>
                <select v-model="state" class="form-control form-control-sm" id="selectStates">
                  <option :value="null">--Select--</option>
                  <!-- <option selected value="" disabled>Select</option> -->
                  <option v-for="state in states" :key="state">{{ state }}</option>
                </select>
              </div>


              <div class="col-sm-3 " v-if="!isInternationalCustomer">
                <label for="examplezipcode"> Zip Code</label>
                <input v-model="zipCode" type="number" class="form-control form-control-sm mb-2"
                  :class="{ 'is-invalid': v$.zipCode.$error }" id="examplezipcode" autocomplete="chrome-off" />
                <span class="info-block text-danger" v-if="v$.zipCode.$error">
                  <span class="help-block" v-for="validationError in v$.zipCode.$errors" :key="validationError">
                    {{ validationError.$message }}
                  </span>
                </span>
              </div>

              <div v-if="isInternationalCustomer" class="col-sm-3">

                <label for="postalCode">Postal Code</label>
                <input v-model="postalCode" type="text" class="form-control form-control-sm mb-2" id="postalCode"
                  autocomplete="chrome-off" />

              </div>

              <div v-if="isInternationalCustomer" class="col-sm-4">

                <label for="selectStates"> Country</label>
                <select v-model="country" class="form-control form-control-sm" id="selectStates">
                  <option :value="null">--Select--</option>
                  <option v-for="country in countries" :key="country">{{ country.name }}</option>
                </select>

              </div>

            </div>

            <div class="form-group row">
              <div class="col-sm-4">
                <label for="examplephone"> Phone</label>
                <InputText type="number" v-if="isInternationalCustomer" v-model="phoneNumber" autocomplete="chrome-off"
                  class="form-control form-control-sm" :class="{ 'is-invalid': v$.phoneNumber.$error }"
                  id="examplephone" />
                <InputMask v-else mask="(999) 999-9999" v-model="phoneNumber" autocomplete="chrome-off"
                  class="form-control form-control-sm field_set" :class="{ 'is-invalid': v$.phoneNumber.$error }"
                  id="examplephone" />
                <small class="text-danger" v-if="v$.phoneNumber.$error">
                  <span class="help-block" v-for="validationError in v$.phoneNumber.$errors" :key="validationError">
                    {{ validationError.$message }}
                  </span>
                </small>
              </div>
              <div class="col-sm-6">
                <label for="exampleInputEmail"> Email <span class="requireAsterisk"></span></label>
                <input v-model="email" autocomplete="chrome-off" type="email" class="form-control form-control-sm"
                  :class="{ 'is-invalid': v$.email.$error }" id="exampleInputEmail" />
                <small class="info-block text-danger" v-if="v$.email.$error">
                  <span class="help-block mt-2" v-for="validationError in v$.email.$errors" :key="validationError">
                    {{ validationError.$message }}
                  </span>
                </small>
              </div>
              <div class="col-sm-1">
                <button type="button" v-tooltip.top="'Send Survey'" v-show="email" v-on:click="surveyEmailDialog(email)"
                  class="btn btn-circle btn-sm btn-outline-primary mt-3"
                  style="height: 19px; width: 21px; margin-top: 7px; margin-left: -9px;">
                  S
                </button>

                <!-- Email Survey Popup Modal -->
                <Dialog :maximizable="true" :closeOnEscape="true" v-model:visible="displayEmailSurvey"
                  :style="{ width: '45vw' }">
                  <template #header>
                    <h5>Send Email - Survay link</h5>
                  </template>
                  <SendEmailSurveyLink :survey_email="survey_email" :model="displayEmailSurvey"
                    @surveyEmailDisplay="dialogEmailSurvey($event)" />
                </Dialog>
                <!-- / Email Survey Popup Modal -->
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Contact Details <button type="button"
                        class="btn btn-outline-info mr-4 float-right btn-sm btn-circle" @click="addMorePetitioner()"><i
                          class="fa fa-plus" aria-hidden="true"></i></button> </h5>
                    <div v-for="(contact, index) in user_contact_details" :key="contact">
                      <!-- First Row -->
                      <div class="row mt-2 ml-1 mr-1">
                        <div class="col-md-5 p-1">
                          <!-- 1st -->
                          <input type="text" autocomplete="chrome-off" placeholder="Title" v-model="contact.contact_title"
                            class="form-control form-control-sm" style="font-size: 10px;" />
                        </div>

                        <div class="col-md-5 p-1">
                          <!-- 2nd -->
                          <input type="text" autocomplete="chrome-off" placeholder="Email Address"
                            v-model="contact.contact_email" class="form-control form-control-sm"
                            style="font-size: 10px;" />
                        </div>


                        <button type="button" v-tooltip.top="'Send Survey'" v-show="contact.contact_email"
                          v-on:click="surveyEmailDialog(contact.contact_email)"
                          class="btn btn-circle btn-sm btn-outline-primary "
                          style="height: 19px; width: 21px; margin-top: 7px; margin-left: 4px;">
                          S
                        </button>

                        <!-- Email Survey Popup Modal -->
                        <Dialog :maximizable="true" :closeOnEscape="true" v-model:visible="displayEmailSurvey"
                          :style="{ width: '45vw' }">
                          <template #header>
                            <h5>Send Email - Survay link</h5>
                          </template>
                          <SendEmailSurveyLink :survey_email="survey_email" :model="displayEmailSurvey"
                            @surveyEmailDisplay="dialogEmailSurvey($event)" />
                        </Dialog>
                        <!-- / Email Survey Popup Modal -->
                      </div>

                      <!-- Second Row -->
                      <div class="row ml-1 mr-1">
                        <div class="col-md-5 p-1">
                          <!-- 3rd -->
                          <input type="text" autocomplete="chrome-off" placeholder="Contact Name"
                            v-model="contact.contact_name" class="form-control form-control-sm"
                            style="font-size: 10px;" />
                        </div>

                        <div class="col-md-5 p-1">
                          <!-- 4th -->
                          <select v-show="contact.contact_phone_type == undefined" v-model="contact.contact_phone_type"
                            class="form-control form-control-sm" style="font-size: 10px;">
                            <option v-bind:value="contact.contact_phone_type">-- Phone Type --</option>
                            <option value="0">Mobile</option>
                            <option value="1">Landline</option>
                          </select>

                          <div v-show="contact.contact_phone_type == 0 || contact.contact_phone_type == 1">
                            <InputMask style="height: 25px; font-size: 10px;" autocomplete="chrome-off"
                              mask="(999) 999-9999"
                              :placeholder="contact.contact_phone_type == true ? 'Office number' : 'Mobile number'"
                              v-model="contact.contact_phone_number" class="form-control form-control-sm field_set" />
                          </div>
                        </div>

                        <div class="col-md-1 pl-0 pr-0"
                          style="padding-right: 0px; max-width: 2.33333%; margin-left: -20px; margin-top:-1px;">
                          <!-- M or P -->
                          <p v-show="contact.contact_phone_number" class="text-danger pt-3"
                            style="font-weight: 800; color: #a3a3a3; font-size: 10px;">
                            {{ contact.contact_phone_type == true ? 'L' : 'M' }}
                          </p>
                        </div>

                        <i v-tooltip.top="'Change phone type'" v-on:click="editPhType(contact)"
                          v-show="contact.contact_phone_type == 0 || contact.contact_phone_type == 1"
                          class="fa fa-edit editIcon" aria-hidden="true">
                        </i>

                        <div v-if="contact.contact_phone_type == 0">
                          <i v-tooltip.top="'Send Message'" v-show="contact.contact_phone_number"
                            v-on:click="smsDialog(contact.contact_phone_number)" class="fa fa-comment editIcon"
                            aria-hidden="true">
                          </i>
                        </div>


                        <!-- SMS Popup Modal -->
                        <Dialog :maximizable="true" :closeOnEscape="true" v-model:visible="display"
                          :style="{ width: '25vw' }">
                          <template #header>
                            <h5>Send SMS</h5>
                          </template>

                          <SendSms :sms_phone_number="sms_phone_number" :model="display" @Display="dialog($event)" />
                        </Dialog>
                        <!-- / SMS Popup Modal -->

                        <div v-if="contact.contact_phone_type == 0">
                          <button type="button" v-tooltip.top="'Send Survey'" v-show="contact.contact_phone_number"
                            v-on:click="surveySmsDialog(contact.contact_phone_number)"
                            class="btn btn-circle btn-sm btn-outline-primary ml-2"
                            style="height: 19px; width: 21px; margin-top: 6px; margin-left: 3px;">
                            S
                          </button>
                        </div>


                        <!-- Survey Popup Modal -->
                        <Dialog :maximizable="true" :closeOnEscape="true" v-model:visible="displaySmsSurvey"
                          :style="{ width: '45vw' }">
                          <template #header>
                            <h5>Send SMS - Survay link</h5>
                          </template>
                          <SendSmsSurveyLink :survey_sms_number="survey_sms_number" :model="displaySmsSurvey"
                            @surveyDisplay="dialogSmsSurvey($event)" />
                        </Dialog>
                        <!-- / Survey Popup Modal -->
                      </div>

                      <div class="row ml-4">
                        <div class="col-md-9">
                          <div class="d-flex justify-content-center">
                            <button type="button" @click="removeInputRow(user_contact_details, index, contact)"
                              class="btn mt-2 btn-outline-danger btn-sm btn-circle "><i class="fa fa-trash"
                                aria-hidden="true"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-2 mb-2">
              <div class="flex align-items-center">
                
                <label for="activeStatus" class=""><Checkbox v-model="isActive" inputId="activeStatus" :binary="true" /> Active </label>
              </div>
            </div>
            <button @click="scrollToTop()" class="btn btn-primary" :disabled="isDisabled">{{ btnTitle }}</button>
            <button v-on:click="showHistory()" class="btn btn-success float-right" type="button">Email History</button>

            <!-- History Popup Modal -->
            <Dialog :maximizable="true" :closeOnEscape="true" v-model:visible="showHistoryModal"
              :style="{ width: '45vw' }">
              <template #header>
                <h5>Email/SMS History</h5>
              </template>
              <EmailHistory :model="showHistoryModal" />
            </Dialog>
            <!-- / History Popup Modal -->

          </form>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <CustomerDetailsCode />
    </div>
    <Toast />
    <ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import axios from "axios";

import Dialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import Tooltip from 'primevue/tooltip';
import SendSms from '../partials/SendSms.vue';
import EmailHistory from '../partials/EmailHistory.vue';
import SendSmsSurveyLink from '../partials/SendSmsSurveyLink.vue';
import SendEmailSurveyLink from '../partials/SendEmailSurveyLink.vue';
import CustomerDetailsCode from '../partials/components/CustomerDetailsCode.vue';
import ProgressSpinner from 'primevue/progressspinner';
import Checkbox from 'primevue/checkbox';

// import Rating from 'primevue/rating';
import {
  required,
  maxLength,
  helpers,
} from "@vuelidate/validators";

export default {
  directives: {
    'tooltip': Tooltip
  },

  components: {
    InputMask,
    InputText,
    ConfirmDialog,
    ProgressSpinner,
    Toast,
    // Rating,
    EmailHistory,
    Dialog,
    SendSms,
    SendSmsSurveyLink,
    SendEmailSurveyLink,
    CustomerDetailsCode,
    Checkbox
  },

  data() {
    return {
      v$: useValidate(),
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      customer_id: null,
      isLead: null,
      createdDate: null,
      companyName: null,
      address: null,
      city: null,
      province: null,
      state: null,
      country: null,
      zipCode: null,
      postalCode: null,
      phoneNumber: null,
      email: null,
      loading: true,
      display: false,
      displaySmsSurvey: false,
      displayEmailSurvey: false,
      ingredient: '',
      sms_phone_number: null,
      survey_email: null,
      survey_sms_number: null,
      isInternationalCustomer: false,
      showHistoryModal: false,
      isDisabled: false,
      btnTitle: "Update",
      isActive: false,
      states: [],
      countries: [],
      user_contact_details: [

      ],

    };
  },


  validations() {
    return {
      companyName: {
        required,
        maxLength: maxLength(150),
      },
      address: {
        maxLength: maxLength(250),
      },
      city: {
        maxLength: maxLength(150),
      },
      zipCode: {
        maxLength: helpers.withMessage('Maximum 5', maxLength(5)),
        number: true,
      },
      phoneNumber: {
        number: true,
      },
      email: {
        maxLength: maxLength(150),
      },
    };
  },

  created() {

    this.getStates();
    this.getCountries();
    this.getCustomer();
  },

  methods: {
    editPhType: function (contact) {
      contact.contact_phone_type = null;
      contact.contact_phone_number = null;
    },

    showHistory: function () {
      this.showHistoryModal = true;
    },

    smsDialog: function (phone_number) {
      this.display = true;
      this.sms_phone_number = phone_number;
    },

    emailDialog: function (phone_number) {
      this.display = true;
      this.sms_phone_number = phone_number;
    },

    surveySmsDialog: function (phone_number) {
      this.displaySmsSurvey = true;
      this.survey_sms_number = phone_number;
    },

    surveyEmailDialog: function (email) {
      this.displayEmailSurvey = true;
      this.survey_email = email;
    },

    dialog(title) {
      this.display = title;
      this.$toast.add({ severity: 'success', summary: 'SMS Sent', detail: 'Message sent successfully.', life: 2000 });
    },

    dialogSmsSurvey(title) {
      this.displaySmsSurvey = title;
      this.$toast.add({ severity: 'success', summary: 'Survey Sent', detail: 'Survey sms link sent successfully.', life: 2000 });
    },

    dialogEmailSurvey(title) {
      this.displayEmailSurvey = title;
      this.$toast.add({ severity: 'success', summary: 'Survey Sent', detail: 'Survey email link sent successfully.', life: 2000 });
    },

    getCustomer: function () {
      var customerId = this.$route.params.id;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.env_api_url + "/customers/" + customerId, { headers })
        .then((response) => {
          console.log("Edit Customer Record: ", response.data.customer);

          this.loading = false;

          this.customer_id = response.data.customer.id;
          this.isLead = response.data.customer.lead_id;
          this.createdDate = response.data.customer.created_at;
          this.user_contact_details = response.data.customer.user_contact_details;
          this.companyName = response.data.customer.company_name;
          this.city = response.data.customer.city;
          this.state = response.data.customer.state;
          this.country = response.data.customer.country;
          this.address = response.data.customer.address;
          this.province = response.data.customer.province;
          this.zipCode = response.data.customer.zip;
          this.postalCode = response.data.customer.postal_code;
          this.phoneNumber = response.data.customer.phone;
          this.email = response.data.customer.email;
          this.notes = response.data.customer.notes;
          this.isInternationalCustomer = response.data.customer.is_international_customer ? true : false;
          this.isActive = response.data.customer.active ? true : false;
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
    },

    addMorePetitioner: function () {
      var new_contacts = {
      };
      this.user_contact_details.push(new_contacts);
    },
    removeInputRow: function (obj, index, contact) {
      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        accept: () => {
          obj.splice(index, 1);
          this.deleteContactDetail(contact.id);
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Record Safe', detail: 'Your record is safe!', life: 2000 });
        }
      });
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    getStates: function () {
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };

      axios.get(this.env_api_url + '/customers', { headers })
        .then((response) => {

          this.states = response.data.states;

        }).catch((error) => {
          console.warn('Your Error is :', error);
        })
      // Get all States of this Customer
    },

    getCountries: function () {
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };

      axios.get(this.env_api_url + '/customers', { headers })
        .then((response) => {

          this.countries = response.data.countries;

        }).catch((error) => {
          console.warn('Your Error is :', error);
        })
      // Get all States of this Customer
    },

    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors");
      } else {

        this.isDisabled = true;
        this.btnTitle = "Waiting...";

        var data = {
          user_id: this.$route.params.id,
          company_name: this.companyName,
          phone: this.phoneNumber,
          email: this.email,
          address: this.address,
          city: this.city,
          state: this.state,
          country: this.country,
          zip: this.zipCode,
          postal_code: this.postalCode,
          province: this.province,
          active: this.isActive ? true : false,
          is_international_customer: this.isInternationalCustomer,
          type: "Staff",
          // rating: this.rating,
          user_contact_details: this.user_contact_details,
        };
        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };

        axios
          .post(this.env_api_url + '/customers', data, {
            headers: headers,
          })
          .then((response) => {
            this.$notify({
              type: "success",
              title: "Success",
              text: "Saved Successfully!",
            });
            console.log("update customer basic data: ", response);

            this.getCustomer();

            this.isDisabled = false;
            this.btnTitle = "Update";

          })
          .catch((error) => {

            this.isDisabled = false;
            this.btnTitle = "Update";

            this.$notify({
              type: "error",
              title: "Something Went Wrong",
              text: "Error!",
            });
            console.warn("Your Error is :", error);
          });
      }
    },
    deleteContactDetail(id) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .delete(
          this.env_api_url + "/user_contact_details/" + id,
          {
            headers: headers,
          }
        )
        .then((response) => {
          this.$toast.add({ severity: 'success', summary: 'Deleted', detail: 'Record deleted successfully!', life: 2000 });
          console.log("Contact detail deleted successfully!: ", response);
          // this.getCustomerProducts();
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
    }
  },
};
</script>
<style scoped>
.editIcon {
  margin-top: 9px;
  margin-left: 13px;
  font-size: 15px;
}

.editIcon:hover {
  color: #2196F3;
}
</style>