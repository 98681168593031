<template>
    <div>
       <form @submit.prevent="formSubmit" class="user">
          <div class="form-group row">

            <div class="col-md-4 mb-5 mb-sm-0">
              <label class="pt-1" for="enableSMS">Enable SMS Service</label>
            </div>
            <div class="col-md-2 mb-5 mb-sm-0">
              <label class="switch float-left">
                <input type="checkbox" class="float-left" id="enableSMS" v-model="enableSMS" />
                <span class="slider round float-left"></span>
              </label>
            </div>
            
            <div class="col-md-12 mt-4 mb-sm-0" v-show="enableSMS">

              <span class="p-float-label">
                <InputText id="sms_app_token" class="col-md-10 form-control-sm" type="password" v-model="smsToken" />
                <label for="sms_app_token" class="required">SMS App Token</label>
              </span>
              <span class="text-danger" v-if="v$.smsToken.$error">
                <span
                  class="help-block"
                  v-for="validationError in v$.smsToken.$errors"
                  :key="validationError"
                >
                  {{ validationError.$message }}
                </span>
              </span>
              
              <div class="input-group" style="margin-top: 8px;">
                <input type="text" class="col-md-9 form-control form-control-sm" readonly v-model="apiKey_value">
                <div class="input-group-append">
                  <button class="btn btn-info btn-sm" type="button" @click="editField(apiKey_value)">Edit</button>
                </div>
              </div>
            </div>

          </div>
          <button class="btn btn-primary" :disabled="btnDisabled">{{btnTitle}}</button>
        </form>
    </div>
</template>
<script>

import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import InputText from 'primevue/inputtext';

export default {

  components: { 
    InputText
  },

  data() {
      return {
          v$: useValidate(),
          enableSMS: null,
          smsToken: null,
          show_smsToken: null,
          btnTitle: 'Update settings',
          btnDisabled: false,
          base_url: process.env.VUE_APP_SERVICE_URL,
      };
  },

  validations() {
      return {
      smsToken: {
          required
      }
      };
  },

  created() {
      this.getSMSDetails();
  },

  methods: {
      editField() {
        this.smsToken = this.show_smsToken;
      },

      getSMSDetails() {
        var twillioId = 5;
        var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };
        
        axios
        .get(this.base_url + "/settings/" + twillioId, { headers })
        .then((response) => {
            console.log("DATAAAAA: ", response.data);

            this.enableSMS = response.data.enable_sms;
            this.show_smsToken = response.data.sms_token;
            let total_x  = ''
            for (let index = 1; index <= response.data.sms_token.length; index++) {
              total_x = total_x + 'x';
            }
            this.apiKey_value = total_x;
        })
        .catch((error) => {
        console.warn("Your Error is :", error);
        });
      },
    
      formSubmit() {
          this.v$.$validate();
          if (this.v$.$error) {
              console.log("errors");
              //event.preventDefault();
          } else {

              this.btnDisabled = true
              this.btnTitle = 'Waiting...'


              var data = {
              setting_id: 5,
              sms_token: this.smsToken,
              enable_sms: this.enableSMS,
              };
              var headers = {
              Authorization:
                  `Bearer ` + localStorage.getItem("rezo_customers_user"),
              };

              axios.post(this.base_url + "/settings", data, { headers }).then(
              (response) => {
                  if (response.status === 200) {
                  this.$notify({
                      type: "success",
                      title: "Success",
                      text: "Saved Successfully!",
                  });

                  this.getSMSDetails();

                  this.btnDisabled = false
                  this.btnTitle = 'Update settings'

                  console.log("Form submitted successfully");
                  this.$router.push({ path: "/settings/twilio" });
                  }
                  console.log(response);
              },
              (error) => {
                  console.log(error.response.data.error);
                  this.$notify({
                  type: "error",
                  title: "Something went wrong!",
                  text: error.response.data.error,
                  });
                  this.btnDisabled = false
                  this.btnTitle = 'Update settings'
              }
              );
          }
      }
  },
}
</script>
