<template>
  <!-- Sidebar -->
        <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" v-show="!hideSidebar"  id="accordionSidebar">

            <!-- Sidebar - Brand -->
            <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
            <div class="sidebar-brand-text mx-3">Rezo Customers</div>
            </a>

            <!-- Divider -->
            <hr class="sidebar-divider my-0">

            <!-- Nav Item - Dashboard -->
            <li class="nav-item">
                
                <!-- <a class="nav-link" href="index.html">
                    <i class="fas fa-fw fa-tachometer-alt"></i>
                    <span>Dashboard</span></a> -->


                <router-link class="nav-link" to="/"><i class="fas fa-fw fa-tachometer-alt"></i>
                    <span>Dashboard</span></router-link>
            </li>

            <!-- Divider -->
            <hr class="sidebar-divider">

            <!-- Heading -->
            <div class="sidebar-heading">
                Main
            </div>

            <li class="nav-item">
                <div class="btn-group split_btn">
                    <router-link @click="resetFilters" class="collapse-item ml-3 link_css" to="/demo_modules"><i class="fas fa-fw fa-building ml-1 move_icon link_css"></i>
                    <span class="link_span_css">Rezosystems</span></router-link>
                    <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split ml-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <div class="dropdown-menu">
                        <h6 class="dropdown-header">Modules</h6>         
                        <router-link @click="resetFilters" class="dropdown-item" to="/demo_modules">Demo Modules</router-link>            
                        <router-link @click="resetFilters" class="dropdown-item" to="/business_modules">Business Modules</router-link>            
                        <router-link @click="resetFilters" class="dropdown-item" to="/servers">Servers</router-link>            
                    </div>
                </div>
            </li>

            <li class="nav-item">
                <div class="btn-group split_btn">
                    <router-link @click="resetFilters" class="collapse-item ml-3 link_css" to="/customers"><i class="fas fa-fw fa-users ml-1 move_icon link_css"></i>
                    <span class="link_span_css">Customers</span></router-link>
                    <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split ml-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <div class="dropdown-menu">
                        <h6 class="dropdown-header">Industries</h6>
                        <div v-for="product in products" :key="product">
                            <router-link class="dropdown-item" :to="'/customers?type=' + product.id">{{product.name}}</router-link>
                        </div>
                    </div>
                </div>
            </li>

            <li class="nav-item">
                <div class="btn-group split_btn">
                     <router-link @click="resetFilters" class="collapse-item ml-3 link_css" to="/leads"><i class="fas fa-fw fa-users ml-1 move_icon link_css"></i>
                     <span class="link_span_css">Leads</span></router-link>
                     <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split ml-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                       <span class="sr-only">Toggle Dropdown</span>
                     </button>
                        <div class="dropdown-menu">
                            <h6 class="dropdown-header">Industries</h6>
                            <div v-for="product in products" :key="product">
                                <router-link class="dropdown-item" :to="'/leads?type=' + product.id">{{product.name}}</router-link>
                            </div>
                        </div>
                </div>
            </li>

            <li class="nav-item">
                <div class="btn-group split_btn">
                    <router-link @click="resetFilters" class="collapse-item ml-3 link_css" to="/surveys"><i class="fas fa-clipboard-list ml-1 move_icon link_css"></i>
                    <span class="link_span_css">Surveys</span></router-link>
                    <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split ml-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <div class="dropdown-menu">
                        <h6 class="dropdown-header">Details</h6>         
                        <router-link class="dropdown-item" to="/survey/reports">Survey Reports</router-link>            
                        <!-- <router-link class="dropdown-item" to="#">Survey Settings</router-link>             -->
                        <router-link class="dropdown-item" to="/survey/submissions">Submissions</router-link>            
                    </div>
                </div>
            </li>

            <li class="nav-item">
                <div class="btn-group split_btn">
                    <router-link class="collapse-item ml-3 link_css" to="/sms_list"><i class="fa fa-comments ml-1 move_icon link_css" aria-hidden="true"></i>
                    <span class="link_span_css">SMS System</span></router-link>
                    <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split ml-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <div class="dropdown-menu">
                        <h6 class="dropdown-header">Industries</h6>
                        <div v-for="product in products" :key="product">
                            <router-link class="dropdown-item" :to="'/sms_list?type=' + product.id">{{product.name}}</router-link>
                        </div>
                    </div>
                </div>
            </li>

            <li class="nav-item">
                <div class="btn-group split_btn">
                    <router-link class="collapse-item ml-3 link_css" to="/follow_up_calendar"><i class="fas ml-1 move_icon link_css fa-fw fa-table"></i>
                    <span class="link_span_css">Calendar</span></router-link>
                </div>
            </li>

            <li class="nav-item active">
                <div class="btn-group split_btn">
                    <router-link class="collapse-item ml-3 link_css" to="/products"><i class="fa ml-1 move_icon link_css fa-th-large" aria-hidden="true"></i>
                    <span class="link_span_css">Products</span></router-link>
                </div>
            </li>

            <!-- Divider -->
            <hr class="sidebar-divider">
            <li class="nav-item">
                <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAdmins"
                    aria-expanded="true" aria-controls="collapseAdmins">
                    <i class="fa fa-fw fa-cogs"></i>
                    <span>Settings</span>
                </a>
                <div id="collapseAdmins" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div class="bg-white py-2 collapse-inner rounded">
                        <!-- <h6 class="collapse-header">Settings</h6> -->
                        <router-link class="collapse-item" to="/admins">Admins</router-link>
                        <router-link class="collapse-item" to="/settings/general">General</router-link>
                        <router-link class="collapse-item" to="/settings/site">Site Setting</router-link>
                        <router-link class="collapse-item" to="/settings/mail">Mail Setting</router-link>
                        <!-- <router-link class="collapse-item" to="/settings/gateway">Gateway</router-link> -->
                        <router-link class="collapse-item" to="/settings/twilio">SMS Setting</router-link>
                    </div>
                </div>
            </li>

            <!-- Nav Item - Utilities Collapse Menu -->
            <!-- <li class="nav-item">
                <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
                    aria-expanded="true" aria-controls="collapseUtilities">
                    <i class="fas fa-fw fa-wrench"></i>
                    <span>Utilities</span>
                </a>
                <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities"
                    data-parent="#accordionSidebar">
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Custom Utilities:</h6>
                        <a class="collapse-item" href="utilities-color.html">Colors</a>
                        <a class="collapse-item" href="utilities-border.html">Borders</a>
                        <a class="collapse-item" href="utilities-animation.html">Animations</a>
                        <a class="collapse-item" href="utilities-other.html">Other</a>
                    </div>
                </div>
            </li> -->


        

        </ul>
        <!-- End of Sidebar -->
</template>

<script>
import axios from "axios";
import { eventBus } from '../../../eventBus';

export default {
    props:["hideSidebar"],
    data() {
        return {
            style: false,
            
            env_api_url: process.env.VUE_APP_SERVICE_URL,          
            products: [], 
        };
    },

    created() {
        this.loadProducts();
    },

    methods: {
        loadProducts: function () {
            var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
            axios.get(this.env_api_url + '/product', {headers})
            .then(response => {
                this.products = response.data.products;
            })
        },
        resetFilters() {
            const newFilters = {}; // Set your desired filters here
            eventBus.emit('update-filters', newFilters);
        },
    },
}
</script>

<style>
    .sidebarDisabled {
        display: none;
    }
    .split_btn{
        margin-top: 18px;
        margin-bottom: 4px;
    }
    .move_icon{
        margin-right: 2px;
    }
    
    .link_css{
        color: rgba(255,255,255,.8);
        font-size: .85rem;
    }
    .link_span_css{
        color: rgba(255,255,255,.8);
        margin-left:3px;
        font-size: .85rem;
        display: inline;
    }
</style>