<template>
    <form @submit.prevent="sendMailForm">
        <div class="form-group row">
            <div class="col-md-12">
                <input type="text" v-model="email" placeholder="Email" ref="email" class="form-control form-control-sm" :class="{ 'is-invalid': v$.email.$error }" />
                <span class="text-danger" v-if="v$.email.$error">
                    <span class="help-block" v-for="validationError in v$.email.$errors" :key="validationError" >
                        {{ validationError.$message }}
                    </span>
                </span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-12 mb-3">
                <input type="text" v-model="subject" placeholder="Subject" class="form-control form-control-sm" :class="{ 'is-invalid': v$.subject.$error }"/>
                <span class="text-danger" v-if="v$.subject.$error">
                    <span class="help-block" v-for="validationError in v$.subject.$errors" :key="validationError" >
                        {{ validationError.$message }}
                    </span>
                </span>
            </div>

            <div class="col-md-12">
                <button class="btn btn-Success btn-sm btn-block" :disabled="btnDisabled">{{btnTitle}}</button>
            </div>
        </div>
    </form>
</template>

<script>
    import useValidate from "@vuelidate/core";
    import {required, email } from "@vuelidate/validators";
    import axios from "axios";
    export default {
        
        props:["surveyID", "base_url"],
        data() {
            return {
            v$: useValidate(),

            btnTitle: 'Send',
            email: null,
            subject: null,
            btnDisabled: false,
            env_api_url: process.env.VUE_APP_SERVICE_URL
            };
        },
        
        validations() {
            return {
                email: {
                    email,
                    required
                },
                subject: {
                    required
                }
            };
        },

        mounted(){
            this.$refs.email.focus();
        },

        methods: {
            hideDialog()
            {
                this.$emit('Display',false)
            },

            sendMailForm()
            {
                this.v$.$validate();
                if (this.v$.$error) {
                    console.log("errors");
                } else {

                    this.btnDisabled = true
                    this.btnTitle = 'Waiting...'

                    var data = {
                        email: this.email,
                        subject: this.subject,
                        survey_id: this.surveyID,
                        base_url: this.base_url
                    };
                    
                    var headers = {
                        Authorization:
                        `Bearer ` + localStorage.getItem("rezo_customers_user"),
                    };

                    axios
                    .post(process.env.VUE_APP_SERVICE_URL + "/survey/send_mail", data, { headers })
                    .then((response) => {
                        if (response.status === 200) {
                            console.log("Email send successfully");
                        }

                        this.btnDisabled = false
                        this.btnTitle = 'Send'
                        this.hideDialog();
                        this.$notify({
                            type: "success",
                            title: "Success",
                            text: "Email send Successfully!",
                        });
                    }).catch((error) => {

                        this.btnDisabled = false
                        this.btnTitle = 'Send'

                        this.$notify({
                            type: "error",
                            title: "Something Went Wrong",
                            text: "Error!",
                        });
                        
                        console.warn("Your Error is :", error);
                    });
                }
            }
        }
    }
</script>