<template>
  <page-header :title="'New Business Module'" />
  <div class="card col-md-6 mb-3">
    <div class="card-body" style="line-height: 4px;">
      <form @submit.prevent="formSubmit" class="user">
        <!--((( Row Start )))-->
        <div class="form-group row mt-3">
          <div class="col-sm-12">
            <!-- <label for="exampleCompanyName" class="required"> Model Name</label> -->
            <input autocomplete="chrome-off" v-model="moduleName" placeholder="Model Name" type="text" class="form-control form-control-sm"
              :class="{ 'is-invalid': v$.moduleName.$error }" id="examplecompanyName" ref="focus" />
            <span class="text-danger" v-if="v$.moduleName.$error">
              <span class="help-block mt-2" v-for="validationError in v$.moduleName.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span>
          </div>
          
        </div>

        <div class="form-group row mt-3">
          <div class="col-md-6">
            <!-- <label for="" class="required"> Servers</label> -->
            <div class="">
              <select v-model="server_id" @change="onSelect(server_id)" class="form-control form-control-sm"  :class="{ 'is-invalid': v$.server_id.$error }">
                <option value="">--Select Server--</option>
                <option :value="server.id" v-for="server in servers" :key="server">
                  {{ server.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <!-- <label for="">IP Address</label> -->
            <div class="">
              <input type="text" v-model="ip_address" readonly placeholder="IP address" class="form-control form-control-sm">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mb-3">
            <!-- <label for="" class="required"> Domain name</label> -->
            <input type="text" v-model="domainName" placeholder="Domain name (example.com)" autocomplete="chrome-off"
              :class="{ 'is-invalid': v$.domainName.$error }" class="form-control form-control-sm">
            <span class="text-danger" v-if="v$.domainName.$error">
              <span class="help-block mt-2" v-for="validationError in v$.domainName.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span>
          </div>

          <div class="col-md-12 mb-3">
            <!-- <label for="" class="required"> Repository link</label> -->
            <input type="text" autocomplete="chrome-off" placeholder="Repository link" v-model="moduleRepo"
              :class="{ 'is-invalid': v$.moduleRepo.$error }" class="form-control form-control-sm">
            <span class="text-danger" v-if="v$.moduleRepo.$error">
              <span class="help-block mt-2" v-for="validationError in v$.moduleRepo.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span>
          </div>

          <div class="col-md-12 mb-3">
            <!-- <label for="">Document Root</label> -->
            <input type="text" placeholder="Document Root" autocomplete="chrome-off" v-model="doc_root" class="form-control form-control-sm">
          </div>

          <div class="col-md-4 mb-1">
            <!-- <label for=""> DB Name</label> -->
            <input type="text" placeholder="DB Name" autocomplete="chrome-off" v-model="dbName" class="form-control form-control-sm">
            <!-- <span class="text-danger" v-if="v$.dbName.$error">
              <span class="help-block mt-2" v-for="validationError in v$.dbName.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span> -->
          </div>

          <div class="col-md-4 mb-1">
            <!-- <label for=""> DB User</label> -->
            <input type="text" placeholder="DB User" autocomplete="chrome-off" v-model="dbUser" class="form-control form-control-sm">
            <!-- <span class="text-danger" v-if="v$.dbUser.$error">
              <span class="help-block mt-2" v-for="validationError in v$.dbUser.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span> -->
          </div>

          <div class="col-md-4 mb-1">
            <!-- <label for=""> DB Password</label> -->
            <input v-bind:class="{ text_As_Pswrd: disc_text }" placeholder="DB Password" v-model="dbPassword"
              autocomplete="chrome-off" class="form-control form-control-sm">
            <i v-on:click="showPasword" id="eyeIcon" class="fa fa-eye zoom ml-1 mt-1" style="cursor: pointer;"
              aria-hidden="true"></i>
          </div>

          <div class="col-md-12 mb-3">
            <!-- <label for=""> Phpmyadmin</label> -->
            <input type="text" v-model="phpMyAdmin" placeholder="Phpmyadmin" autocomplete="chrome-off"
              class="form-control form-control-sm">
            <!-- <span class="text-danger" v-if="v$.phpMyAdmin.$error">
              <span class="help-block mt-2" v-for="validationError in v$.phpMyAdmin.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span> -->
          </div>
        </div>
        
        <div class="form-group row mt-1">
          <div class="col-sm-12">
            <!-- <label for="exampleCompanyName">Notes</label> -->
            <textarea
              placeholder="Note"
              v-model="notes"
              class="form-control"
              cols="20"
              rows="2"
            ></textarea>
          </div>
        </div>

        <button class="btn btn-primary btn-sm" :disabled="btnDisabled">{{ btnTitle }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import PageHeader from "../SharedComponents/PageHeader.vue";
import useValidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import axios from "axios";

export default {
  components: { PageHeader },
  data() {
    return {
      v$: useValidate(),
      servers: [],
      disc_text: true,
      server_id: "",
      moduleName: null,
      domainName: null,
      ip_address: null,
      notes: null,
      doc_root: null,
      moduleRepo: null,
      dbName: null,
      dbUser: null,
      dbPassword: null,
      phpMyAdmin: null,
      btnDisabled: false,
      btnTitle: "Create",
      type: 2,
      env_api_url: process.env.VUE_APP_SERVICE_URL
    };
  },
  validations() {
    return {
      server_id: {
        required
      },
      moduleName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(150),
      },
      domainName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      moduleRepo: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      // dbName: {
      //   required,
      //   minLength: minLength(3),
      //   maxLength: maxLength(25),
      // },
      // dbUser: {
      //   required,
      //   minLength: minLength(3),
      //   maxLength: maxLength(25),
      // },
      // dbPassword: {
      //   required,
      //   minLength: minLength(3),
      //   maxLength: maxLength(25),
      // },
      // phpMyAdmin: {
      //   required,
      //   minLength: minLength(3),
      //   maxLength: maxLength(100),
      // },
    };
  },
  mounted() {
    this.$refs.focus.focus();
  },
  created() {
    this.getServers();
  },
  methods: {
    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors", this.v$.$error);
      } else {

        this.btnDisabled = true;
        this.btnTitle = "Waiting...";

        var data = {
          name: this.moduleName,
          domain_name: this.domainName,
          repo: this.moduleRepo,
          db_name: this.dbName,
          db_user: this.dbUser,
          db_password: this.dbPassword,
          php_my_admin: this.phpMyAdmin,
          type: this.type,
          server_id: this.server_id,
          ip_address: this.ip_address,
          doc_root: this.doc_root,
          notes: this.notes
        };
        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };
        axios.post(this.env_api_url + "/rezo_modules", data, { headers }).then(
          (response) => {
            if (response.status === 200) {
              this.$notify({
                type: "success",
                title: "Success",
                text: "Saved Successfully!",
              });
              console.log("Form submitted successfully");
              this.$router.push({ path: "/business_modules" });
            }
            console.log(response);
          },
          (error) => {

            this.btnDisabled = false;
            this.btnTitle = "Create";

            console.log(error.response.data.error);
            this.$notify({
              type: "error",
              title: "Something went wrong!",
              text: error.response.data.error,
            });
          }
        );
      }
    },
    
    onSelect(serverID) {
      this.getServer(serverID);
    },

    getServer: function (id) {
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
      axios.get(process.env.VUE_APP_SERVICE_URL + '/servers/' + id, {headers})
      .then(response => {
        this.serverDetail = response.data;
        this.ip_address = this.serverDetail.server_ip;
      })
    },
    
    getServers: function () {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.env_api_url + "/servers", { headers })
        .then((response) => {
          this.servers = response.data;
        });
    },
    showPasword() {
      this.disc_text = !this.disc_text; //Disc text class will fail
    },
  },
};
</script>

<style scoped>
.text_As_Pswrd {
  -webkit-text-security: disc;
}

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  color: #dfc04e;
  transform: scale(1.5);
}

.spinner-border {
  height: 20px;
  width: 20px;
}
</style>
