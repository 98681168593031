<template>
    <page-header :title="'New Admin'" />
    <div class="card col-md-6 mb-3">
        <div class="card-body" style="line-height: 4px;">
            <form @submit.prevent="formSubmit" class="user">
                <!--((( Row Start )))-->
                <div class="form-group row mt-3">
                    <div class="col-sm-4 mb-3 mb-sm-0">
                        <label class="required" for=""> First Name</label>
                        <input
                            v-model="firstName"
                            type="text"
                            id="examplecompanyFirstName"
                            class="form-control form-control-sm"
                            :class="{ 'is-invalid': v$.firstName.$error }"
                        />
                        <span class="text-danger" v-if="v$.firstName.$error">
                            <span
                                class="help-block mt-2"
                                v-for="validationError in v$.firstName.$errors"
                                :key="validationError"
                            >
                                {{ validationError.$message }}
                            </span>
                        </span>
                    </div>
                    <div class="col-sm-4 mb-3 mb-sm-0">
                        <label class="required" for=""> Last Name</label>
                        <input
                            v-model="lastName"
                            type="text"
                            id="examplecompanyLastName"
                            class="form-control form-control-sm"
                            :class="{ 'is-invalid': v$.lastName.$error }"
                        />
                        <span class="text-danger" v-if="v$.lastName.$error">
                            <span
                                class="help-block mt-2"
                                v-for="validationError in v$.lastName.$errors"
                                :key="validationError"
                            >
                                {{ validationError.$message }}
                            </span>
                        </span>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="required" for="exampleMode"> Type</label>
                        <div class="">
                            <select
                                v-model="userType"
                                class="form-control form-control-sm"
                                :class="{ 'is-invalid': v$.userType.$error }"
                            >
                                <option value="">--Select Type--</option>
                                <option>Admin</option>
                                <option>Staff</option>
                                <option>Developer</option>
                            </select>
                            <span class="text-danger" v-if="v$.userType.$error">
                                <span
                                    class="help-block mt-2"
                                    v-for="validationError in v$.userType
                                        .$errors"
                                    :key="validationError"
                                >
                                    {{ validationError.$message }}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6 mb-3 mb-sm-0">
                        <label class="required" for=""> Email</label>
                        <input
                            v-model="email"
                            type="text"
                            id="examplecompanyEmail"
                            class="form-control form-control-sm"
                            :class="{ 'is-invalid': v$.email.$error }"
                        />
                        <span class="text-danger" v-if="v$.email.$error">
                            <span
                                class="help-block mt-2"
                                v-for="validationError in v$.email.$errors"
                                :key="validationError"
                            >
                                {{ validationError.$message }}
                            </span>
                        </span>
                    </div>
                    <div class="col-sm-6 mb-3 mb-sm-0">
                        <label class="required" for=""> Phone</label>
                        <InputMask
                            mask="(999) 999-9999"
                            v-model="phoneNumber"
                            id="examplePhone"
                            class="form-control form-control-sm"
                            :class="{ 'is-invalid': v$.phoneNumber.$error }"
                        />
                        <span class="text-danger" v-if="v$.phoneNumber.$error">
                            <span
                                class="help-block mt-2"
                                v-for="validationError in v$.phoneNumber
                                    .$errors"
                                :key="validationError"
                            >
                                {{ validationError.$message }}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label class="required" for=""> Password</label>
                        <input
                            v-model="password"
                            type="password"
                            id="examplePassword"
                            class="form-control form-control-sm"
                            :class="{ 'is-invalid': v$.password.$error }"
                        />
                        <span class="text-danger" v-if="v$.password.$error">
                            <span
                                class="help-block mt-2"
                                v-for="validationError in v$.password.$errors"
                                :key="validationError"
                            >
                                {{ validationError.$message }}
                            </span>
                        </span>
                    </div>

                    <div class="col-sm-6 mb-3 mb-sm-0">
                        <label class="required" for=""> Confirm Password</label>
                        <input
                            type="password"
                            v-model="comfirmPassword"
                            id="exampleComfirmPassword"
                            class="form-control form-control-sm"
                            :class="{'is-invalid': v$.comfirmPassword.$error || isPasswordMatch === false,'border-success': isPasswordMatch === true}"
                        />
                        <span
                            class="help-block mt-2"
                            style="overflow-wrap: break-word"
                            v-for="validationError in v$.comfirmPassword
                                .$errors"
                            :key="validationError"
                        >
                            <span
                                class="help-block"
                                v-for="validationError in v$.comfirmPassword
                                    .$errors"
                                :key="validationError"
                            >
                                {{ validationError.$message }}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="col-sm-4 form-inline mb-1">
                    <div class="form-group row">
                        <label for="checkboxCustomer"
                            >Email details to new Admin</label
                        >
                        <label class="switch">
                            <input
                                type="checkbox"
                                id="checkboxUser"
                                v-model="sendLoginDetail"
                            />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>

                <div v-for="error in errors" v-bind:key="error">
                  <div v-for="error_message in error" v-bind:key="error_message" class="alert alert-danger p-3 m-2">
                    {{ error_message }}
                  </div>
                </div>

                <button class="btn btn-primary" :disabled="btnDisabled">
                    {{ btnTitle }}
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import {
    required,
    minLength,
    maxLength,
    email,
    sameAs,
    helpers,
} from "@vuelidate/validators";
import InputMask from "primevue/inputmask";
import PageHeader from "../SharedComponents/PageHeader.vue";
import axios from "axios";

export default {
    components: { PageHeader, InputMask },
    data() {
        return {
            v$: useValidate(),
            env_api_url: process.env.VUE_APP_SERVICE_URL,
            firstName: null,
            lastName: null,
            userType: "",
            phoneNumber: null,
            email: null,
            password: null,
            comfirmPassword: null,
            sendLoginDetail: false,
            btnTitle: "Create",
            btnDisabled: null,
            passwordMatch: null,
            errors: [],
        };
    },

    validations() {
        return {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            phoneNumber: {
                required,
                number: true,
            },
            email: {
                required,
                email,
                minLength: minLength(3),
                maxLength: maxLength(150),
            },
            password: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(150),
            },
            comfirmPassword: {
                required,
                minLength: minLength(6),
                sameAs: helpers.withMessage(
                    "Password not match!",
                    sameAs(this.password)
                ),
                maxLength: maxLength(150),
            },
            userType: {
                required,
            },
        };
    },
    computed: {
    isPasswordMatch() {
        if (this.comfirmPassword === null || this.password === null) {
            return null;
        } else if (this.comfirmPassword === this.password) {
            return true;
        } else {
            return false;
        }
    }
    },
    methods: {
        formSubmit() {
            this.v$.$validate();
            if (this.v$.$error) {
                console.log("errors", this.v$.$error);
            } else {
                this.btnDisabled = true;
                this.btnTitle = "Waiting...";

                var data = {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    type: this.userType,
                    phone: this.phoneNumber,
                    email: this.email,
                    email_details: this.sendLoginDetail,
                    password: this.password,
                };

                var headers = {
                    Authorization:
                        `Bearer ` + localStorage.getItem("rezo_customers_user"),
                };
                axios
                    .post(this.env_api_url + "/admins", data, { headers })
                    .then(
                        (response) => {
                            if (response.status === 200) {
                                this.$notify({
                                    type: "success",
                                    title: "Success",
                                    text: "Save Successfully!",
                                });
                                console.log("Form submitted successfully");
                                this.$router.push({ path: "/admins" });
                            }
                            console.log(response);
                        },
                        (error) => {
                            this.btnDisabled = false;
                          this.btnTitle = "Create";
                          this.errors = error.response.data.errors;
                            console.log(error.response.data.errors);
                            

                            console.log(error.response.data.error);
                            this.$notify({
                                type: "error",
                                title: "Something went wrong!",
                                text: error.response.data.error,
                            });
                        }
                    );
            }
        },
    },
};
</script>
<style>
#examplePhone {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
.border-success {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25)!important;
}
</style>
