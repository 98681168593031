<template>
  <div class="row">
    <div class="col-md-8"><page-header :title="'Mail Settings'" /></div>
    <!-- <div class="col-md-3 float-right"><page-header :title="'Mail Management'" /></div> -->
  </div>
  <div class="row">
    <div class="card col-md-8">
      <div class="card-body">
        <MailSetting/> <!-- Mail setting form Component -->
      </div>
    </div>
    <div class="card col-md-3 ml-2">
      <div class="card-body">
        <SendMail/> <!-- Mail Send form Component -->
      </div>
    </div>  
  </div>
</template>

<script>
  import PageHeader from "../SharedComponents/PageHeader.vue";
  import MailSetting from './partials/MailSettingFrom.vue';
  import SendMail from './partials/SendMail.vue';

  export default {
    components: { 
      PageHeader, 
      MailSetting,
      SendMail 
    },
  };
</script>