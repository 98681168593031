<template>
  <div class="justify-content-center">
    <div class="card o-hidden border-0 shadow-lg my-5" style="border-radius: 0px;">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-lg-12">
            <div class="p-5">
              <p class="h4 pull-left text-primary mb-3"><strong>Survey: {{survey.name}}</strong></p>
              <form @submit.prevent="formSubmit" class="user">

                <!-- Customer Name -->
                <div class="form-group row" v-show="this.survey.customer_name == true">
                  <label for="customerName" class="col-sm-3 col-form-label"  :class="{ 'required': this.survey.is_mandatory == true }"><strong>Customer Name</strong> </label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control form-control-sm" :class="{ 'is-invalid': v$.customer_name.$error }" v-model="customer_name" style="margin-left: -36px;">
                    <span class="text-danger" v-if="v$.customer_name.$error">
                      <span
                        class="help-block"
                        v-for="validationError in v$.customer_name.$errors"
                        :key="validationError"
                      >
                        {{ validationError.$message }}
                      </span>
                    </span>
                  </div>
                </div>
                
                <!-- Questions Loop Start -->
                <div v-for="(question, index) in survey_questions" :key="question">
                  <div class="form-group row mt-3 question-card">
                    
                    <!-- Title -->
                    <label for="input"><strong>{{index+1}})</strong> {{question.title}}</label>
                    
                    <div class="col-md-12">  
                      <!-- - with Single Answer -->
                      <div class="col-md-12">
                        <div v-if="question.question_type =='checkbox'" class="d-flex flex-wrap">
                          <div v-for="item in question.options" :key="item" class="" style="padding-left: 0px;">
                            <input class="form-check-input ml-3" type="radio" v-model="questionAnswer[question.id]" :id="item.option" :value="item.option">
                            <label class="form-check-label" :for="item.option" style="margin-left: 31px;"> {{item.option}}</label>
                          </div>
                        </div>
                      </div>
                      
                      <!-- - with Multiple Answer -->
                      <div class="col-12 col-sm-6 col-md-8">
                        <div v-if="question.question_type =='checkbox_multiple'" class="row">
                          <div v-for="item in question.options" :key="item" class="col-md-6" style="padding-left: 50px;">
                            <input class="form-check-input" name="chkbox" type="checkbox" v-model="questionAnswer[item.option]" :id="item.id" :value="item.option">
                            <label class="form-check-label" :for="item.id" style="margin-right: 15px;"> {{item.option}}</label>
                          </div>
                        </div>
                      </div>
                      
                      
                      <!-- - with Scale type -->
                      <div class="col-md-12">
                        <div v-show="question.question_type =='scale'" style="position: relative; height: 80px; padding-top: 2px;" class=" form-check-inline">
                          <div v-for="(item, index) in question.options" :key="item">
                            <label  v-if="index == 0" class="mb-3 text-danger" style="position: absolute; left: 10px; top:0; ">{{item.option}}</label>
                              <label v-if="index > 0 && index < question.options.length - 1" class="btn btn-outline-primary btn-sm ml-2">
                                <input type="radio" v-model="questionAnswer[question.id]" :id="item.option" :value="item.option"> {{item.option}}
                              </label>
                            <label v-if="index == question.options.length - 1" class="mb-3 ml-2 text-success" style="position: absolute; right: 0; top:0; ">{{item.option}}</label>
                          </div>
                        </div>
                      </div>
                      
                      <!-- Question type Star -->
                      <Rating v-model="questionAnswer[question.id]" class="ml-3" :stars="5" :cancel="false" v-show="question.question_type =='stars'"/>

                      <!-- Question type Textbox -->
                      <textarea v-model="questionAnswer[question.id]" v-show="question.question_type =='textbox'" class="form-control form-control-sm ml-3 mr-4 " :rows="question.textbox_size == 'long'? '5':'1' && question.textbox_size == 'medium'? '2':'1'" :maxlength="question.textbox_size == 'medium'? '135':'45' && question.textbox_size == 'long'? '360':'45'"></textarea>
                      <p v-show="question.question_type =='textbox'" class="text-danger ml-4">Only {{question.textbox_size == 'medium'? '135':'45' && question.textbox_size == 'long'? '360':'45'}} Characters Allowed</p>

                      <div>
                        <input type="hidden" ref="data" name="question_id" :value="question.id">
                      </div>
                    </div>

                  </div>
                </div>
                <!-- Questions Loop End -->

                <div class="d-flex justify-content-center">
                  <button class="btn btn-success text-center" :class="{ 'btn-danger': v$.customer_name.$error }" :disabled="isDisabled" > {{btnTitle}} </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Rating from 'primevue/rating';
  
  import useValidate from "@vuelidate/core";
  import { required } from "@vuelidate/validators";

export default {
  data() {
    return {
      v$: useValidate(),
      base_url: process.env.VUE_APP_SERVICE_URL,
      isDisabled: false,
      btnTitle: "Submit",

      survey: [],
      survey_questions: [],
      customer_name:null,
      isMandatory: true,
      questionAnswer: {},
    };
  },

  components: { Rating },

  validations() {
    return {
      customer_name: {
        required
      }
    };
  },

  created()
  {
    if (this.$route.name == "TestSurvey")
    {
      this.isDisabled = true //Submission NOT Allowed
    }
    
    if (this.$route.name == "RezoCustomersFeedBack")
    {
      this.isDisabled = false //Submission Allow
    }
    
    this.getSurvey();
  },

  methods:
  {
    getSurvey: function ()
    {
      var surveyID = this.$route.params.id;
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
      
      axios.get(this.base_url + '/publicSurvey/' + surveyID, {headers})
      .then((response) => {
    
        this.survey = response.data.survey;
        this.survey_questions = response.data.survey_questions;
        this.isMandatory = response.data.survey.is_mandatory;

      }).catch((error) => {
        console.warn('Your Error is :', error);
      })
    },

    formSubmit()
    { 
      if (this.isMandatory == true)
      {  
        this.v$.$validate();
      }
      if (this.v$.$error) {
        console.log("errors");
      } else {

        this.isDisabled = true;
        this.btnTitle = "Waiting...";

          var data = {
            "customer_name": this.customer_name,
            "survey_id": this.$route.params.id,
            "ans": this.questionAnswer,
          };

          var headers = {
            Authorization:
              `Bearer ` + localStorage.getItem("rezo_customers_user"),
          };

          axios
          .post(this.base_url + "/survey_submission", data, { headers })
          .then((response) => {
            if (response.status === 200)
            {
              this.$notify({
                type: "success",
                title: "Success",
                text: "Save Successfully!",
              });

              this.isDisabled = false;
              this.btnTitle = "Submit";

              console.log("Survey created successfully");
              this.$router.push({ path: "/survey/completed" });
            }
          });
      }
    }
  }
};
</script>
<style scope>
  .question-card {
    padding: 10px;
    box-shadow: 3px 4px 11px 0px rgb(0 0 0 / 20%);
    border-radius: 7px;
  }

  body {
    font-size: 15px;
  }
</style>
