<template>
  <page-header :title="'Edit Lead | '" :module_title="leadTitle && leadTitle.company_name ? leadTitle.company_name : 'Page' "/>

  <ul class="nav nav-pills nav-fill mt-3 mb-3">
    
    <li class="nav-item">
      <a
        class="nav-link "
        :class="{'active': activeTab == 'basic'}" 
        @click="goToTab('basic')"
        href="javascript:void(0)"
        ><i class="fa fa-user"></i> Basic</a
      >
    </li>

    <!-- <li class="nav-item">
      <a class="nav-link"
        :class="{'active':activeTab == 'contactDetails'}"
        href="javascript:void(0)"
        @click="goToTab('contactDetails')"
        ><i class="fa fa-book"></i> Contact Details</a
      >
    </li> -->

    <li class="nav-item">
      <a class="nav-link"
        :class="{'active':activeTab == 'salesInfo'}"
        href="javascript:void(0)"
        @click="goToTab('salesInfo')"
        ><i class="fa fa-info-circle"></i> Sales info</a
      >
    </li>

    <!-- <li class="nav-item">
      <a class="nav-link" :class="{'active':activeTab == 'SaleInfoHistory'}" href="javascript:void(0)" @click="goToTab('SaleInfoHistory')" ><i class="fa fa-book"></i> History</a>
    </li> -->

    <li class="nav-item">
      <a class="nav-link" 
      :class="{'active': activeTab == 'notes'}" 
      href="javascript:void(0)"
       @click="goToTab('notes')"
        ><i class="fa fa-copy"></i> Notes</a
      >
    </li>

  </ul>

  <section v-if="activeTab == 'basic'">
    <lead-form />
  </section>

  <section v-if="activeTab == 'salesInfo'">
    <lead-salesInfo />
  </section>

  <section v-if="activeTab == 'contactDetails'">
    <lead-contactDetails />
  </section>

  <section v-if="activeTab == 'SaleInfoHistory'">
    <lead-saleHistory />
  </section>

  <section v-if="activeTab == 'notes'">
    <lead-notes />
  </section>

</template>

<script>
import PageHeader from "../SharedComponents/PageHeader.vue";
import LeadForm from "./partials/LeadForm.vue";
import LeadSalesInfo from './partials/LeadSalesInfo.vue';
import LeadContactDetails from './partials/LeadContactDetails.vue';
import LeadSaleHistory from './partials/LeadSaleHistory.vue';
import LeadNotes from './partials/LeadNotes.vue';
import axios from "axios";
import { useRoute } from 'vue-router'

export default {
  setup(){

  },

  components: { 
    PageHeader, 
    LeadForm,
    LeadSalesInfo,
    LeadContactDetails,
    LeadSaleHistory,
    LeadNotes
  },
  
  data() {
    const route = useRoute()
    console.log(route.query)
    return {
      leadTitle: null,
      base_url: process.env.VUE_APP_SERVICE_URL,
      activeTab: route.query.activeTab==undefined?'basic':route.query.activeTab,
    };
  },
  created() {
    this.getLeadTitle();
  },
  methods: {
    goToTab(param) {
      //router.push({ path: '', query: { plan: 'private' }})
      this.$router.replace({ query: { activeTab: param } });
      this.activeTab = param;
    },
    getLeadTitle: function () {
      var leadId = this.$route.params.id;
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };

        axios.get(this.base_url + '/leads/'+leadId, {headers})
        .then((response) => {
          this.leadTitle = response.data.lead_title;
          
        }).catch((error) => {
            console.warn('Your Error is :', error);
        })
    },
  },

};
</script>
