<template>
  <h1 class="h6 text-secondary"><strong>Demo Modules</strong></h1>
  <div>
    <div v-if="loading" class="spinner-border text-primary spninner-border-sm"></div>
    <ul v-else>
      <li v-if="demo_modules.length == 0">No Item</li>
      <li v-else v-for="item in demo_modules" :key="item.id">
      <router-link class="link" target='_blank' :to="{name:'EditDemoModule', params: { id:item.id}}" >{{ item.name }}</router-link>
      </li>
    </ul>
  </div>
  <h1 class="h6 text-secondary"><strong>Business Modules</strong></h1>
  <div>
    <div v-if="loading" class="spinner-border text-primary spninner-border-sm"></div>
    <ul v-else>
      <li v-if="business_modules.length == 0">No Item</li>
      <li v-else v-for="item in business_modules" :key="item.id">
      <router-link class="link" target='_blank' :to="{name:'EditBusinessModule', params: { id:item.id}}" > {{ item.name }}</router-link>
      </li>
    </ul>
  </div>
  <h1 class="h6 text-secondary"><strong>Customers</strong></h1>
  <div>
    <div v-if="loading" class="spinner-border text-primary spninner-border-sm"></div>
    <ul v-else>
      <li v-if="customers.length == 0">No Item</li>
      <li v-else v-for="item in customers" :key="item.id">
        <router-link class="link" target='_blank' :to="{name:'EditCustomer', params: { id:item.id}}">{{ item.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ShowSites",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      loading:true,
      demo_modules: [],
      business_modules: [],
      customers: [],
    };
  },
  methods: {
    getSites() {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.env_api_url + "/servers/show_sites/" + this.id, {
          headers,
          params: this.filters,
        })
        .then((response) => {
          this.demo_modules = response.data.demo_modules;
          this.business_modules = response.data.business_modules;
          this.customers = response.data.customers;
          this.loading = false;
        });
    },
  },
  created() {
    this.getSites();
  },
};
</script>

<style scoped>
.link{
    color:blue;
    cursor: pointer;
}
.link:hover{
  color:#337ab7;
  cursor: pointer;
}
.spninner-border-sm{
    font-size: 9px;
    width:24px;
    height:24px;
}
</style>