<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Sales Information</h4>
          <p class="card-text"></p>
          <div class="row">
            <div class="col-sm-12">
              <div class="card mt-2 mt-4" v-if="leadSalesInfo.id>0">
                <div class="card-body">
                  <p class="card-text">
                    Follow Up Date: {{ moment(leadSalesInfo.follow_up_date).format("MM/DD/YYYY") }}
                    <span class="float-right">
                      <button
                        class="btn btn-circle btn-danger btn-sm mr-1"
                        @click="deleteSalesInfo(leadSalesInfo)"
                      >
                        <i class="fas fa-trash"></i></button
                    ></span>
                  </p>
                  <p
                    class="card-text"
                    v-if="leadSalesInfo.follow_up_action == 1"
                  >
                    Follow Up Action: Call
                  </p>
                  <p
                    class="card-text"
                    v-else-if="leadSalesInfo.follow_up_action == 2"
                  >
                    Follow Up Action: Email
                  </p>
                  <p class="card-text" v-else>Follow Up Action:</p>
                  <p class="card-text">Status: {{ leadSalesInfoStatus }}</p>
                </div>
              </div>
            </div>
          </div>
          <Toast /><ConfirmDialog></ConfirmDialog>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <form @submit.prevent="formSubmit" class="user">
          <div class="card-body">
            <div class="row">
              <div class="col-md-10">
                <h4 class="card-title">{{ salesInfoFormTitle }}</h4>
              </div>
              <div class="col-md-2">
                <button
                  v-show="newSalesInfo.follow_up_date"
                  type="button"
                  @click="resetForm()"
                  class="btn float-right btn-warning btn-circle btn-sm"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-12">
                <label for="">Follow Up Date</label>
                <input
                  type="date"
                  v-model="newSalesInfo.follow_up_date"
                  placeholder="Domain name"
                  class="form-control form-controls"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-12">
                <label for="">Follow Up action</label>
                <select
                  v-model="newSalesInfo.follow_up_action"
                  class="form-control"
                  name=""
                  id=""
                >
                  <option selected :value="undefined">--Select--</option>
                  <option value="1">Call</option>
                  <option value="2">Email</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-12">
                <label for="">Status</label>
                <!-- <select
                    v-model="newSalesInfo.sales_info_status_id"
                    class="form-control"
                    name=""
                    id=""
                  >
                    <option selected :value="undefined">--Select--</option>
                    <option value="1">A</option>
                    <option value="2">B</option>
                  </select> -->

                <select
                  v-model="newSalesInfo.sales_info_status_id"
                  class="form-control form-control"
                >
                  <option selected :value="undefined">--Select--</option>
                  <option
                    :value="saleStatus.id"
                    v-for="saleStatus in salesStatuses"
                    :key="saleStatus"
                  >
                    {{ saleStatus.status }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <button
                  :disabled="!newSalesInfo.follow_up_date"
                  class="btn btn-primary btn-user btn-block"
                >
                  {{btnTitle}}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import moment from "moment";
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
export default {
computed: mapState(["user"]),  
  components: {ConfirmDialog, Toast},
  data() {
    return {
      salesStatuses: {},
      newSalesInfo: {},
      base_url: process.env.VUE_APP_SERVICE_URL,
      salesInfoFormTitle: "Add Sales information",
      leadSalesInfo: [],
      leadSalesInfoStatus: null,
      btnTitle: 'Create',
      moment: moment,
      
    };
  },
  created() {
    this.getSalesStatuses();
    this.getSalesInfo();
  },
  methods: {

    getSalesStatuses: function () {
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
      axios.get(this.base_url + '/sales_information_statuses', {headers})
      .then(response => {
        this.salesStatuses = response.data;
      })
    },
    getSalesInfo: function () {
      var lead_id = this.$route.params.id;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      // Get all sales information
      axios
        .get(this.base_url + "/salesInformation/" + lead_id, { headers })
        .then((response) => {
          console.log(
            "All sales information of lead --> ",
            response.data.sales_information
          );
          this.leadSalesInfo = response.data.sales_information;
          this.leadSalesInfoStatus = response.data.sales_information_status;
          if (response.data.sales_information == null) {
            this.resetForm();
          } else {
            this.salesInfoFormTitle = "Edit Sales information";
            this.editSaleInfo(response.data.sales_information);
          }
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
    },

    formSubmit() {

      console.log("save sales info data to database.");
      this.btnTitle = 'Waiting...';
      var data = {
        id: this.newSalesInfo.id,
        lead_id: this.$route.params.id,
        added_by: this.user.id,
        follow_up_date: this.newSalesInfo.follow_up_date,
        follow_up_action: this.newSalesInfo.follow_up_action,
        sales_info_status_id: this.newSalesInfo.sales_info_status_id,
      };

      // Sales info store
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .post(this.base_url + "/salesInformation", data, { headers })
        .then((response) => {
          console.log("sales information store successfully!", response);
          this.$notify({
            type: "success",
            title: "Success",
            text: "Saved Successfully!",
          });
          this.btnTitle = "Waiting..."
          this.getSalesInfo();
        }).catch((error) => {

          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          
          console.warn("Your Error is :", error);
        });
    },

    editSaleInfo(sales_information) {
      this.newSalesInfo = sales_information;
      this.customerProductFormTitle = "Edit Product";
      this.btnTitle = "Update";
    },
    deleteSalesInfo(leadSalesInfo) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios
            .delete(
              this.base_url + "/salesInformation/" + leadSalesInfo.id,
              {
                headers: headers,
              }
            )
            .then((response) => {
              this.btnTitle = "Create";
              this.getSalesInfo();
              this.$toast.add({severity:'success', summary: 'Deleted', detail:'Record deleted successfully!', life: 2000});
              console.log("Delete sale info basic data: ", response);
              this.resetForm();
            })
            .catch((error) => {
              console.warn("Your Error is :", error);
            });
        },
        reject: () => {
            this.$toast.add({severity:'error', summary: 'Record Safe', detail:'Your record is safe!', life: 2000});
        }
      });
    },

    resetForm() {
      this.salesInfoFormTitle = "Add Sales information";
      this.newSalesInfo = {};
    },
  },
};
</script>

<style>
</style>