<template>
  <!-- DataTales Example -->
  <div class="card shadow mr-5">
    <div class="card-header" style="padding:0px;">
      <div class="row mt-3">
        <div class="col-md-6">
          <table-header
            :title="'Survey Report'"
          />
        </div>
      </div>
    </div>
    <ProgressSpinner v-if="loading" style="width:50px;height:50px" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"/>
    <div class="card-body" v-else>
      <DataTable :value="surveys" :paginator="true" style="padding:4px;" :rows="10" selectionMode="single"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5,10,20,50]" responsiveLayout="scroll"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
        <Column style="padding:4px;" field="id" header="S. No" :sortable="true"></Column>
        <Column style="padding:4px;" field="name" header="Survey Name" :sortable="true"></Column>
        
        <Column style="padding:4px;" field="email_survey" header="Email Survey">
          <template #body="slotDate">
            {{ slotDate.data.email_survey? 'Yes':'No' }}
          </template>
        </Column>

        <Column style="padding:4px;" field="status" header="Status">
          <template #body="slotDate">
            {{ slotDate.data.status? 'Yes':'No' }}
          </template>
        </Column>

        <Column style="padding:4px;" field="description" header="Description" :sortable="true"></Column>


        <Column style="padding:4px;" header="Actions">
            <template #body="slotProps">
              <!-- <router-link :to="{name:'ShowReport', params: { id:slotProps.data.id}}" >Show Report</router-link >               -->
              <router-link class="btn btn-info btn-sm" :to="{name:'ShowReport', params: { id:slotProps.data.id}}" >Show Report</router-link >              
            </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import Tooltip from 'primevue/tooltip';
import TableHeader from "../SharedComponents/TableHeader.vue";
import axios from "axios";
import moment from "moment";

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressSpinner from 'primevue/progressspinner';

export default {
  directives: {
    'tooltip': Tooltip
  },
  
  components: {
    TableHeader, 
    DataTable, 
    Column, 
    ProgressSpinner 
  },

  data() {
    return {
      filters:{
        
      },
      loading: true,
      surveys: [],
      moment: moment,
      display: false,
      SurveyID: null,
      totalRows: 0,
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      base_url:process.env.VUE_APP_SERVICE_URL
    };
  },
  
  methods: {

    getSurveyReports: function () {
      // this.filters.type = this.$route.query.type?this.$route.query.type:"";
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.base_url+'/reports', { 
          headers,
          // params: this.filters,
        })
        .then((response) => {

          this.surveys = response.data.surveys;
          this.totalRows = 0;
          // this.customers_url = 0;
          this.loading = false;
        });
    }
  },
  created() {
    this.getSurveyReports();
  },
};
</script>
