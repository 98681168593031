<template>
  <!-- DataTales Example -->
  <div class="card shadow mr-5">
  <div class="card-header" style="padding: 0px;">
  <div class="row mt-3">
    <div class="col-md-6">
          <table-header
          :title="'Servers'"
          :hasLink="true"
          :linkTo="'/servers/create'"
          :linkText="'New Server'"
         />
    </div>
    <div class="col-md-6">
          <!-- <h6 class="m-0 font-weight-bold text-primary">Records <small>({{totalRows}})</small></h6> -->
          <form class="form-inline float-right mr-3">
            <div class="form-group">
              <input
                v-model="filters.search_server"
                class="form-control form-control-sm"
                placeholder="Name"
                ref="search"
              />
            </div>
            <div class="form-group ml-1">
              <button
                type="button"
                class="btn btn-danger btn-sm"
                @click="this.filters = {}"
              >
                Reset
              </button>
            </div>
          </form>
          <DownloadButton class="float-right ml-1 mr-1" :url="this.env_api_url + '/export/servers/'" fileName="Rezo_Servers.xlsx"/>
        </div>
        </div>
  </div>
  <ProgressSpinner v-if="loading" style="width:50px;height:50px" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"/>
        <div class="card-body" v-else>
          <DataTable
            selectionMode="single"
            :value="servers"
            :paginator="true"
            style="padding:4px;"
            :rows="10"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 20, 50]"
            responsiveLayout="scroll"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          >
            <Column style="padding:4px;" field="name" header="Server Name" :sortable="true"></Column>
            <Column style="padding:4px;" field="dedicated" header="Dedicated" >
              <template #body="slotProps">
                <span v-if="slotProps.data.dedicated" class="bg-success p-1 px-2 text-white rounded"><i class="fas fa-check "></i></span>
                <span v-else class="bg-danger text-white rounded p-1 px-2"><i class="fas fa-times "></i></span>
              </template>
            </Column>
            <Column style="padding:4px;" field="server_ip" header="Server IP" :sortable="true"></Column>
            <Column style="padding:4px;" field="no_of_sites" header="# of sites" :sortable="true">
              <template #body="slotProps">
                <a href="#" @click.prevent="showSites(slotProps.data.id)">{{
                  slotProps.data.no_of_sites
                }}</a>
              </template>
            </Column>
            <Column style="padding:4px;" field="created_at" header="Created At" :sortable="true">
              <template #body="slotDate">
                {{ moment(slotDate.data.created_at).format("MM/DD/YYYY") }}
              </template>
            </Column>
            <Column style="padding:4px;" header="Actions">
              <template #body="slotProps">
                <router-link 
                class="btn btn-circle btn-primary  btn-sm" 
                :to="{ name: 'EditServer', params: { id: slotProps.data.id } }">
                <i class="fas fa-edit"></i>
                </router-link>

                <button
                  v-on:click="deleteServer(slotProps.data.id)"
                  class="btn btn-circle btn-danger btn-sm ml-1"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </template>
            </Column>
          </DataTable>
        </div>
        <Toast /><ConfirmDialog></ConfirmDialog>
      </div>
  <Sidebar v-model:visible="isSitesShow" position="right" class="p-sidebar-md">
    <ShowSites :id="isSitesShow" />
  </Sidebar>
</template>

<script>
import TableHeader from "../SharedComponents/TableHeader.vue";
import axios from "axios";
import moment from "moment";
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import DownloadButton from "../SharedComponents/DownloadButton.vue";
import Sidebar from "primevue/sidebar";
import ShowSites from "./ShowSites";
import ProgressSpinner from 'primevue/progressspinner';
import { eventBus } from "../../eventBus";

export default {
  components: { TableHeader, DataTable, Column, DownloadButton, Sidebar, ShowSites, ProgressSpinner, ConfirmDialog, Toast },
  data() {
    return {
      filters: {},
      totalRows: 0,
      loading: true,
      moment: moment,
      servers: [],
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      isSitesShow: false,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getServers();
            this.loading=true;
            this.awaitingSearch = false;
          }, 700); // 1 sec delay
        }
        this.awaitingSearch = true;
      },
    },
  },

  methods: {
    getServers: function () {
      this.filters.type = this.$route.query.type ? this.$route.query.type : "";

      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.env_api_url + "/servers", { headers, params: this.filters })
        .then((response) => {
          this.servers = response.data;
          this.totalRows = response.data.length;
          this.$refs.search.focus();
          this.loading = false;
        });
    },
    editServer: function (server) {
      this.server = server;
    },
    deleteServer: function (serverId) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios
            .delete(this.env_api_url + "/servers/" + serverId, {
              headers: headers,
            })
            .then((response) => {
              this.getServers();
              this.$toast.add({severity:'success', summary: 'Deleted', detail:'Record deleted successfully!', life: 2000});
              console.log("Delete getServers basic data: ", response);
            })
            .catch((error) => {
              console.warn("Your Error is :", error);
            });
        },
        reject: () => {
            this.$toast.add({severity:'error', summary: 'Record Safe', detail:'Your record is safe!', life: 2000});
        }
      });
    },
    showSites(id){
      this.isSitesShow = id;
    }
  },
  mounted(){
    this.$refs.search.focus();
  },
  created() {
    this.getServers();
    eventBus.on('update-filters', (newFilters) => {
      this.filters = newFilters;
    });
  },
};
</script>
