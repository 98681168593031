<template>
  <page-header :title="'SMS Settings'" />
  <div class="row">
    <div class="card col-md-6 mb-3">
      <div class="card-body">
        <TwilioForm/>
      </div>
    </div>
    <!-- Mail Send -->
    <div class="card col-md-5 ml-2 mb-3" v-show="true">
      <div class="card-body">
        <SendSms/>
      </div>
    </div>
  <!-- /Mail Send -->
  </div>
</template>

<script>
  import PageHeader from "../SharedComponents/PageHeader.vue";
  import SendSms from './partials/SendSms.vue';
  import TwilioForm from './partials/TwilioForm.vue';
  import axios from "axios";
  export default {
    components: { 
      PageHeader, 
      SendSms, 
      TwilioForm
    },

    data() {
      return {
        enableSMS: false,
        base_url: process.env.VUE_APP_SERVICE_URL,
      };
    },

    created() {
      var twillioId = 5;
      var headers = {
      Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      
      axios
      .get(this.base_url + "/settings/" + twillioId, { headers })
      .then((response) => {
        console.log("Twilio Settings data: ", response.data.enable_sms);
        this.enableSMS = response.data.enable_sms;
      })
      .catch((error) => {
      console.warn("Your Error is :", error);
      });
    },
  };
</script>