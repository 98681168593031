<template>

  <div v-if="warningZone" >
    <Message v-for="msg of messages" :severity="msg.severity" :key="msg.content">{{msg.content}}</Message>
  </div>

  <component :is="layout"> </component>
  <notifications />
</template>

<script>
import { useRoute } from "vue-router";
import { computed } from "vue";

import Login from "./views/Auth/Login.vue";
import AdminLayout from "./views/layouts/admin/AdminLayout.vue";
import SurveyLayout from "./views/layouts/survey/SurveyLayout.vue";

import Message from 'primevue/message';

export default {
  
  components: { Login, AdminLayout, SurveyLayout, Message },
  data() {
    return {
      path: null,

      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
      warningTimer: null,
      logoutTimer: null,
      warningZone: false,
      messages: [
        {severity: 'warn', content: 'Are you still with us?'}
      ]
    };
  },

  mounted()
  {  
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer);
    }, this);

    this.setTimers();
  },

  unmounted(){
    this.events.forEach(function (event) {
      window.removeEventListener(event, this.resetTimer);
    }, this);

    this.resetTimer();
  },

  methods: {
    setTimers: function()
    {
      this.warningTimer = setTimeout(this.warningMessage, 120 * 60 * 1000); // 120 minutes
      this.logoutTimer = setTimeout(this.logoutUser, 125 * 60 * 1000); // 125 minutes
    
      this.warningZone = false;
    },

    warningMessage: function()
    {
      // alert("Waiting...!");
      this.warningZone = true;
    },

    logoutUser: function()
    {
      // alert("Logout me!");
      this.warningZone = false;
      localStorage.removeItem("rezo_customers_user");
      this.$router.push({ name: "Login"})
    },

    resetTimer: function()
    {
      clearTimeout(this.warningTimer)
      clearTimeout(this.logoutTimer)
      this.setTimers();
    }
  },

  created() {
    console.log("VUE_APP_TITLE",process.env.VUE_APP_TITLE)
    this.$store.dispatch("getAuthUser");
    const route = useRoute();
    this.path = computed(() => route.path);
  },
  computed: {
    layout() {
      console.log(this.$route.meta.layout);
      // return this.$route.meta.layout ? Login : SurveyLayout;

      if (this.$route.name == "TestSurvey" || this.$route.name == "RezoCustomersFeedBack" || this.$route.name == "ThankYou") {
        return SurveyLayout;
      }
      
      if (this.$route.fullPath === '/login') {
        return Login;
      } 
      else {
        return AdminLayout;
      }

    },
  },
};
</script>



