<template>
  <page-header :title="'New Customer'" />
  <div class="card col-md-6 mb-3">
    <div class="card-body" style="line-height: 4px;">
      <form @submit.prevent="formSubmit" class="user">
        <div class="form-group row mt-3">
          <div class="col-md-6 mb-sm-0">
            <label class="required" for="exampleCompanyName"> Company Name</label>
            <input v-model="companyName" type="text" autocomplete="chrome-off" class="form-control form-control-sm"
              :class="{ 'is-invalid': v$.companyName.$error }" id="exampleCompanyName" ref="focus" />
            <small class="text-danger" v-if="v$.companyName.$error">
              <span class="help-block mt-2" v-for="validationError in v$.companyName.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </small>
          </div>
          <div class="col-sm-6 mt-2">
            <label class="switch" style="padding-top: 22px;">
              <input type="checkbox" id="checkboxActive" v-model="isInternationalCustomer" />
              <span class="slider round"></span>
            </label>
            <label class="mr-1" for="checkboxActive" style="margin-top: -22px; margin-left: 52px;">International
              Customer</label>
            <!-- <Rating v-model="rating" class="ml-3" :stars="5" :cancel="false"/> -->
            <!-- <strong>Created by:</strong> <small class="pull-right badge" :class="isLead?'badge-danger':'badge-success'">{{isLead?'Lead':'Customer'}}</small> -->
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12 mb-3 mb-sm-0">
            <label for="exampleaddress"> Address</label>
            <input v-model="address" autocomplete="chrome-off" type="text" class="form-control form-control-sm"
              :class="{ 'is-invalid': v$.address.$error }" id="exampleaddress" />
          </div>

        </div>
        <div class="form-group row">
          <div class="col-sm-4 mb-3 mb-sm-0">
            <label for="exampleInputcity">City</label>
            <input v-model="city" type="text" autocomplete="chrome-off" class="form-control form-control-sm"
              :class="{ 'is-invalid': v$.city.$error }" id="exampleInputcity" />
            <span class="info-block text-danger" v-if="v$.city.$error">
              <span class="help-block" v-for="validationError in v$.city.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span>
          </div>
          <div v-if="!isInternationalCustomer" class="col-sm-5">
            <label for="selectStates"> State</label>
            <select v-model="state" class="form-control form-control-sm" id="selectStates">
              <option value="">--Select--</option>
              <!-- <option selected value="" disabled>Select</option> -->
              <option v-for="state in states" :key="state">{{ state }}</option>
            </select>
          </div>
          <div v-if="isInternationalCustomer" class="col-sm-5">

            <label for="province"> Province</label>
            <input v-model="province" autocomplete="chrome-off" type="text" class="form-control form-control-sm"
              id="province" />

          </div>

          <div v-if="isInternationalCustomer" class="col-sm-3">

            <label for="postalCode">Postal Code</label>
            <input v-model="postalCode" type="text" class="form-control form-control-sm mb-2" id="postalCode"
              autocomplete="chrome-off" />

          </div>
          <div v-if="!isInternationalCustomer" class="col-sm-3">

            <label for="examplezipcode"> Zip Code</label>
            <input v-model="zipCode" type="number" class="form-control form-control-sm mb-2"
              :class="{ 'is-invalid': v$.zipCode.$error }" id="examplezipcode" autocomplete="chrome-off" />
            <span class="info-block text-danger" v-if="v$.zipCode.$error">
              <span class="help-block" v-for="validationError in v$.zipCode.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span>

          </div>

          <div class="col-md-4">
            <div v-if="isInternationalCustomer">
              <label for="selectStates"> Country</label>
              <select v-model="country" class="form-control form-control-sm" id="selectStates">
                <option value="">--Select--</option>
                <!-- <option selected value="" disabled>Select</option> -->
                <option v-for="country in countries" :key="country">{{ country.name }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-4">
            <label for="examplephone"> Phone</label>
            <InputText v-if="isInternationalCustomer" v-model="phoneNumber" type="number"
              class="form-control form-control-sm" :class="{ 'is-invalid': v$.phoneNumber.$error }" id="examplephone"
              autocomplete="chrome-off" />
            <InputMask v-else v-model="phoneNumber" mask="(999) 999-9999" type="text" class="form-control form-control-sm"
              :class="{ 'is-invalid': v$.phoneNumber.$error }" id="examplephone" autocomplete="chrome-off" />
            <span class="info-block text-danger" v-if="v$.phoneNumber.$error">
              <span class="help-block" v-for="validationError in v$.phoneNumber.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span>
          </div>
          <div class="col-sm-7">
            <label class="required" for="exampleInputEmail"> Email</label>
            <input v-model="email" type="email" class="form-control form-control-sm"
              :class="{ 'is-invalid': v$.email.$error }" id="exampleInputEmail" autocomplete="chrome-off" />
            <small class="info-block text-danger" v-if="v$.email.$error">
              <span class="help-block mt-2" v-for="validationError in v$.email.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </small>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Contact Details <button type="button"
                    class="btn btn-outline-info mr-4 float-right btn-sm btn-circle" @click="addMorePetitioner()"><i
                      class="fa fa-plus" aria-hidden="true"></i></button> </h5>
                <div v-for="(contact, index) in user_contact_details" :key="contact">
                  <!-- First Row -->
                  <div class="row mt-2 ml-1 mr-1">
                    <div class="col-md-5 p-1">
                      <!-- 1st -->
                      <input type="text" autocomplete="chrome-off" placeholder="Title" v-model="contact.contact_title"
                        class="form-control form-control-sm" style="font-size: 10px;" />
                    </div>

                    <div class="col-md-5 p-1">
                      <!-- 2nd -->
                      <input type="text" autocomplete="chrome-off" placeholder="Email Address"
                        v-model="contact.contact_email" class="form-control form-control-sm" style="font-size: 10px;" />
                    </div>

                    <div class="col-md-1" style="padding-right: 0px; max-width: 2.33333%;"
                      v-show="contact.contact_phone_number">
                      <!-- M,L -->
                    </div>
                  </div>

                  <!-- Second Row -->
                  <div class="row ml-1 mr-1">
                    <div class="col-md-5 p-1">
                      <!-- 3rd -->
                      <input type="text" autocomplete="chrome-off" placeholder="Contact Name"
                        v-model="contact.contact_name" class="form-control form-control-sm" style="font-size: 10px;" />
                    </div>

                    <div class="col-md-5 p-1">
                      <!-- 4th -->
                      <select v-show="contact.contact_phone_type == undefined" v-model="contact.contact_phone_type"
                        class="form-control form-control-sm" style="font-size: 10px;">
                        <option selected :value="undefined">-- Phone Type --</option>
                        <option value="0">Mobile</option>
                        <option value="1">Landline</option>
                      </select>

                      <div v-show="contact.contact_phone_type == 0 || contact.contact_phone_type == 1">
                        <InputMask style="height: 25px; font-size: 10px;" autocomplete="chrome-off" mask="(999) 999-9999"
                          :placeholder="contact.contact_phone_type == true ? 'Office number' : 'Mobile number'"
                          v-model="contact.contact_phone_number" class="form-control form-control-sm field_set" />
                      </div>
                    </div>

                    <div class="col-md-1 pl-0 pr-0"
                      style="padding-right: 0px; max-width: 2.33333%; margin-left: -20px; margin-top:-1px;">
                      <!-- M or P -->
                      <p class="text-danger pt-3" style="font-weight: 800; color: #a3a3a3; font-size: 10px;"
                        v-show="contact.contact_phone_number">
                        {{ contact.contact_phone_type == true ? 'L' : 'M' }}
                      </p>
                    </div>

                    <i v-tooltip.top="'Change phone type'" v-on:click="editPhType(contact)"
                      v-show="contact.contact_phone_type == 0 || contact.contact_phone_type == 1"
                      class="fa fa-edit editIcon ml-3" aria-hidden="true" style="margin-top: 9px; 
                        margin-left: 3px; 
                        font-size: 15px;">
                    </i>
                  </div>

                  <div class="row ml-4">
                    <div class="col-md-9">
                      <div class="d-flex justify-content-center">
                        <button type="button" @click="removeInputRow(user_contact_details, index)"
                          class="btn mt-2 btn-outline-danger btn-sm btn-circle "><i class="fa fa-trash"
                            aria-hidden="true"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn-primary mt-3" :disabled="btnDisabled">{{ btnTitle }}</button>
      </form>

      <Toast />
      <ConfirmDialog></ConfirmDialog>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import { required, maxLength, helpers } from "@vuelidate/validators";
import PageHeader from "../SharedComponents/PageHeader.vue";
import axios from "axios";
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import Tooltip from 'primevue/tooltip';

// import Rating from 'primevue/rating';
export default {
  directives: {
    'tooltip': Tooltip
  },

  components: {
    PageHeader,
    InputMask,
    InputText,
    ConfirmDialog,
    Toast,
    // Rating 
  },

  data() {
    return {
      v$: useValidate(),
      states: [],
      countries: [],
      companyName: null,
      address: null,
      city: null,
      province: null,
      state: "",
      country: "",
      zipCode: null,
      postalCode: null,
      phoneNumber: null,
      email: null,
      isInternationalCustomer: false,
      // rating: 0,
      btnDisabled: null,
      btnTitle: "Create",
      base_url: process.env.VUE_APP_SERVICE_URL,

      user_contact_details: [
        {

        }
      ],

    };
  },

  validations() {
    return {
      companyName: {
        required,
        maxLength: maxLength(150),
      },
      address: {
        maxLength: maxLength(150),
      },
      city: {
        maxLength: maxLength(150),
      },
      zipCode: {
        maxLength: helpers.withMessage('Maximum 5', maxLength(5)),
        number: true,
      },
      phoneNumber: {
        number: true,
      },
      email: {
        required,
        maxLength: maxLength(150),
      },
    };
  },
  mounted() {
    this.$refs.focus.focus();
  },
  created() {
    this.getStates();
    this.getCountries();
  },

  methods: {
    editPhType: function (contact) {
      contact.contact_phone_type = undefined;
      contact.contact_phone_number = undefined;
    },

    addMorePetitioner: function () {
      var new_contacts = {
      };
      this.user_contact_details.push(new_contacts);
    },
    removeInputRow: function (obj, index) {
      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        accept: () => {
          obj.splice(index, 1);
          // this.getCustomers();
          this.$toast.add({ severity: 'success', summary: 'Deleted', detail: 'Record deleted successfully!', life: 2000 });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Record Safe', detail: 'Your record is safe!', life: 2000 });
        }
      });
    },

    getStates: function () {
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };

      axios.get(this.base_url + '/customers', { headers })
        .then((response) => {
          this.states = response.data.states;

        }).catch((error) => {
          console.warn('Your Error is :', error);
        })
      // Get all States of this Customer
    },

    getCountries: function () {
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };

      axios.get(this.base_url + '/customers', { headers })
        .then((response) => {
          console.warn(response.data.countries)
          this.countries = response.data.countries;

        }).catch((error) => {
          console.warn('Your Error is :', error);
        })
      // Get all States of this Customer
    },

    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors");
        //event.preventDefault();
      } else {

        this.btnDisabled = true;
        this.btnTitle = "Waiting...";

        var data = {
          company_name: this.companyName,

          phone: this.phoneNumber,
          email: this.email,
          address: this.address,
          city: this.city,
          state: this.state,
          country: this.country,
          zip: this.zipCode,
          postal_code: this.postalCode,
          province: this.province,
          is_international_customer: this.isInternationalCustomer,
          type: "Staff",
          active: 1,
          // rating: this.rating,
          //Contact Details
          user_contact_details: this.user_contact_details
        };
        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };

        axios.post(this.base_url + "/customers", data, { headers }).then(
          (response) => {
            if (response.status === 200) {
              this.$notify({
                type: "success",
                title: "Success",
                text: "Saved Successfully!",
              });
              console.log("Form submitted successfully");
              this.$router.push({ path: "/customers" });
            }
            console.log(response);
          },
          (error) => {

            this.btnDisabled = false;
            this.btnTitle = "Create";

            console.log(error.response.data.error);
            this.$notify({
              type: "error",
              title: "Something went wrong!",
              text: error.response.data.error,
            });
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.editIcon:hover {
  color: #2196F3;
}
</style>