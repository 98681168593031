<template>
  <!-- Page Heading -->

  <table-header
    :title="'Admins'"
    :hasLink="true"
    :linkTo="'/admins/create'"
    :linkText="'New Admin'"
  />
  
  <!-- DataTales Example -->
  <div class="card shadow mb-4">
    <div class="card-header py-3 d-flex justify-content-between align-items-center">
      <h6 class="m-0 font-weight-bold text-primary">Records <small>({{totalRows}})</small></h6>
          <DownloadButton :url="env_api_url + '/export/admins'" fileName="admins.xlsx" />
    </div>
    <div class="card-body">
      <!-- <form class="form-inline">
        <div class="form-group">
          <input
            v-model="filters.name"
            class="form-control"
            placeholder="Name"
          />
        </div>

        <div class="form-group ml-1">
          <input
            v-model="filters.email"
            class="form-control"
            placeholder="Email"
          />
        </div>


        <div class="form-group ml-1">
          <button type="button" class="btn btn-danger" @click="this.filters={}">Reset</button>
        </div>
      </form> -->
      <div class="table-responsive">
        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0" >
          <thead>
            <tr>
              <th>Admin</th>
              <th>Email</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          
          <tbody>
            <tr v-for="admin in admins" :key="admin">
              <td>{{admin.first_name}} {{admin.last_name}}</td>
              <td>{{admin.email}}</td>
              <td>{{admin.type}}</td>
              
              <td>
                <router-link v-if="admin.type==='Admin'" :to="{name:'EditAdmin', params: { id:admin.id}}" >
                  <i class="btn btn-circle btn-primary fas fa-edit btn-sm"></i>
                </router-link >
                <button v-on:click="deleteAdmin(admin.id)" class="btn btn-circle btn-danger  btn-sm ml-1">
                  <i
                  class="fas fa-trash"
                  
                ></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <Toast />
  <ConfirmDialog></ConfirmDialog>
</template>

<script>
import TableHeader from "../SharedComponents/TableHeader.vue";
import axios from "axios";
import DownloadButton from '../SharedComponents/DownloadButton.vue'
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';

export default {
  components: { TableHeader,DownloadButton,ConfirmDialog,Toast },
  data() {
    return {
      filters:{
        
      },
      totalRows: 0,
      admins: [],
      env_api_url: process.env.VUE_APP_SERVICE_URL,
    };
  },
  watch: {
      filters: {
        deep: true,
        handler () {
          if (!this.awaitingSearch) {
            setTimeout(() => {
              this.getAdmins()
              this.awaitingSearch = false;
            }, 700); // 1 sec delay
          }
          this.awaitingSearch = true;
        }
      },
    },
  methods: {
    getAdmins: function ()
    {  
      this.filters.type = this.$route.query.type?this.$route.query.type:"";
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      
      axios
        .get(this.env_api_url + "/admins", { headers })
        .then((response) => {
          console.log("ADMINS ",response);
          this.totalRows = response.data.length;
          this.admins = response.data;
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
    },

    deleteAdmin: function (adminId) {
       
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        accept: () => {
          axios
          .delete(this.env_api_url + "/admins/" + adminId, {
            headers: headers,
          })
          .then((response) => {
            this.getAdmins();
            this.$toast.add({severity:'success', summary: 'Deleted', detail:'Record deleted successfully!', life: 2000});
            console.log("Deleted Admin: ", response);
          })
          .catch((error) => {
            console.warn("Your Error is :", error);
          });
        },
        reject: () => {
          this.$toast.add({severity:'error', summary: 'Record Safe', detail:'Your record is safe!', life: 2000});
        }
      });
    },
  },
  created() {
    this.getAdmins();
  },
};
</script>
<style scoped>
  .zoom {
    transition: transform .2s;
  }
  .zoom:hover {
    transform: scale(1.5);
  }
</style>
