<template>
  <div class="head">
    <img :src="'/img/logo.png'" class="float-left" width="100" style="padding-top: 38px" />
  </div>
  <div class="divStyle" >
    <router-view :key="$route.fullPath" />
    <div class="text-center text-light pt-5">
      Copyrights Ⓒ Rezosystems Customer Portal {{ new Date().getFullYear() }} all rights reserved
    </div>
  </div>
</template>

<script>
  export default {
    methods:{
      
    }
  };
</script>

<style scoped>
  .divStyle {
    background: black;
    padding-top: 122px;
    padding-bottom: 89px;
  }
  .head {
    margin-left: 50px;
  }
</style>