<template>
    <div class="col-md-12">
        <div class="card">
            <div class="d-flex flex-column justify-content-center" v-if="loading" style="height:230px">
            <ProgressSpinner style="width:50px;height:50px; margin-top:1px;" strokeWidth="1" fill="var(--surface-ground)"
                animationDuration=".5s" />
            </div>
            <div class="card-body" v-else>
                <div class="row">
                    <div class="col-md-6">
                        <h4 class="card-title">Customer Details</h4>
                    </div>
                    <div class="col-md-6 mt-2 text-primary"><small>Created at: {{
                            moment(createdDate).format("MM/DD/YYYY")
                    }}</small></div>
                </div>
                <p class="card-text"></p>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <p class="card-text">Company Name: <small class="text-primary"><b>{{ companyName
                                }}</b></small></p>
                                <p class="card-text" v-for="customerServer in customerServers" :key="customerServer">
                                    Server: <small class="text-primary"><b>{{ customerServer.server.name }}</b></small>
                                </p>
                                <!-- <p class="card-text">Phone #: <small class="text-primary"><b>{{ phoneNumber ?
                                        phoneNumber
                                        : 'N/A'
                                }}</b></small></p> -->
                                <p class="card-text">Created by: <strong class="pull-right badge"
                                        :class="isLead ? 'badge-danger' : 'badge-success'">
                                        {{ isLead ? 'Lead' : 'Customer' }}</strong></p>
                                <div v-for="customerServer in customerServers" :key="customerServer">
                                    <p class="card-text" v-if="(customerServers.length) == 1"><a
                                            v-bind:href="customerServer.domain_name" target="_blank" type="button"
                                            class="btn btn-info btn-sm">Open URL</a></p>
                                </div>
                                <Button type="button" v-if="(customerServers.length) > 1" class="btn btn-info btn-sm"
                                    label="Toggle" @click="toggle">Open URLS</Button>
                                <OverlayPanel ref="op">
                                    <table class="table">
                                        <tbody>
                                            <tr v-for="customerServer in customerServers" :key="customerServer">
                                                <th>{{ customerServer.domain_name }}</th>
                                                <td>
                                                    <p class="card-text"><a v-bind:href="customerServer.domain_name"
                                                            target="_blank" type="button"
                                                            class="btn btn-info btn-sm">Open</a></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </OverlayPanel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import OverlayPanel from 'primevue/overlaypanel';
import ProgressSpinner from 'primevue/progressspinner';
export default {
    components: {
        OverlayPanel,
        ProgressSpinner,
    },

    data() {
        return {
            env_api_url: process.env.VUE_APP_SERVICE_URL,
            moment: moment,
            customerServers: [],
            loading: true,
        }
    },

    created() {
        this.getCustomerServers();
    },

    methods: {
        toggle(event) {
            this.$refs.op.toggle(event);
        },

        getCustomerServers: function () {
            var customerId = this.$route.params.id;
            var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };

            // Get all Servers of this Customer
            axios.get(this.env_api_url + '/customers/' + customerId, { headers })
                .then((response) => {
                    console.log("Edit Customer all Servers --> ", response);
                    this.customerServers = response.data.customer_servers;
                    this.loading = false;

                }).catch((error) => {
                    console.warn('Your Error is :', error);
                })
            // Get all Servers of this Customer
        },

    },
}
</script>