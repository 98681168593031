<template>
  <!-- Page Heading -->
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-1">
    <h1 class="h4 mb-0 text-gray-800">Dashboard</h1>
    <!-- <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                                class="fas fa-download fa-sm text-white-50"></i> Generate Report</a> -->
  </div>

  <!-- Content Row -->
  <div class="row" style="line-height: 11px;">
    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-2 col-md-6 mb-4">
      <router-link class="link" target= '_blank' to="/customers?type=2">
      <div class="card counter-card border-left-primary shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-right">
            <div class="col mr-2">
              <div
                class="
                  text-xs
                  font-weight-bold
                  text-primary text-uppercase
                  mb-1
                "
              >
                Bike Rental Clients
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                {{ bikeCount }}
              </div>
            </div>
            <div class="col-auto">
              <i class="fas fa-bicycle fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
      </router-link>
    </div>

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-2 col-md-6 mb-4">
      <router-link class="link" target= '_blank' to="/customers?type=1">
      <div class="card counter-card border-left-success shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-right">
            <div class="col mr-2">
              <div
                class="
                  text-xs
                  font-weight-bold
                  text-success text-uppercase
                  mb-1
                "
              >
                GMS Clients
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                {{ gmsCount }}
              </div>
            </div>
            <div class="col-auto">
              <i class="fas fa-mountain fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
      </router-link>
    </div>

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-2 col-md-6 mb-4">
      <router-link class="link" target= '_blank' to="/customers?type=3">
      <div class="card counter-card border-left-info shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-right">
            <div class="col mr-2">
              <div
                class="text-xs font-weight-bold text-info text-uppercase mb-1"
              >
                Ski Rental Clients
              </div>
              <div class="row no-gutters align-items-center">
                <div class="col-auto">
                  <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                    {{ skiCount }}
                  </div>
                </div>
                <div class="col">
                  <div>
                    <div 
                      style="width: 50%"
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <i class="fas fa-skiing-nordic fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
      </router-link>
    </div>

    <!-- Pending Requests Card Example -->
    <div class="col-xl-2 col-md-6 mb-4">
      <router-link class="link" target= '_blank' to="/customers?type=4">
      <div class="card counter-card border-left-secondary shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-right">
            <div class="col mr-2">
              <div
                class="
                  text-xs
                  font-weight-bold
                  text-secondary text-uppercase
                  mb-1
                "
              >
                Raft Clients
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                {{ raftCount }}
              </div>
            </div>
            <div class="col-auto">
              <i class="fas fa-ship fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
      </router-link>
    </div>

    <div class="col-xl-2 col-md-6 mb-4">
      <router-link class="link" target= '_blank' to="/customers?type=6">
      <div class="card counter-card border-left-warning shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-right">
            <div class="col mr-2">
              <div
                class="
                  text-xs
                  font-weight-bold
                  text-warning text-uppercase
                  mb-1
                "
              >
                Ski Tune Clients
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                {{ skituneCount }}
              </div>
            </div>
            <div class="col-auto">
              <i class="fa fa-cogs fa-2x text-gray-300" aria-hidden="true"></i>

            </div>
          </div>
        </div>
      </div>
      </router-link>
    </div>

    <!-- Pending Requests Card Example -->
    <div class="col-xl-2 col-md-6 mb-4">
      <router-link class="link" target= '_blank' to="/customers?type=5">
      <div class="card counter-card border-left-primary shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-right">
            <div class="col mr-2">
              <div
                class="
                  text-xs
                  font-weight-bold
                  text-primary text-uppercase
                  mb-1
                "
              >
                Others Clients
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                {{ othersCount }}
              </div>
            </div>
            <div class="col-auto">
              <i class="fas fa-users fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
      </router-link>
    </div>
    
  </div>
  <Toast /><ConfirmDialog></ConfirmDialog>

  <!-- TODO LISTS SECTION-->
 
  <div class="d-sm-flex align-items-center justify-content-between mb-1">
    <h1 class="h4 mb-0 text-gray-800">Todo List</h1>
  </div>

  <div class="row">
    <div class="col-xl-4 col-md-12">
      <!-- Todo list (Today) -->
      <TodayList />
      <template v-if="isBillingReminderListShown">
        <!-- Billing Reminders list -->
        <BillingReminderList />
      </template>
    </div>
    
    <!-- Todo list (Weekly) -->
    <div class="col-xl-4 col-md-12">
      <WeekList />
    </div>

    <!-- Todo list (Monthly) -->
    <div class="col-xl-4 col-md-12">
      <MonthlyList />
    </div>

  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";

import { mapState } from "vuex";
import axios from "axios";
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import TodayList from './Todos/TodayList.vue';
import WeekList from './Todos/WeekList.vue';
import MonthlyList from './Todos/MonthlyList.vue';
import BillingReminderList from './Todos/BillingReminderList.vue';

export default {
  name: "Dashboard",

  components: {
    TodayList, WeekList, MonthlyList, BillingReminderList, ConfirmDialog, Toast
  },


  data() {
    return {
      bikeCount: 0,
      skiCount: 0,
      gmsCount: 0,
      raftCount: 0,
      skituneCount:0,
      othersCount:0,
      v$: useValidate(),
      title: null,
      display_order: null,
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      isBillingReminderListShown:false,
    };
  },

  validations() {
    return {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(150),
      },
      display_order: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(150),
      },
    };
  },

  computed: mapState(["products", "productsInBag","user"]),
  created() {
    this.getProductsCount();
    this.getMeetingCount();
  },
  methods: {
    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors");
      } else {
        var data = {
          task_title: this.title,
          display_order: this.display_order,
        };
        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };
        this.$notify({
          type: "success",
          title: "Success",
          text: "Saved Successfully!",
        });
        axios
          .post(process.env.VUE_APP_SERVICE_URL + "/todo", data, { headers })
          .then((response) => {
            if (response.status === 200) {
              console.log("Form submitted successfully");
              this.$router.push({ path: "/todo" });
            }
            console.log(response);
          });
      }
    },
    getProductsCount() {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.env_api_url + "/customer_products", { headers })
        .then((response) => {
          this.bikeCount = response.data.bikeCount;
          this.skiCount = response.data.skiCount;
          this.gmsCount = response.data.gmsCount;
          this.raftCount = response.data.raftCount;
          this.skituneCount = response.data.skituneCount;
          this.othersCount = response.data.othersCount;
        })
        .catch((error) => {
          this.$router.push('/login');
          console.log("Please login!",error);
        });
    },
  getMeetingCount() {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.env_api_url + "/salesInformation", { headers })
        .then((response) => {
          this.todayMeetingCount = response.data.today_meeting_count;
        })
        .catch((error) => {
          this.$router.push('/');
          console.log("Please login!",error);
          
        });
    },
  },
  watch: {
    user: {
      immediate:true,
      handler(user){
        if(user.id != 0 && user.id == 1)
          this.isBillingReminderListShown = true;
      }
    }
  },
};
</script>
<style>
#task_list {
  list-style: none;
}
.counter-card:hover{
  transform: scale(1.1);
}
.link:hover{
  text-decoration: none;
}
.h5:hover{
  color: blue;
}
</style>