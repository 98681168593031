<template>
    <button class="btn btn-primary btn-sm" @click="download">
        <span v-if="loading">
          <span class="spinner-border spinner-border-sm"></span> Loading
        </span>
        <span v-else>{{ text }}</span>
    </button>
</template>

<script>
import axios from "axios";
export default {
    props: {
        text: {
            type: String,
            default: "Export",
        },
        url: {
            type: String,
            required: true,
        },
        fileName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        download() {
            this.loading = true;
            axios
                .get(this.url, {
                    responseType: "blob",
                    headers: {
                        Authorization:
                            `Bearer ` +
                            localStorage.getItem("rezo_customers_user"),
                    },
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${this.fileName}`);
                    document.body.appendChild(link);
                    link.click();
                    this.loading = false;
                })
                .catch(() => {
                    this.$notify({
                        type: "error",
                        title: "Error",
                        text: "Something Went Wrong!",
                    });
                    this.loading = false;
                });
        },
    },
};
</script>

<style>
</style>