<template>
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary">Records <small>({{totalRows}})</small></h6>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0" >
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Date Added</th>
              <th>Event Date</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="salesInfo in salesInfoHistory" :key="salesInfo">
              <template v-for="lead in leadsInfo" :key="lead">
              <td v-if="salesInfo.lead_id==lead.id">
              {{ lead.company_name }}
              </td>
              </template>
              <td>{{ moment(salesInfo.created_at).format("MM/DD/YYYY") }}</td>
              <td>{{ moment(salesInfo.follow_up_date).format("MM/DD/YYYY") }}</td>
              <td v-if="salesInfo.follow_up_action == 1">Call</td>
              <td v-else-if="salesInfo.follow_up_action == 2">Email</td>
              <td v-else>None</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from 'moment';
export default {
  
  data() {
    return {
      salesInfoHistory: [],
      leadsInfo: [],
      moment: moment,
      totalRows: 0,
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      history_url:process.env.VUE_APP_SERVICE_URL + '/sales_info_history'
    };
  },

  methods: {
    getHistory: function ()
    {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.history_url, { headers })
        .then((response) => {
          console.log("Sales info History ",response.data.saleInfoHistory);
          this.salesInfoHistory = response.data.saleInfoHistory;
          this.leadsInfo = response.data.leadsInfo;
          this.totalRows = response.data.saleInfoHistory.length;
        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });
    },
  },
  created() {
    this.getHistory();
  },
};
</script>