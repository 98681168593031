<template>
 <div class="card shadow mr-5">
    <div class="card-body">
      <div class="row">
        <div class="col-md-9"><h5 class="m-2">Follow up calendar</h5></div>
        <div class="col-md-3">
          <div class="form-group row">
            <strong class="mt-2">Added by:</strong>
            <div class="col-sm-8 mt-2">
                <select class="form-control form-control-sm" name="role" id="role"  v-model="filters.userID">
                    <option selected :value="null">--All--</option>
                    <option v-for="admin in admins" :key="admin" :value="admin.id">
                    {{admin.first_name}} {{admin.last_name}}
                    </option>
                </select>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <full-calendar :options="calendarOptions">
        <template #eventContent="arg">
          <v-tooltip bottom color="teal">
              <div class="eventLayout">

                <strong class="leadLink" v-on:click="show(arg.event.extendedProps.leadID)">{{arg.event.extendedProps.company_name}}</strong> <br>
                {{arg.event.extendedProps.phone}}<br />
                <p style="font-size: 15px;">
                  <button v-if="arg.event.extendedProps.action == 'Call'" class="btn btn-info btn-sm mt-2">{{arg.event.extendedProps.action}}</button>
                  <button v-else class="btn btn-danger btn-sm mt-2"> {{arg.event.extendedProps.action}}</button>
                </p>

              </div>
          </v-tooltip>
        </template>
      </full-calendar>
      
      <Dialog :maximizable="true" :closeOnEscape="true" header="Event Details" v-model:visible="modalShow" >
        <strong>Lead:</strong> {{companyName}} <br>
        <strong>Phone:</strong> {{phoneNumber}} <br>
        <strong>Action:</strong> {{action}} <br>
      </Dialog>

    </div>
  </div>
</template>

<script>

  import axios from "axios";
  import { mapState } from "vuex";
  import '@fullcalendar/core/vdom' // solves problem with Vite
  import FullCalendar from '@fullcalendar/vue3'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid';
  import interactionPlugin from '@fullcalendar/interaction'
  import Dialog from 'primevue/dialog';

  export default {
    computed: mapState(["user"]), 
    components: { FullCalendar, Dialog },
    data() {
      return {
        
        calendarOptions: {
          plugins:[dayGridPlugin,timeGridPlugin, interactionPlugin],
          initialView: 'dayGridMonth',
          allDaySlot:false,
          height:800,
        
          defaultView: 'agendaWeek',           
          // slotMinTime: "09:00:00",
          // slotMaxTime: "15:00:00",
          headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          },

          events: [],
          eventColor: '#545b62'
          
        },
        admins:[],
        addedBy :null,
        
        modalShow: false,
        companyName :null,
        phoneNumber :null,
        action :null,
        env_api_url: process.env.VUE_APP_SERVICE_URL,

        filters:{
          userID: null
        },

        base_url:process.env.VUE_APP_SERVICE_URL  ,
        saleInformations: []
      }
    },

    watch: {
      filters: {
        deep: true,
        handler () {
          if (!this.awaitingSearch) {
            setTimeout(() => {
              this.getEvents()
              this.loading=true;
              this.awaitingSearch = false;
            }, 700);
          }
          this.awaitingSearch = true;
        }
      },
    },

    created() {
      this.getEvents();
    },

    methods: {
      toggleWeekends: function() {
        this.calendarOptions.weekends = !this.calendarOptions.weekends // toggle the boolean!
      },

      show(leadID)
      {
        this.modalShow = true;
        var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user") };
      
        axios
        .get(this.env_api_url + "/leads/" + leadID, { headers })
        .then((response) => {
         
          console.log("Showwwwww event details: ", response.data.lead);
          this.companyName = response.data.lead.company_name,
          this.phoneNumber = response.data.lead.phone_number
          this.action = response.data.action

        })
        .catch((error) => {
          console.warn("Your Error is :", error);
        });

      },

      getEvents: function ()
      {
        console.warn(this.filters.userID);

        this.filters.userID = this.$route.query.userID?this.$route.query.userID: this.filters.userID;
        var headers = {Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user")};
        axios.get(this.base_url+'/salesInformation', {
          headers, 
          params: this.filters
        })

        .then((response) => {
          console.warn("My all Events", response.data.events);
          this.calendarOptions.events = response.data.events;
          this.calendarOptions.slotMinTime = response.data.calendarhours_from;
          this.calendarOptions.slotMaxTime = response.data.calendarhours_to;
          this.admins = response.data.admins;
        });
      }
    }
  }
</script>

<style scoped>
  .leadLink:hover
  {
    font-style: bold;
    color: #36b9cc;
    cursor: hand;
  }

  .eventLayout{
    display: flex; flex-wrap: wrap;
    text-align:left;
    padding-top: 13px;
    padding-left: 7px;
    color: white;
    background: #545b62;
    white-space: pre-wrap !important;
  }

</style>