<template>
  <!-- DataTales Example -->
  <div class="card shadow mr-5">
    <div class="card-header" style="padding:0px;">
      <div class="row mt-3">
        <div class="col-md-6">
          <table-header
            :title="'SMS List'"
            :hasLink="false"
            :linkTo="'/sms/customer/create'"
            :linkText="'Send SMS'"
          />
        </div>
        <div class="col-md-6">
          <button type="button" v-show="showDelBtn" class="btn btn-danger btn-sm float-right mr-2 mb-2" v-on:click="deleteSelected()" :disabled="btnDisabled">{{btnTitle}} <i v-show="!btnDisabled" class="fa ml-1 fa-trash" aria-hidden="true"></i></button>
          
          <button type="button" class="btn btn-info btn-sm float-right mr-2 mb-2" v-on:click="showProductDialog">Import Customers <i class="fa ml-1 fa-download" aria-hidden="true"></i></button>
          <Dialog :maximizable="true" :closeOnEscape="true" header="Industries" v-model:visible="showProductsPopUp" style="width: 330px;">
            <ProductForm @productDialogHide="hideProductPopup($event)" />
          </Dialog>

          <button type="button" class="btn btn-success btn-sm float-right mr-2 mb-2" v-on:click="showsmsDialog()">Send SMS <i class="fa ml-1 fa-paper-plane" aria-hidden="true"></i></button>
          <Dialog :maximizable="true" :closeOnEscape="true" header="Send SMS to Customers" v-model:visible="smsModeldisplay" :style="{width: '75vw'}">
              <sendSMS :model="smsModeldisplay" @smsModeldisplay="smsdialog($event)" />
          </Dialog>
        </div>
      </div>
    </div>

    <ProgressSpinner v-if="loading" style="width:50px;height:50px" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"/>
    
    <div class="card-body" v-else>
      <DataTable :value="customers" :paginator="true" style="padding:4px;" :rows="loadRows" selectionMode="single"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5,10,20,50]" responsiveLayout="scroll"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
        <Column style="padding:4px;" field="company_name" header="Name" :sortable="true"></Column>
        
        <Column style="padding:4px;" header="Products" >
          <template #body="slotProducts">  
            <span v-if="slotProducts.data.products">
              <small  v-for="productObj in slotProducts.data.products" :key="productObj" class="badge badge-primary mr-1">{{productObj.product?productObj.product.name:''}}</small>
            </span>

            <span v-if="(slotProducts.data.products).length<1">
              <small class="badge badge-danger">No Products yet!</small>
            </span>
          </template>
        </Column> 

        <Column style="padding:4px;" field="phone" header="Phone" :sortable="true">
          <template #body="slotDate">
            <a href="tel:{{slotDate.data.phone}}">{{slotDate.data.phone}}</a>
          </template>
        </Column>
 
        <Column style="padding:4px;" field="created_at" header="Created At" :sortable="true">
          <template #body="slotDate">
            {{ moment(slotDate.data.created_at).format("MM/DD/YYYY") }}
          </template>
        </Column>
        
        <Column style="padding:4px;" field="" header="Action">
          <template #body="slotDate">
            <label class="switch">
              <input type="checkbox" v-on:change="showBtn" v-on:click="isSelected($event)" v-model="selectedCustomer[slotDate.data.id]" id="checkboxActive" :value="slotDate.data.id" />
              <span class="slider round"></span>
            </label>
          </template>
        </Column>
      </DataTable>
    </div>
    <Toast /><ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script>
import TableHeader from "../SharedComponents/TableHeader.vue";
import axios from "axios";
import moment from "moment";
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import ProductForm from "./partials/ProductForm.vue";
import Tooltip from 'primevue/tooltip';
import sendSMS from './partials/sendSMS.vue';

export default {

  directives: {
    'tooltip': Tooltip
  },

  components: {
    TableHeader, 
    DataTable, 
    Column,
    ProgressSpinner,
    ConfirmDialog,
    Toast,
    Dialog,
    ProductForm,
    sendSMS
  },

  data() { 
    return {
      filters:{
        
      },
      loading: true,
      loadRows: 10,
      customers: [],
      display: false,
      smsModeldisplay: false,
      moment: moment,
      ProductID: null,
      selectedCustomer: {},
      btnTitle: 'Delete',
      btnDisabled: false,
      showDelBtn: false,
      showProductsPopUp : false,
      totalRows: 0,
      env_api_url: process.env.VUE_APP_SERVICE_URL,
      base_url:process.env.VUE_APP_SERVICE_URL
    };
  },
  
  methods: {

    showProductDialog(){
      this.showProductsPopUp = true;
    },

    isSelected : function(event) {
        if (event.target.checked) {
          this.showDelBtn = true;
        }else{
          this.showDelBtn = false; 
        }
    },

    showsmsDialog: function () {
      this.smsModeldisplay = true;
    },
    smsdialog(status)
    {
      //Hide Dialog after success 
      this.smsModeldisplay=status;
    },

    hideProductPopup(status)
    {
      //Hide Dialog after success 
      this.showProductsPopUp=status;
      this.getList();
    },

    getList: function () {
      this.filters.type = this.$route.query.type?this.$route.query.type:"";
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };
      axios
        .get(this.base_url+'/list', { 
          headers,
          params: this.filters,
        })
        .then((response) => {
          this.customers = response.data.customers;
          this.totalRows = 0;
          this.loading = false;
        });
    },

    deleteSelected: function () {
       
       
      this.btnTitle = 'Deleting...';
      this.btnDisabled = true;

      var data = {
        selectedCustomers: this.selectedCustomer
      };
      
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_customers_user"),
      };

      this.$confirm.require({
        message: 'Want to delete selected records?',
        header: 'Delete',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios
            .post(this.env_api_url + "/sms_list/selected_delete", data, {
              headers: headers,
            })
            .then((response) => {
              this.getList();
              this.btnTitle = 'Delete';
              this.btnDisabled = false;
              this.showDelBtn = false,
              this.$toast.add({severity:'success', summary: 'Deleted', detail:'Record deleted successfully!', life: 2000});
              console.log("Selected rows Deleted: ", response);
            })
            .catch((error) => {
              console.warn("Your Error is :", error);
              this.btnTitle = 'Delete';
              this.btnDisabled = false;
            });
        },
        reject: () => {
          this.$toast.add({severity:'error', summary: 'Record Safe', detail:'Your record is safe!', life: 2000});  
          this.selectedCustomer = {};
          this.showDelBtn = false;
          this.btnTitle = 'Delete';
          this.btnDisabled = false;
        }
      });

    },
  },

  created() {
    this.getList();
  },
};
</script>

<style scoped>
  input:checked + .slider {
    background-color: #e74a3b;
  }
</style>