<template>
  <h4 class=""><strong>{{survey.name}}</strong> - Survey Response</h4>
  <div class="card col-md-12 mb-3">
    <div class="card-body" style="line-height: 4px;">
      <div class="form-group row mt-3">
        <div class="col-md-12 mb-sm-0">
          <div v-for="(question, index) in survey_questions" :key="question">
            <div class="form-group row">
              <h5 for="input"><strong>{{index+1}})</strong> {{question.title}}</h5>
            </div>
            <div>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Sr.#</th>
                    <th scope="col">Response</th>
                    <th scope="col">Total</th>
                    <th scope="col">Submission Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in question.response" :key="item">
                    <th scope="row">1</th>
                    <td>{{item.answer}}</td>
                    <td>{{item.total}}</td>
                    <td>{{ moment(question.created_at).format("MM/DD/YYYY") }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import moment from "moment";
  export default {
    
    data() {
      return {
        base_url: process.env.VUE_APP_SERVICE_URL,
        moment: moment,
        survey: [],
        survey_questions: [],        
      };
    },

    created() {
      this.getFilledData();
    },

    methods:
    {
      getFilledData: function ()
      {
        var surveyID = this.$route.params.id;
        var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };
        
        axios.get(this.base_url + '/filled/data/' + surveyID, {headers})
        .then((response) => {
      
          this.survey = response.data.survey;
          this.survey_questions = response.data.survey_questions;

        }).catch((error) => {

          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          
          console.warn("Your Error is :", error);
        });
      }
    }
  };
</script>