<template>
    <div>
        <form @submit.prevent="sendMail">
            <div class="form-group row">
                <div class="col-md-12 mb-sm-0">
                    <label class="required" for="email">To (Email)</label>
                    <input v-model="email" type="text" id="email" placeholder="someone@somewhere.com" class="form-control form-control-sm" :class="{ 'is-invalid': v$.email.$error }" />
                    <span class="text-danger" v-if="v$.email.$error">
                        <span
                            class="help-block"
                            v-for="validationError in v$.email.$errors"
                            :key="validationError"
                        >
                            Email must be required for testing!
                        </span>
                    </span>
                </div>
            </div>        
            <div class="form-group row">
                <div class="col-md-12 mb-sm-0">
                    <label class="required" for="email">Message</label>
                    <textarea v-model="message" id="message" class="form-control form-control-sm" :class="{ 'is-invalid': v$.message.$error }" rows="7" cols="100"></textarea>
                    <span class="text-danger" v-if="v$.message.$error">
                        <span
                            class="help-block"
                            v-for="validationError in v$.message.$errors"
                            :key="validationError"
                        >
                            Please type message!
                        </span>
                    </span>
                </div>
            </div>

            <button class="btn btn-success btn-sm" :disabled="isDisabled">{{btnTitle}}</button>
        </form>
    </div>
</template>
<script>

import {required, email } from "@vuelidate/validators";
import axios from "axios";
import useValidate from "@vuelidate/core";

export default {
    data() {
        return {
        v$: useValidate(),
        env_api_url: process.env.VUE_APP_SERVICE_URL,
    
        email: null,
        message: null,
        btnTitle: 'Send',
        isDisabled: false,
        };
    },

    validations() {
        return {
            email: {
                required,
                email
            },
            message: {
                required
            }
        };
    },

    methods: {
        sendMail() {

            this.v$.$validate();
            if (this.v$.$error) {
                console.log("errors",this.v$.$error);
            } else {

                this.btnTitle = "Waiting...";
                this.isDisabled = true;

                var data = {
                    email: this.email,
                    message: this.message
                };

                var headers = {
                Authorization:`Bearer ` + localStorage.getItem("rezo_customers_user"),
                };

                axios
                .post(this.env_api_url + "/settings/send-email", data, { headers })
                .then((response) => {
                if (response.status === 200) {
                   
                    console.warn("Email send successfully!")

                    this.btnTitle = "Send";
                    this.isDisabled = false;
                   
                    this.$notify({
                        type: "success",
                        title: "Success",
                        text: "Email send successfully!!",
                        });
                        console.log("Mail send successfully");
                        // this.$router.push({ path: "/leads" });
                    }
                },
                (error) => {
                    console.log(error.response.data.error);
                    this.$notify({ 
                    type: "error",
                    title: "Something went wrong!",
                    text: error.response.data.error,
                    });
                });
            }
        },
    },
}
</script>
