<template>
  <page-header :title="formTitle" />
  <div class="card col-md-6 mb-3">
    <div class="card-body" style="line-height: 4px;">
      <form @submit.prevent="formSubmit" class="user">
        <div class="form-group row mt-3">
          <div class="col-sm-6 mb-sm-0">
            <label class="required" for="surveyName">Survey Name</label>
            <input
              autocomplete="chrome-off"
              v-model="surveyName"
              type="text"
              class="form-control form-control-sm mb-1" :class="{ 'is-invalid': v$.surveyName.$error }"
              id="surveyName"
            />
            <span class="text-danger" v-if="v$.surveyName.$error">
              <span class="help-block" v-for="validationError in v$.surveyName.$errors" :key="validationError">
                {{ validationError.$message }}
              </span>
            </span>
          </div>
        </div>
        
        <div class="form-group row">
          <div class="col-sm-12 mb-sm-0">
            <label for="description">Survey Description</label>
            <textarea v-model="surveyDescription" autocomplete="chrome-off" class="form-control form-control-sm" id="txtid" name="txtname" rows="4" cols="100" maxlength="200" ></textarea>
          </div>
        </div>
        
        <div class="form-group row">
          <div class="col-sm-6 form-inline">
            <div class="form-group mr-2">
              <label for="checkboxActive" class="mr-1">Customer Name  </label>
              <label class="switch">
                <input type="checkbox" id="checkboxActive" checked v-model="customerName" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>

          <div class="col-sm-6 form-inline" v-show="customerName">
            <div class="form-group mr-2">
              <label for="checkboxActive" class="mr-1">Mandatory  </label>
              <label class="switch">
                <input type="checkbox" id="checkboxActive" checked v-model="mandatory" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        
        <button class="btn btn-primary btn-sm" :disabled="btnDisabled">{{btnTitle}}</button>
        <router-link v-if="this.surveyID" target="_blank" class="btn btn-success float-right btn-sm" :to="{name:'TestSurvey'}" >Test Survey</router-link >

      </form>
    </div>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import {
  maxLength,
  required
} from "@vuelidate/validators";
import PageHeader from "../SharedComponents/PageHeader.vue";
import axios from "axios";
export default {
  components: { PageHeader },
  data() {
    return {
    v$: useValidate(),
    
    base_url: process.env.VUE_APP_SERVICE_URL,

    //Share form for Create/Edit
    formTitle: "New Survey",

    surveyID:null,
    surveyName: null,
    surveyDescription: null,
    customerName: false,
    mandatory: false,

    btnDisabled: false,
    btnTitle: "Create"
    };
  },

  validations() {
    return {
      surveyName: {
        maxLength: maxLength(150),
        required
      }
    };
  },

created() {
  
  if (this.$route.name == "EditSurvey")
  {
    this.formTitle = "Edit Survey";
    this.surveyID = this.$route.params.id;
    this.getSurvey();
    this.btnTitle = "Update"
  }

},

  methods: {
   
    getSurvey: function ()
    {
      var headers = { Authorization: `Bearer `+localStorage.getItem("rezo_customers_user") };

      axios.get(this.base_url + '/survey/' + this.surveyID, {headers})
      .then((response) => {
    
        ///Edit Survey
        this.surveyName = response.data.survey.name;
        this.surveyDescription = response.data.survey.description;
        this.customerName = response.data.survey.customer_name? true:false;
        this.mandatory = response.data.survey.is_mandatory ? true:false;
         
      
      }).catch((error) => {
          console.warn('Your Error is :', error);
      })
    },

    formSubmit() { 
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors",this.v$.$error);
      } else {

        this.btnDisabled = true;
        this.btnTitle = "Waiting...";

        var data = {
          survey_id : this.surveyID,
          name: this.surveyName,
          description: this.surveyDescription,
          customer_name: this.customerName,
          is_mandatory: this.mandatory,
          status: true,
        };

        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_customers_user"),
        };

        axios
        .post(this.base_url + "/survey", data, { headers })
        .then((response) => {
          if (response.status === 200)
          {
            this.btnDisabled = false;
            this.btnTitle = this.$route.name == "EditSurvey" ? "Update":"Create";

            this.$notify({
              type: "success",
              title: "Success",
              text: "Save Successfully!",
            });

            
            console.log("Survey created successfully");
            var redirectURL = "/survey/" + response.data.survey.id + "/edit?activeTab=surveyQuestions";   

            if (this.$route.name == "EditSurvey")
            {
              redirectURL = "/survey/" + response.data.survey.id + "/edit";   
            }
            
            this.$router.push(redirectURL)

          }
        }).catch((error) => {

          this.btnDisabled = false

          if (this.$route.name == "EditSurvey")
          {
            this.btnTitle = 'Update';
          }
          else
          {
            this.btnTitle = 'Update';
          }

          this.$notify({
              type: "error",
              title: "Something Went Wrong",
              text: "Error!",
          });
          
          console.warn("Your Error is :", error);
        });
      }
    },
  },
};
</script>